import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import CallIcon from '@mui/icons-material/Call';
import naac from '../assets/home/naacLogo.png'

const Header = () =>{
    return(
        <>
            <div style={{backgroundColor:'#111f29', color:'#fff'}} className='d-none d-lg-block'>
                <div className='d-flex justify-content-between container p-1'>
                    <div className='d-flex'>
                        <svg fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.442 27.442">
                            <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546 c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481 s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469 c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z M19.995,21.1H7.448V3.373h12.547V21.1z"></path> 
                        </svg>
                        <p className='m-0'> +91 7038500030 / 9011070916</p>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                        <svg fill="#fff" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 4C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7C1 5.34315 2.34315 4 4 4H20ZM19.2529 6H4.74718L11.3804 11.2367C11.7437 11.5236 12.2563 11.5236 12.6197 11.2367L19.2529 6ZM3 7.1688V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7.16882L13.8589 12.8065C12.769 13.667 11.231 13.667 10.1411 12.8065L3 7.1688Z" ></path> 
                        </svg>
                        <p className='m-0'>mibs.admission@gmail.com / imcdmba@rediffmail.com</p>
                    </div>
                    <p className='m-0'>Institute Code: 6812</p>
                </div>
            </div>
            <header>
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between flex-wrap my-0">
                        <div className="d-flex align-items-center vendor-name">
                            <img src="/logo.png" alt="logo" />
                            <div className="vendor-name ps-2">
                                <p className="m-0">PROGRESSIVE EDUCATION SOCIETY'S</p>
                                <h3 className="m-0 fw-bold title-text">
                                    MODERN INSTITUTE OF BUSINESS STUDIES (AUTONOMOUS)
                                </h3>
                                <p className='m-0'>(Formerly known as Institute of Management and Career Development IMCD)</p>
                                <p className="m-0">
                                Approved by AICTE New Delhi, Recognized by Govt. of Maharashtra, Accredited by NAAC,
                                </p>
                                <p className="m-0">
                                Permanently Affiliated to Savitribai Phule Pune University
                                </p>
                            </div>
                           
                        </div>  
                        {/* <div className="d-flex flex-lg-column gap-2 flex-md-row social-icons py-2 align-items-center">
                         <img src={naac} alt="logo" className='p-0' style={{height:'110px'}}/>
                        </div> */}
                        <div className="d-flex flex-lg-column gap-2 social-icons py-2 align-items-center ps-1">
                            <img src={naac} alt="logo" className='p-0' style={{height:'110px'}}/>
                            <h4 className='text-bold'>DTE Code 6812</h4>
                            {/* <a className='text-bold text-gray'>Admission Enquiry</a> */}
                           <Link to={'/admissionEnquiry'} clas><button id="blinking-button">ADMISSIONS 2024-25</button></Link>
                            {/* <div>
                                <Link to={'/contact'} className='btn contactBtn'>Contact</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Navbar />
            </header>
        </>
    );
}

export default Header;