export const NoticeData = [
  //doc can be anything image or pdf import that doc and give its name
  // {
  //     title: 'Fee Fixation Proposal for A.Y. 2024-25',
  //     viewTitle: 'Fee Fixation Proposal for A.Y. 2024-25',
  //     Doc:{
  //         image:[],
  //         pdf: [FeeFixation],
  //     }
  //     ,
  //     description: '',
  //     updatedDate: 'Updated on 13 December 2023',
  // },
  // {
  //         title: 'Orientation Programme MBA | A.Y 2023-24.',
  //         viewTitle: 'Orientation Programme MBA | A.Y 2023-24.',
  //         Doc:{
  //             image:[],
  //             pdf: [Orientation],
  //         }
  //         ,
  //         description: '',
  //         updatedDate: 'Updated on 28 June 2023',
  //     },
  //     {
  //         title:'Online registration of application and uploading of required...',
  //         viewTitle:'Online registration of application and uploading of required documents by the Candidate for admission on the website from 28/06/2023 to 07-07-2023.',
  //         Doc:{
  //             image:[ ],
  //             pdf: [Online_registration_of_application],
  //         }
  //         ,
  //         description: '',
  //         updatedDate: 'Updated on 28 June 2023',
  //     },
  //     {
  //         title: 'MIBM ADMISSION ENQUIRY',
  //         viewTitle: 'MIBM ADMISSION ENQUIRY',
  //         Doc:{
  //             image:[ ],
  //             pdf: [AdmissionEnquiry],
  //         }
  //         ,
  //         description: '',
  //         updatedDate: 'Updated on 14 March 2023',
  //     },
  //     {
  //         title: 'MBA Admission Brochure-2023-24',
  //         viewTitle: 'MBA Admission Brochure-2023-24',
  //         Doc:{
  //             image:[],
  //             pdf: [AdmissionBrochoure],
  //         }
  //         ,
  //         description: '',
  //         updatedDate: 'Updated on 14 March 2023',
  //     },
  //     // {
  //     //     title: 'Object 5',
  //     //    Doc:[ 'http://mibmpune.com/assets/Research_Center.pdf',],
  //     //     description: 'Description of Object 5',
  //     //     date: 'upload on date 5',
  //     //   },
];
