import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import {  BasicTable} from "../Data/GovernanceData";
import UnderMaintanence from "./UnderMaintanence";
import TableComponent from "../Components/TableComponent";
// import { Paper } from "@mui/material";
import { LibraryAssociateTable, LibraryCommitteeTable, LibraryResourcesTable } from "../Data/LibraryData";
import libraryimg from "../assets/Library/librayImage.png";
import associateMember from "../assets/Library/OrganizationChart.jpg";
import libraryResource from '../assets/Library/LibraryCollections.jpg'
import { Paper } from "@material-ui/core";

const sidemenus = [
      {
        title: 'About Library',
        url: '/library/AboutLibrary'
      },
      {
        title: 'Library Resources',
        url: '/library/Resources'
      },
      {
        title: 'Library Rules',
        url: '/library/Rules'
      },
      {
        title: 'Associate Memberships',
        url: '/library/AssociateMemberships'
      },
      {
        title: 'Library Committee',
        url: '/library/LibraryCommittee'
      },
      // {
      //   title: 'Web OPAC',
      //   url: '/library/WebOPAC'
      // },
      // {
      //   title: 'DELNET',
      //   url: '/library/DELNET'
      // },
      // {
      //   title: 'National Digital Library',
      //   url: '/library/NationalDigitalLibrary'
      // },
      // {
      //   title: 'SPPU Jaykar Library',
      //   url: '/library/SPPUJaykarLibrary'
      // },
      {
        title: 'Previous Question Papers',
        url: '/library/PreviousQuestionPapers'
      },
];

export default function Library(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export function AboutLibrary(){
    return(
      <div className="text-justify">
       <img src={libraryimg}  alt="library Image" style={{marginBottom:"10px", height: '400px', width: '800px'}} />
<p>The main purpose of library is to freely disseminate knowledge, support teaching, research and academic activities and ignite the minds of youngsters studying in our institute. Our Library is one of the vibrant learning units. All library staff works with the motto “To Give Maximum Information for Maximum Number of Times by Maximum Number of Ways in Minimum Times”. Library operates on need based approach rather than the usual rules-based treatment.
</p>
<p>We are giving technology based services and facilities such as Web-OPAC, reference service for searching information from online databases, e-books, e-journals, audio books, e-newsletter, online information literacy programs, library Blog, Online Brochure, digital library, Internet facility, Urkund- an anti-plagiarism software along with other open source tools available for research scholars, computerized issue return and attendance through barcode scanner, etc. At the same time we also provide traditional services such as reference and referral service, current awareness service, bibliographical services, reading facility, book bank facility for needy students, institutional membership of other libraries, inter library loan and library orientation programs, etc.
</p>
<p>We have a huge collection of books which includes reference, text, self-help, personality development, biographies, autobiographies, success stories, novels and competitive exam related books in our library. We subscribe national and International journals and magazines and provide gateway to many online scholarly publications.
</p>
<p>Every year library organizes book exhibitions; guest lectures, celebrates birth anniversaries of eminent personalities, conducts various competitions and other programs to inculcate reading habits among the students.
</p>
<p>Through library website and web-OPAC library is accessible to users for 24/7 from any location. Library has its active Facebook page to circulate notices and updates.
</p>
<h5 className="title-text my-5">Objectives :</h5>
     <div>
        <ul>
          <li>To orient you about library collection, services, facilities, rules and disciplines</li>
          <li>To give you training on how to access electronic resources subscribed by our library</li>
          <li>To maximize utilization of library resources</li>
         </ul>
      </div>
       <h5 className="title-text my-5">Main Purpose of library :</h5>
      <div>
        <ul>
          <li>To freely disseminate knowledge and support teaching, research and other academic activities of the college</li>
          <li>Library operates on need based approach rather than the usual rules-based treatment</li>
         </ul>
      </div>
      </div>
    );
}

export function LibraryResources(){
    return(
      <div>
        <div>
            <h5 className="title-text my-2 mb-4">Library Resources</h5>
        <div> 
        <img src={libraryResource} height={400} width={'auto'} className="px-5" />
        </div>
        <LibraryResourcesTable />
        </div>
      </div>
    );
}

export function LibraryRules(){
    return(
      <div>
       {/* <div style={{ margin: '100px auto', maxWidth: '600px', padding: '20px 50px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}> */}
       <h4 className="title-text my-4 mt-2">Library Rules and Regulations </h4>
      <div>
        <ul>
          <li>Every student must have his/her Identity Card/Library Card while entering in library.</li>
          <li>Keep Silence in the reading hall. </li>
          <li>Eatables are not allowed.</li>
          <li>Take proper care of all library resources.</li>
          <li>Write your name in the register the counter while entering in the library.</li>
          <li>Kindly switch off/ Silence the mobile phone while you visit the library.</li>
          <li>Silence is required in library and reading hall, loud conversation, standing in groups and discussions etc. not allowed.</li>
          <li>Any personal belongings are not permitted into the library.</li>
          <li>Eatables are not allowed inside the library & reading hall.</li>
          <li>Use students’ computer for searching required book. </li>
          <li>Students should return their book in given time period. </li>
          <li>Students should preserve their library copy of deposit chalan for deposit refund.</li>
          </ul>
      </div>
      <h5 className="title-text my-5">A. Membership Eligibility </h5>
      <div>
        <ul>
          <li>Individual Members: Faculty, Staff and Students of the Institute.</li>
          <li>Outside Members: Alumni of Institute, Retired Faculty of Institute.</li>
          <li>Special Members : Visiting Faculty. </li>
         </ul>
      </div>
      <h5 className="title-text my-5">B. I-Card </h5>
      <div>
        <ul>
          <li>ID card must be presented on demand. </li>
          <li>Icard is not transferable. </li>
          <li>If Icard lost, duplicate I-Card will be charges Rs.150/- </li>
         </ul>
      </div>
      <h5 className="title-text my-5">C. Circulation of study Material </h5>
      <div>
      <ul>
          <li>All books must be returned on or before the due date.</li>
          <li>Book will be renewed only if there is no demand from other members.</li>
          <li>Book will issued for 10days. </li>
          <li>Books should be presented physically at the counter for renewal.</li>
          <li>Late fee fine will be charged Rs.2/- per book per day.</li>
          <li>Single copy of any publication is not issued on long term.</li>
          <li>Members are requested to check the condition of the reading material before getting issued.</li>
          <li>Members are requested to collect the receipts for the overdue charges paid by them.</li>
          <li>Reference Books will not be issued only read in Library. </li>
          <li>Kindly Check your emails when you issued books the system software given always reminder to you due date. </li>
      </ul>
      </div>
      <h5 className="title-text my-5">D.  Loss of Books </h5>
<div>
  <ul>
    <li>Loss of any book/ reading material borrowed must be reported immediately to the library counter. </li>
    <li>All members must replace the book if it is lost the latest edition along with overdue charges, if any</li>
  </ul>
</div>
<h5 className="title-text my-5">E. General Rules</h5>
<div>
  <ul>
    <li>Readers are required to deposit their bags / belongings at the counter before entering the library.</li>
    <li>Write your full name in the register at the counter while entering in the library.</li>
    <li>Take proper care of all library resources. </li>
    <li>Kindly switch off or silent the mobile phone while you visit the library.</li>
    <li>Any personal belongings are not permitted into the library.</li>
    <li>Eatables are not allowed inside the library & reading hall.</li>
    <li>Use student’s computer for searching required book. </li>
    <li>Students should return their book in given time period.</li>
    <li>Keep Silence. </li>
  </ul>
</div>
        {/* </div> */}
      </div>
    );
}

export function AssociateMemberships(){
    return(
      <div>
          <h5 className="title-text my-2 mb-4">Associate Memberships</h5>
          <div>
            <h5 className="my-2">Library Softwares:</h5>
            <ul>
              <li>Autolib Library Management Software.</li>
              <li>Library OPAC ( Online Public Access Catalogue) </li>
              <li>Anti-Plagiarism software: URKUND ( Provide by Savitribai Phule Pune  University)</li>
              <li>Language Development Software ( English , German,)</li>
            </ul>
          </div>
          <div>
            <LibraryAssociateTable />
          </div>
      </div>
    );
}

export function LibraryCommittee(){
    return(
      <div className="text-justify">
         {/* <UnderMaintanence /> */}
        {/* <div style={{ margin: '100px auto', maxWidth: '600px', padding: '10px 50px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        </div> */}
          <div>
          <h5 className="title-text my-2 mb-4">Library Committee</h5>
           </div> 
        <LibraryCommitteeTable />
      </div>
    );
}

// export function WebOPAC(){
//     return(
//       <div>
//         <UnderMaintanence />  
//       </div>
//     );
// }

// export function DELNET(){
//     return(
//       <div>
//         <UnderMaintanence />  
//       </div>
//     );
// }

// export function NationalDigitalLibrary(){
//     return(
//       <div>
//         <UnderMaintanence />  
//       </div>
//     );
// }

// export function SPPUJaykarLibrary(){
//     return(
//       <div>
//         <UnderMaintanence />  
//       </div>
//     ); 
// }

export function PreviousQuestionPapers(){
    return( 
      <Paper style={{minHeight:400 }} className="p-3">
        <div>
        <h5 className="title-text my-2 mb-4">Previous Question Papers</h5>
        <p>Dear Student, </p>
        <p>Please refer to the following website of Savitribai Phule Pune University for previous question papers.</p>
        <Link to="http://exam.unipune.ac.in/Pages/PreviousQuestionPapers.html" target="_blank">http://exam.unipune.ac.in/PreviousQuestionPapers</Link>
      </div>
     </Paper>
    );
}