import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import NotFount from "./routes/NotFound";
import AboutUs, {
  AboutInstitute,
  Approval,
  ChairmansMessage,
  CoordinatorsMessage,
  DirectorsMessage,
  MandatoryDisclosure,
  PESociety,
  VisionMission,
  VisitorMessage,
} from "./routes/AboutUs";
import MBA, { MBASpecialization } from "./routes/MBA";
import MCA, { MCASpecialization } from "./routes/MCA";
import PHD, { PHDSpecialization } from "./routes/PHD";
import Admission, {
  ContactAdmission,
  Eligibility,
  FeesStructure,
  MBAAdmission,
  MCAAdmission,
  MIBSProspectus,
  PHDAdmission,
} from "./routes/Admission";
import Students, {
  AcademicCalender,
  Circulars,
  ExtensionActivities,
  LifeAtMIBS,
  MBACurriculum,
  MCACurriculum,
  Newsletter,
  OnlineGrievance,
  StudentAchievement,
} from "./routes/Students";
import Faculty, {
  NonTeaching,
  TeachingMBA,
  TeachingMCA,
} from "./routes/Faculty";
import ResearchCenter, {
  CodeEthics,
  Publications,
} from "./routes/ResearchCenter";
// import Library, { AboutLibrary, AssociateMemberships, DELNET, LibraryCommittee, LibraryResources, LibraryRules, NationalDigitalLibrary, PreviousQuestionPapers, WebOPAC } from './routes/Library';
import Library, {
  AboutLibrary,
  AssociateMemberships,
  LibraryCommittee,
  LibraryResources,
  LibraryRules,
  PreviousQuestionPapers,
} from "./routes/Library";
import TrainingPlacement, {
  Collaborations,
  OurRecruiters,
  PlacementBrouchere,
  PlacementCell,
  SummerIntershipProgram,
} from "./routes/TrainingPlacement";
import NAAC, { AccreditationStatus, Feedback, SSR } from "./routes/NAAC";
// import IQAC, { ActionReport, MinutesMeetings } from './routes/IQAC';
import AQAR, { AQAR202021, AQAR202122 } from "./routes/AQAR";
import Committee, {
  AntiRaggingCommittee,
  InternalCommittee,
  SCSTCommittee,
  StudentGrievenceCell,
} from "./routes/Committee";
import Alumni, {
  AlumniAchievers,
  AlumniCell,
  AlumniRegistration,
} from "./routes/Alumni";
import Contact from "./routes/Contact";
import Sample from "./routes/Sample";
import IIC, {
  AboutIIC,
  BenefitsOfIIC,
  FunctionsOfIIC,
  IICActivities,
  ObjectivesOfIIC,
} from "./routes/InstitutionInnovationCouncil";
import Governance, {
  AntiRagging,
  CollegeDevelopment,
  InternalComplaint,
  InternalQualityAssurance,
  Organization,
  StudentGrievance,
  SCST_Committee,
} from "./routes/Governance";
import BestPractices, { AlumniMeet, HRmeet } from "./routes/BestPractices";
import AdmissionEnquiry from "./routes/AdmissionEnquiry";
import Home from "./routes/Home";
import GalleryView from "./Components/GalleryView";
import IQAC, { Aug2022, Dec2022 } from "./routes/IQAC";
import { Practice2223 } from "./routes/BestPractice2223";
import BestPractice2223 from "./routes/BestPractice2223";
import ContactTPofficer, {
  PlacementOfficer,
  TrainingOfficer,
} from "./routes/ContactTpOfficer";
import BCA, { BCASpecialization } from "./routes/BCA";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFount />} />

          <Route>
            <Route
              path="/aboutUs/PESociety"
              element={
                <AboutUs
                  pageId={0}
                  pageName={`About P.E.Society`}
                  pageContent={<PESociety />}
                />
              }
            />
            <Route
              path="/aboutUs/Institute"
              element={
                <AboutUs
                  pageId={1}
                  pageName={`About Institute`}
                  pageContent={<AboutInstitute />}
                />
              }
            />
            <Route
              path="/aboutUs/VisionMission"
              element={
                <AboutUs
                  pageId={2}
                  pageName={`Vision Mission`}
                  pageContent={<VisionMission />}
                />
              }
            />
            <Route
              path="/aboutUs/ChairmansMessage"
              element={
                <AboutUs
                  pageId={3}
                  pageName={`Chairman's Message`}
                  pageContent={<ChairmansMessage />}
                />
              }
            />
            <Route
              path="/aboutUs/DirectorsMessage"
              element={
                <AboutUs
                  pageId={4}
                  pageName={`Director's Message`}
                  pageContent={<DirectorsMessage />}
                />
              }
            />
            <Route
              path="/aboutUs/VisitorMessage"
              element={
                <AboutUs
                  pageId={5}
                  pageName={`Visitor's Message`}
                  pageContent={<VisitorMessage />}
                />
              }
            />
            <Route
              path="/aboutUs/CoordinatorsMessage"
              element={
                <AboutUs
                  pageId={6}
                  pageName={`Coordinator's Message`}
                  pageContent={<CoordinatorsMessage />}
                />
              }
            />
            <Route
              path="/aboutUs/MandatoryDisclosure"
              element={
                <AboutUs
                  pageId={7}
                  pageName={`Mandatory Disclosure`}
                  pageContent={<MandatoryDisclosure />}
                />
              }
            />
            <Route
              path="/aboutUs/Approval"
              element={
                <AboutUs
                  pageId={8}
                  pageName={`AICTE Approval`}
                  pageContent={<Approval />}
                />
              }
            />
            {/* <Route path="/aboutUs/Governance" element = {<AboutUs pageId={7} pageName={`Governance`} pageContent={ <Governance/> } />} /> */}
          </Route>

          <Route>
            <Route
              path="/courses/Phd"
              element={
                <PHD
                  pageId={0}
                  pageName={`Ph.D (Management)`}
                  pageContent={<PHDSpecialization />}
                />
              }
            />
            {/* <Route path="/courses/mcas" element = {<PHD pageId={1} pageName={`mca`} pageContent={ <MCASpecializations/>}/>} />
              <Route path="/courses/mbas" element = {<PHD pageId={2} pageName={`mba`} pageContent={ <MBASpecializations/>}/>} /> */}
          </Route>
          <Route
            path="/courses/BCA"
            element={
              <BCA
                pageId={0}
                pageName={`Bachelor of Computer Applications`}
                pageContent={<BCASpecialization />}
              />
            }
          />
          <Route>
            <Route
              path="/courses/MBA"
              element={
                <MBA
                  pageId={0}
                  pageName={`Master in Business Administration`}
                  pageContent={<MBASpecialization />}
                />
              }
            />
            {/* <Route path="/courses/MBA/InnovativePractices" element = {<MBA pageId={1} pageName={`Master in Business Administration`} pageContent={ <MBAInnovativePractices/>}/>} /> */}
            <Route
              path="/courses/MCA"
              element={
                <MCA
                  pageId={0}
                  pageName={`Master of Computer Application`}
                  pageContent={<MCASpecialization />}
                />
              }
            />
            {/* <Route path="/courses/MCA/InnovativePractices" element = {<MCA pageId={1} pageName={`Master of Computer Application`} pageContent={ <MCAInnovativePractices/>}/>} /> */}
            {/* <Route path="/courses/Phd" element = {<PHD pageId={0} pageName={`Ph.D (Management)`} pageContent={ <PHDSpecialization/>}/>} /> */}
          </Route>

          <Route>
            <Route
              path="/admission/MBA"
              element={
                <Admission
                  pageId={0}
                  pageName={`MBA`}
                  pageContent={<MBAAdmission />}
                />
              }
            />
            <Route
              path="/admission/MCA"
              element={
                <Admission
                  pageId={1}
                  pageName={`MCA`}
                  pageContent={<MCAAdmission />}
                />
              }
            />
            <Route
              path="/admission/PHD"
              element={
                <Admission
                  pageId={2}
                  pageName={`Ph.D`}
                  pageContent={<PHDAdmission />}
                />
              }
            />
            <Route
              path="/admission/Eligibility"
              element={
                <Admission
                  pageId={3}
                  pageName={`Eligibility`}
                  pageContent={<Eligibility />}
                />
              }
            />
            <Route
              path="/admission/FeesStructure"
              element={
                <Admission
                  pageId={4}
                  pageName={`Fees Structure and Documents`}
                  pageContent={<FeesStructure />}
                />
              }
            />
            <Route
              path="/admission/Prospectus"
              element={
                <Admission
                  pageId={5}
                  pageName={`MIBS Prospectus`}
                  pageContent={<MIBSProspectus />}
                />
              }
            />
            <Route
              path="/admission/Contact"
              element={
                <Admission
                  pageId={6}
                  pageName={`Contact For Admissions`}
                  pageContent={<ContactAdmission />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/students/AcademicCalender"
              element={
                <Students
                  pageId={0}
                  pageName={`Academic Calender`}
                  pageContent={<AcademicCalender />}
                />
              }
            />
            <Route
              path="/students/MBACurriculum"
              element={
                <Students
                  pageId={1}
                  pageName={`MBA Curriculum`}
                  pageContent={<MBACurriculum />}
                />
              }
            />
            <Route
              path="/students/MCACurriculum"
              element={
                <Students
                  pageId={2}
                  pageName={`MCA Curriculum`}
                  pageContent={<MCACurriculum />}
                />
              }
            />
            <Route
              path="/students/ExtensionActivities"
              element={
                <Students
                  pageId={3}
                  pageName={`Extension Activities`}
                  pageContent={<ExtensionActivities />}
                />
              }
            />
            <Route
              path="/students/Circulars"
              element={
                <Students
                  pageId={4}
                  pageName={`Circulars`}
                  pageContent={<Circulars />}
                />
              }
            />
            <Route
              path="/students/Newsletter"
              element={
                <Students
                  pageId={5}
                  pageName={`Newsletter`}
                  pageContent={<Newsletter />}
                />
              }
            />
            <Route
              path="/students/StudentAchievement"
              element={
                <Students
                  pageId={6}
                  pageName={`Student Achievement`}
                  pageContent={<StudentAchievement />}
                />
              }
            />
            <Route
              path="/students/LifeAtMIBS"
              element={
                <Students
                  pageId={7}
                  pageName={`Life at MIBS`}
                  pageContent={<LifeAtMIBS />}
                />
              }
            />
            <Route
              path="/students/OnlineGrievance"
              element={
                <Students
                  pageId={8}
                  pageName={`Online Grievance Redressal`}
                  pageContent={<OnlineGrievance />}
                />
              }
            />
            {/* <Route path="/students/Collaborations" element = {<Students pageId={5} pageName={`Collaborations`} pageContent={ <Collaborations/>} />} /> */}
          </Route>

          <Route>
            <Route
              path="/faculty/TeachingMCA"
              element={
                <Faculty
                  pageId={0}
                  pageName={`Teaching Staff MCA`}
                  pageContent={<TeachingMCA />}
                />
              }
            />
            <Route
              path="/faculty/TeachingMBA"
              element={
                <Faculty
                  pageId={1}
                  pageName={`Teaching Staff MBA`}
                  pageContent={<TeachingMBA />}
                />
              }
            />
            <Route
              path="/faculty/nonTeaching"
              element={
                <Faculty
                  pageId={2}
                  pageName={`Non Teaching Staff`}
                  pageContent={<NonTeaching />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/ResearchCenter/CodeEthics"
              element={
                <ResearchCenter
                  pageId={0}
                  pageName={`Code of Ethics`}
                  pageContent={<CodeEthics />}
                />
              }
            />
            <Route
              path="/ResearchCenter/Publications"
              element={
                <ResearchCenter
                  pageId={1}
                  pageName={`Research and Publications`}
                  pageContent={<Publications />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/IIC/AboutIIC"
              element={
                <IIC
                  pageId={0}
                  pageName={`About IIC`}
                  pageContent={<AboutIIC />}
                />
              }
            />
            <Route
              path="/IIC/ObjectivesOfIIC"
              element={
                <IIC
                  pageId={1}
                  pageName={`Objectives of IIC`}
                  pageContent={<ObjectivesOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/FunctionsOfIIC"
              element={
                <IIC
                  pageId={2}
                  pageName={`Functions of IIC`}
                  pageContent={<FunctionsOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/BenefitsOfIIC"
              element={
                <IIC
                  pageId={3}
                  pageName={`Benefits of IIC`}
                  pageContent={<BenefitsOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/IICActivities"
              element={
                <IIC
                  pageId={4}
                  pageName={`IIC Activities`}
                  pageContent={<IICActivities />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/governance/OrganizationChart"
              element={
                <Governance
                  pageId={0}
                  pageName={`Organization Chart`}
                  pageContent={<Organization />}
                />
              }
            />
            <Route
              path="/governance/CollegeDevelopmentCommittee"
              element={
                <Governance
                  pageId={1}
                  pageName={`College Development Committee`}
                  pageContent={<CollegeDevelopment />}
                />
              }
            />
            <Route
              path="/governance/InternalQualityAssuranceCommittee"
              element={
                <Governance
                  pageId={2}
                  pageName={`Internal Quality Assurance Committee`}
                  pageContent={<InternalQualityAssurance />}
                />
              }
            />
            <Route
              path="/governance/AntiRaggingCommittee"
              element={
                <Governance
                  pageId={3}
                  pageName={`Anti Ragging Committee`}
                  pageContent={<AntiRagging />}
                />
              }
            />
            <Route
              path="/governance/InternalComplaintCommittee"
              element={
                <Governance
                  pageId={4}
                  pageName={`Internal Complaint Committee`}
                  pageContent={<InternalComplaint />}
                />
              }
            />
            <Route
              path="/governance/StudentGrievanceRedressalCommittee"
              element={
                <Governance
                  pageId={5}
                  pageName={`Student Grievance Redressal`}
                  pageContent={<StudentGrievance />}
                />
              }
            />
            <Route
              path="/governance/SC-ST-Committee"
              element={
                <Governance
                  pageId={6}
                  pageName={`SC-ST-Committee`}
                  pageContent={<SCST_Committee />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/IIC/AboutIIC"
              element={
                <IIC
                  pageId={0}
                  pageName={`About IIC`}
                  pageContent={<AboutIIC />}
                />
              }
            />
            <Route
              path="/IIC/ObjectivesOfIIC"
              element={
                <IIC
                  pageId={1}
                  pageName={`Objectives of IIC`}
                  pageContent={<ObjectivesOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/FunctionsOfIIC"
              element={
                <IIC
                  pageId={2}
                  pageName={`Functions of IIC`}
                  pageContent={<FunctionsOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/BenefitsOfIIC"
              element={
                <IIC
                  pageId={3}
                  pageName={`Benefits of IIC`}
                  pageContent={<BenefitsOfIIC />}
                />
              }
            />
            <Route
              path="/IIC/IICActivities"
              element={
                <IIC
                  pageId={4}
                  pageName={`IIC Activities`}
                  pageContent={<IICActivities />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/library/AboutLibrary"
              element={
                <Library
                  pageId={0}
                  pageName={`About Library`}
                  pageContent={<AboutLibrary />}
                />
              }
            />
            <Route
              path="/library/Resources"
              element={
                <Library
                  pageId={1}
                  pageName={`Library Resources`}
                  pageContent={<LibraryResources />}
                />
              }
            />
            <Route
              path="/library/Rules"
              element={
                <Library
                  pageId={2}
                  pageName={`Library Rules`}
                  pageContent={<LibraryRules />}
                />
              }
            />
            <Route
              path="/library/AssociateMemberships"
              element={
                <Library
                  pageId={3}
                  pageName={`Associate Memberships`}
                  pageContent={<AssociateMemberships />}
                />
              }
            />
            <Route
              path="/library/LibraryCommittee"
              element={
                <Library
                  pageId={4}
                  pageName={`Library Committee`}
                  pageContent={<LibraryCommittee />}
                />
              }
            />
            <Route
              path="/library/PreviousQuestionPapers"
              element={
                <Library
                  pageId={5}
                  pageName={`Previous Question Papers`}
                  pageContent={<PreviousQuestionPapers />}
                />
              }
            />
            {/* <Route path="/library/WebOPAC" element = {<Library pageId={5} pageName={`Web OPAC`} pageContent={ <WebOPAC/>} />} />
              <Route path="/library/DELNET" element = {<Library pageId={6} pageName={`DELNET`} pageContent={ <DELNET/>} />} />
              <Route path="/library/NationalDigitalLibrary" element = {<Library pageId={7} pageName={`National Digital Library`} pageContent={ <NationalDigitalLibrary/>} />} />
              <Route path="/library/SPPUJaykarLibrary" element = {<Library pageId={8} pageName={`SPPU Jaykar Library`} pageContent={ <NationalDigitalLibrary/>} />} /> */}
          </Route>

          <Route>
            <Route
              path="/placement/PlacementCell"
              element={
                <TrainingPlacement
                  pageId={0}
                  pageName={`About Placement Cell`}
                  pageContent={<PlacementCell />}
                />
              }
            />

            {/* <Route
              path="/placement/SummerIntershipProgram"
              element={
                <TrainingPlacement
                  pageId={2}
                  pageName={`Summer Intership Program / IT Project`}
                  pageContent={<SummerIntershipProgram />}
                />
              }
            /> */}
            <Route
              path="/placement/OurRecruiters"
              element={
                <TrainingPlacement
                  pageId={3}
                  pageName={`Our Recruiters`}
                  pageContent={<OurRecruiters />}
                />
              }
            />
            {/* <Route
              path="/placement/PlacementBrouchere"
              element={
                <TrainingPlacement
                  pageId={4}
                  pageName={`Placement Brouchere`}
                  pageContent={<PlacementBrouchere />}
                />
              }
            /> */}
            <Route
              path="/placement/Collaborations"
              element={
                <TrainingPlacement
                  pageId={5}
                  pageName={`Collaborations`}
                  pageContent={<Collaborations />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/naac/AccreditationStatus"
              element={
                <NAAC
                  pageId={0}
                  pageName={`Accreditation Certificate and Status`}
                  pageContent={<AccreditationStatus />}
                />
              }
            />
            <Route
              path="/naac/SSR"
              element={
                <NAAC
                  pageId={1}
                  pageName={`Self-Study Report (SSR)`}
                  pageContent={<SSR />}
                />
              }
            />
            <Route
              path="/naac/Feedback"
              element={
                <NAAC
                  pageId={2}
                  pageName={`Feedback`}
                  pageContent={<Feedback />}
                />
              }
            />
            {/* <Route path="/naac/BestPractices" element = {<NAAC pageId={3} pageName={`Best Practices`} pageContent={ <BestPractices/>} />} /> */}
          </Route>

          <Route>
            <Route
              path="/BestPractices/HRMeet"
              element={
                <BestPractices
                  pageId={0}
                  pageName={`HR Meet`}
                  pageContent={<HRmeet />}
                />
              }
            />
            <Route
              path="/BestPractices/AlumniMeet"
              element={
                <BestPractices
                  pageId={1}
                  pageName={`Alumni Meet`}
                  pageContent={<AlumniMeet />}
                />
              }
            />
            <Route
              path="/BestPractices/2022-23"
              element={
                <BestPractice2223
                  pageId={0}
                  pageName={`Best Practice 2022-23`}
                  pageContent={<Practice2223 />}
                />
              }
            />
          </Route>
          <Route>
            <Route
              path="/contactTPofficer/PlacementOfficer"
              element={
                <ContactTPofficer
                  pageId={1}
                  pageName={`Message From Placement Officer`}
                  pageContent={<PlacementOfficer />}
                />
              }
            />
          </Route>
          <Route>
            <Route
              path="/contactTPofficer/trainingOfficer"
              element={
                <ContactTPofficer
                  pageId={1}
                  pageName={`Message from Training Officer`}
                  pageContent={<TrainingOfficer />}
                />
              }
            />
          </Route>
          <Route>
            <Route
              path="/AQAR/AQAR2021-22"
              element={
                <AQAR
                  pageId={0}
                  pageName={`AQAR 2021-22`}
                  pageContent={<AQAR202122 />}
                />
              }
            />
            <Route
              path="/AQAR/AQAR2020-21"
              element={
                <AQAR
                  pageId={1}
                  pageName={`AQAR 2020-21`}
                  pageContent={<AQAR202021 />}
                />
              }
            />
            {/* <Route path="/AQAR/AQAR2019-20" element = {<AQAR pageId={2} pageName={`AQAR 2019-20`} pageContent={ <AQAR201920/>} />} />
              <Route path="/AQAR/AQAR2018-19" element = {<AQAR pageId={3} pageName={`AQAR 2018-19`} pageContent={ <AQAR201819/>} />} /> */}
          </Route>

          <Route>
            <Route
              path="/IQAC/Minutes/Aug2022"
              element={
                <IQAC
                  pageId={0}
                  pageName={`August 2022`}
                  pageContent={<Aug2022 />}
                />
              }
            />
            <Route
              path="/IQAC/Minutes/Dec2022"
              element={
                <IQAC
                  pageId={1}
                  pageName={`December 2022`}
                  pageContent={<Dec2022 />}
                />
              }
            />
          </Route>

          <Route>
            <Route
              path="/Committee/InternalCommittee"
              element={
                <Committee
                  pageId={0}
                  pageName={`Internal Complaint Committee`}
                  pageContent={<InternalCommittee />}
                />
              }
            />
            <Route
              path="/Committee/AntiRaggingCommittee"
              element={
                <Committee
                  pageId={1}
                  pageName={`Anti-Ragging Committee & Squad`}
                  pageContent={<AntiRaggingCommittee />}
                />
              }
            />
            <Route
              path="/Committee/StudentGrievenceCell"
              element={
                <Committee
                  pageId={2}
                  pageName={`Student Grievence Cell`}
                  pageContent={<StudentGrievenceCell />}
                />
              }
            />
            <Route
              path="/Committee/SC-STCommittee"
              element={
                <Committee
                  pageId={3}
                  pageName={`SC-ST Committee`}
                  pageContent={<SCSTCommittee />}
                />
              }
            />
          </Route>

          {/* <Route>
              <Route path="/gallery/IndustrialVisit" element = {<Gallery pageId={0} pageName={`Industrial Visit`} pageContent={ <IndustrialVisitGallery/>} />} />
              <Route path="/gallery/CulturalProgram" element = {<Gallery pageId={1} pageName={`Cultural Program`} pageContent={ <CulturalProgramGallery/>} />} />
              <Route path="/gallery/Sports" element = {<Gallery pageId={2} pageName={`Sports`} pageContent={ <SportsGallery/>} />} />
              <Route path="/gallery/ExtensionActivities" element = {<Gallery pageId={3} pageName={`Extension Activities`} pageContent={ <ExtensionActivitiesGallery/>} />} />
              <Route path="/gallery/TrainingPlacement" element = {<Gallery pageId={4} pageName={`Training and Placement`} pageContent={ <TrainingPlacementGallery/>} />} />
              <Route path="/gallery/Infrastructure" element = {<Gallery pageId={5} pageName={`Infrastructure`} pageContent={ <InfrastructureGallery/>} />} />
              <Route path="/gallery/AlumniMeet" element = {<Gallery pageId={6} pageName={`Alumni Meet`} pageContent={ <AlumniMeetGallery/>} />} />
              <Route path="/gallery/HRMeet" element = {<Gallery pageId={7} pageName={`HR Meet`} pageContent={ <HRMeetGallery/>} />} />
            </Route> */}

          <Route>
            <Route
              path="/alumni/AlumniCell"
              element={
                <Alumni
                  pageId={0}
                  pageName={`About Alumni Cell`}
                  pageContent={<AlumniCell />}
                />
              }
            />
            <Route
              path="/alumni/AlumniAchievers"
              element={
                <Alumni
                  pageId={1}
                  pageName={`Alumni Achievers`}
                  pageContent={<AlumniAchievers />}
                />
              }
            />
            <Route
              path="/alumni/AlumniRegistration"
              element={
                <Alumni
                  pageId={2}
                  pageName={`Alumni Registration`}
                  pageContent={<AlumniRegistration />}
                />
              }
            />
          </Route>

          <Route path="/contact" element={<Contact />} />
          <Route path="/admissionEnquiry" element={<AdmissionEnquiry />} />

          <Route path="/gallery" element={<GalleryView />} />
          <Route path="/sample" element={<Sample />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
