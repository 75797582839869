import React from 'react'
import { TableComponent2colm } from '../../Components/TableComponent'

export default function CollaborationData() {
  const userData = [
    {
      col1: 'Pratham Infotech foundation' ,
      col2:'Soft skill training , internship & Placement assistance',
    },
    {
        col1: 'DigitalEdu Pvt. Ltd. ' ,
        col2:'Internship & Placement assistance',
    },
   {
      col1: 'Vivekananda Kendra Kanyakumari' ,
      col2: 'Training & students improvement',
    },
    {
      col1: 'Tachway software solutions' ,
      col2:'SAP Training , Internship & placement assistance',
    },
    {
      col1: 'ExcelR' ,
      col2:'Training & Placement assistance',
    },
    {
      col1: 'Mitu Skillologies Pvt Ltd' ,
      col2:'Academic and Research',
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Mou signed with ', 'Objective of MOU',''],
  };

  return (
    <div>
        
      <TableComponent2colm data={userData} headers={tableHeaders} />
    </div>
  );
}
