import React from 'react';
import img from '../assets/logo/underCode.jpg'

const UnderMaintanence = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f0f0f0', textAlign: 'center' , padding:'20px'}}>
      <div style={{ margin: '100px auto', maxWidth: '600px', padding: '20px 50px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <h1 style={{ color: '#ff2600' }}>Website Under Maintenance</h1>
        <p style={{ fontSize: '18px' }}>We're sorry, but our website is currently undergoing maintenance.</p>
        <p style={{ fontSize: '18px' }}>Please check back later.</p>
        <img src={img} style={{ height: '50px', width: '50px' }} alt="Maintenance Image" />
      </div>
    </div>
  );
};

export default UnderMaintanence;
