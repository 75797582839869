import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";

const sidemenus = [
    {
        title: 'About IIC',
        url: '/IIC/AboutIIC',
      },
      {
        title: 'Objectives of IIC',
        url: '/IIC/ObjectivesOfIIC',
      },
      {
        title: 'Functions of IIC',
        url: '/IIC/FunctionsOfIIC',
      },
      {
        title: 'Benefits of IIC ',
        url: '/IIC/BenefitsOfIIC',
      },
      {
        title: 'IIC Activities',
        url: '/IIC/IICActivities',
      },
];

export default function IIC(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export function AboutIIC(){
    return(
      <div>
        <div>
        <h5 className="title-text mb-3">About Institution’s Innovation Council (IIC):</h5>
        <p>
        Innovation Cell is thus envisaged to network people, ideas, experience and resources to motivate the innovation community in the college. This cell helps to nurture the students’ ideas and encourage them to bring up business proposals. The Innovation Cell may facilitate even in getting funds for the project.
        </p>
        <p>
        Learning is a continuous process and there are always possibilities for improvement in the existing system around us. Using the facilities of workshops, laboratories, library including digital library facility, internet and intranet facilities and research facilities available in the institute, innovative ideas could be translated into reality or existing systems could be improved upon.
        </p>
        <p>
        The main aim of this Cell is, thus, to create intuition in terms of creative design ideas in various fields of engineering in an aesthetic approach. Innovation Cell will assist students and also encourage young talented minds by providing them a perfect platform for showcasing their talents, working together as teams and participate in various competitions. It will also serve to provide the students a platform to excel in their specialized area of interest.
        </p>
      </div>
      </div>
    );
}

export function ObjectivesOfIIC(){
    return(
      <div>
             <>
        <div>
        <h5 className="title-text mb-3">Objectives</h5>
        <p>
        To motivate students to bring out their hidden talents in various disciplines of Engineering.
        </p>
        <ul>
            <li> To provide a platform to realize and believe in themselves.</li>
            <li> To empower students to become young entrepreneurs.</li>
            <li> To create an innovative environment for the students to discover, develop, deploy and express their skills and talents.</li>
            <li> To motivate students to take part in Hackathons & prototype development, Technical Tutorials.</li>
            <li> To encourage students to carry out their hidden talents in various disciplines of Engineering.</li>
        </ul>
        
      </div>
      </>
      </div>
    );
}

export function FunctionsOfIIC(){
    return(
      <div>
       <div>
        <h5 className="title-text mb-3">Functions of Institution’s Innovation Council</h5>
      
        <ul>
            <li> Promote innovation in the Institution through multitudinous modes leading to an innovation promotion eco-system in the campus.</li>
            <li> To conduct various innovation and entrepreneurship-related activities prescribed by Central MIC in time bounded fashion.</li>
            <li> Identify and reward innovations and share success stories.</li>
            <li> Organize periodic workshops/ seminars/ interactions with entrepreneurs, investors, professionals and create a mentor pool for student innovators.</li>
            <li> Network with peers and national entrepreneurship development organizations.</li>
            <li> Create an Institution’s Innovation portal to highlight innovative projects carried out by institution’s faculty and students.</li>
            <li> Organize Hackathons, idea competition, mini-challenges etc with the involvement of industries.</li>
        </ul>
        
      </div>
      </div>
    );
}
export function BenefitsOfIIC(){
    return(
      <div>
       <div>
        <h5 className="title-text mb-3">Benefits</h5>
      
        <ul>
            <li> No major capital investment required for establishing IIC as it will make use of existing local ecosystem.</li>
            <li> Students/Faculty associated with IIC will get exclusive opportunity to participate in various Innovation related initiatives and competitions organized by MHRD.</li>
            <li> Win exciting Prizes/Certificates every month.</li>
            <li> Meet/Interact with renowned Business Leaders and top-notch academicians</li>
            <li> Opportunity to nurture and prototype new ideas</li>
            <li> Mentoring by Industry Professionals</li>
            <li> Experimentation with new/latest technologies</li>
            <li> Visit new places and learn a new cultur</li>
        </ul>
        
      </div>
      </div>
    );
}
export function IICActivities(){
    return(
      <div>
       <div>
       <h5 className="title-text my-2 mb-4">IIC Activities</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={process.env.PUBLIC_URL+'/assets/pdf/IIC_Activities.pdf'}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to={process.env.PUBLIC_URL+'/assets/pdf/IIC_Activities.pdf'}> click here to download the PDF file. </Link></p>
        </object>
      </div>
      </div>
      </div>
    );
}