import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

function GalleryView () {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    "/assets/img/gallery/1.jpg",
    "/assets/img/gallery/2.jpg",
    "/assets/img/gallery/3.jpg",
    "/assets/img/gallery/4.jpg",
    "/assets/img/gallery/5.jpg",
    "/assets/img/gallery/5.jpg",
    "/assets/img/gallery/5.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
	// Gallery
	<div className="container">
		<div className="default-padding">
			<div className="text-center" data-aos="fade-up">
				<h5 className="title-text">GALLERY</h5>
				<h2>Recent Gallery</h2>
			</div>
			<div className="default-padding d-flex justify-content-center flex-wrap">
				{images.map((src, index) => (
					<img
						src={src}
						onClick={() => openImageViewer(index)}
						width="250"
						key={index}
						style={{ margin: "8px" }}
						alt=""
					/>
				))}

				{isViewerOpen && (
					<ImageViewer
						src={images}
						currentIndex={currentImage}
						onClose={closeImageViewer}
						disableScroll={false}
						backgroundStyle={{
						backgroundColor: "rgba(0,0,0,0.9)"
					}}
						closeOnClickOutside={false}
					/>
				)}
			</div>
		</div>
	</div>
  );
}

export default GalleryView;