import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import SSR_pdf from '../assets/PDF/NAAC/SSR.pdf';
import naacCertificate from '../assets/PDF/NAAC/NAAC_Certificate.pdf'
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const sidemenus = [
    {
        title: 'Accreditation Certificate and Status',
        url: '/naac/AccreditationStatus'
    },
    {
        title: 'Self-Study Report (SSR)',
        url: '/naac/SSR'
    },
    {
        title: 'Feedback',
        url: '/naac/Feedback'
    },
    // {
    //     title: 'Best Practices',
    //     url:'/naac/BestPractices'
    // },
];

export default function NAAC(props) {
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class', 'active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });

        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return (
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AccreditationStatus() {
    return (
        <>
            <div>
                <h5 className="title-text my-2 mb-4">Accreditation Certificate and Status</h5>
                <div>
                    <object
                        // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
                        data={naacCertificate}
                        type="application/pdf"
                        width="100%"
                        height={800}
                    >
                        <p>Your web browser doesn't have a PDF plugin.</p>
                        <p>Instead you can <Link to={naacCertificate}> click here to download the PDF file.</Link></p>
                    </object>
                </div>
            </div>
        </>
    );
}

export function SSR() {
    return (
        <div>
            <h5 className="title-text my-2 mb-4">Self-Study Report (SSR) </h5>
            <div>
                <object
                    // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
                    data={SSR_pdf}
                    type="application/pdf"
                    width="100%"
                    height={800}
                >
                    <p>Your web browser doesn't have a PDF plugin.</p>
                    <p>Instead you can <Link to={SSR_pdf}> click here to download the PDF file.</Link></p>
                </object>
            </div>
        </div>
    );
}

export function Feedback() {
    return (
        <div>
            <div>
                <h5 className="title-text py-3">Students Feedback : </h5>
                <div>
                 Click Here <ArrowRightIcon /><a href="https://www.aicte-india.org/feedback/students.php" target="_blank" className="btn btn-outline-light mt-0" style={{color:'steelblue'}}> https://www.aicte-india.org/feedback/students.php</a>
                </div>
            </div>
            <div>
                <h5 className="title-text py-3">Faculty Feedback : </h5>
                <div>
                 Click Here <ArrowRightIcon /><a  href="https://www.aicte-india.org/feedback/faculty.php" target="_blank"  className="btn btn-outline-light mt-0" style={{color:'steelblue'}}> https://www.aicte-india.org/feedback/faculty.php</a>
                </div>
            </div>
        </div>
    );
}

