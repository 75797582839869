import React, { useState, useEffect } from "react";
import "./UpcomingEvents.css";
import Blink from "react-blink-text";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { UpcomingData } from "../../../Data/UpcomingEventsData";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { UpcomingIcon } from "../../SvgIcons";
import { Link } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UpcomingEvents = ({}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleTexts, setVisibleTexts] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === UpcomingData?.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const nextIndex1 = (currentIndex + 1) % UpcomingData?.length;
    //const nextIndex2 = (currentIndex + 2) % UpcomingData.length;
    //const nextIndex3 = ( currentIndex + 3) % UpcomingData.length;
    setVisibleTexts([
      UpcomingData[currentIndex],
      UpcomingData[nextIndex1],
      //   UpcomingData[nextIndex2],
      // UpcomingData[nextIndex3],
    ]);
  }, [currentIndex]);

  return (
    <div className="upcoming-event text-justify" style={{ height: "400px" }}>
      {visibleTexts.map((info, index) => (
        <div className="upcoming-texts" key={index}>
          <div className="home-card-content d-flex flex-column gap-1">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid xs={1.5} className="home-card-title mt-1">
                  <div className="upcomingDate">
                    <EventIcon /> {info?.date}
                  </div>
                </Grid>
                <Grid xs={10.5}>
                  <h5 className="home-card-title text-wrap"> {info?.title}</h5>{" "}
                  {/*Newstitle*/}
                  <div className="home-card-body container">
                    {/*Newsbody*/}
                    {/* <a href={info?.description} style={{ color: 'steelblue', fontSize: 13 }}>
                      View More...
                    </a> */}
                    <Link
                      to={"/UpcomingEventPage"}
                      style={{ color: "steelblue", fontSize: 13 }}
                    >
                      {" "}
                      View More...
                    </Link>
                  </div>
                  <div className="Application home-card-Footer mt-5">
                    {/*NewsFooter*/}
                    <Blink
                      color="gray"
                      text={info?.updatedDate ? info?.updatedDate : "Upcoming"}
                      fontSize={15}
                    ></Blink>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingEvents;
