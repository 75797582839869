import React from 'react';

const TableComponent = ({ data, headers }) => {
  return (
    <div className="table-responsive">
      <table className="table table-light">
        {/* <caption>{headers.caption}</caption> */}
        <thead>
          <tr>
            {headers.columns.map((column, index) => (
              <th key={index} scope="col">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{user.NameOfTheMember}</td>
              <td>{user.Designation}</td>
              <td>{user.PositionInTheCommittee}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;

export function TableComponent2colm({ data, headers }) {
  return (
    <div className="table-responsive">
    <table className="table table-light">
      {/* <caption>{headers.caption}</caption> */}
      <thead>
        <tr>
          {headers.columns.map((column, index) => (
            <th key={index} scope="col">
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((user, index) => (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{user.col1}</td>
            <td>{user.col2}</td>
            <td>{user.col3}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}
