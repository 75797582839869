import React from 'react';
import { TableComponent2colm } from '../../Components/TableComponent';


export  function OnlineGrievanceData() {
  const userData = [
    {
      col1: 'Dr. Maithili Arjunwadkar ' ,
      col2:'Chairperson',
      col3:'Director'
  },
  {
    col1: 'Dr.Manoj Sathe' ,
    col2: 'Member',
    col3:'Co-ordinator, MIBS'
  },
  {
    col1: 'Prof. Dr. P.D. Choudhari' ,
    col2:'Ombudsman (Appointed by SPPU)',
    col3:'PES Modern College of  Pharmacy'
  },

    {
      col1: 'Mrs.Mrudula Madiwale' ,
      col2:'Member',
      col3:'Non – Teaching staff'
    },
    {
      col1: 'Mrs.Snehal Padhye' ,
      col2:'Member',
      col3:'Non – Teaching staff'
    },
    {
      col1: 'Miss.Vedanti Gadekar' ,
      col2:'Member',
      col3:'Student Representative'
    },
    {
      col1: 'Mr.Akash Patil' ,
      col2:'Member',
      col3:'Student  Representative'
    },
    {
      col1: 'Prof.Mahesh Salvi' ,
      col2:'Member Secretary',
      col3:'Teaching Staff'
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name of the member', 'Position in the Committee', 'Authority'],
  };

  return (
    <div>
      <TableComponent2colm data={userData} headers={tableHeaders} />
    </div>
  );
};