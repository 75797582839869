import { Grid, Paper } from "@mui/material";
import React from "react";
import Divider from "@mui/material/Divider";
import ArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";

export default function AdmissionEnquiry() {
  return (
    <>
      <Paper
        elevation={3}
        className="mx-4 my-4 px-5"
        style={{ minHeight: "400px" }}
      >
        <h3 className="my-3 text-bold text-center pt-4">ADMISSION</h3>
        <h5 className="my-3 title-text text-center">
          Admission Process For A.Y. 2024-25
        </h5>
        <Divider sx={{ border: 1 }} />
        <Grid
          container
          columns={{ xs: 12, sm: 24, md: 18 }}
          sx={{ p: "auto", width: "100%" }}
        >
          <Grid sm={12} md={6} className="px-3">
            <h5 className="my-3">Enquiry for BCA for Academic Year 2024-25</h5>
            <h6 className="my-3 title-text text-bold">
              For BCA Admission Enquiry please contact:
            </h6>
            <span>
              Click <ArrowIcon className="mb-1" />
              <Link
                target="_blank"
                to={
                  "https://docs.google.com/forms/d/e/1FAIpQLSeyLGrDyALoI53Bp7DsJySSETvlZB5VrUSb-Js-B1wNfEsRRw/viewform?vc=0&c=0&w=1&flr=0"
                }
              >
                <button id="blinking-button" className="p-1 px-5">
                  BCA Enquiry form
                </button>
              </Link>
            </span>
            <p className="py-4">
              <b>Prof Dr. Manoj Sathe : </b> +91 8605858150
            </p>
            <h5 className="my-3 text-bold">BCA DTE CODE: 6812</h5>
          </Grid>
          <Grid sm={12} md={6} className="px-3">
            <h5 className="my-3">Enquiry for MCA for Academic Year 2024-25</h5>
            <h6 className="my-3 title-text text-bold">
              For MCA Admission Enquiry please contact:
            </h6>
            <span>
              Click <ArrowIcon className="mb-1" />
              <Link
                target="_blank"
                to={
                  "https://docs.google.com/forms/u/0/d/1pL6Ye---FHjVw16ijhztkJbX2cLnG5RyXpDxNMgRpgE/viewform?pli=1&pli=1&edit_requested=true"
                }
              >
                <button id="blinking-button" className="p-1 px-5">
                  MCA Enquiry form
                </button>
              </Link>
            </span>
            <p className="py-4">
              <b>Prof Gajanan Badhe : </b>+91 9822541826
            </p>
            <h5 className="my-3 text-bold">MCA DTE CODE: 6812</h5>
          </Grid>
          <Grid sm={12} md={6} className="px-3">
            <h5 className="my-3">Enquiry for MBA for Academic Year 2024-25</h5>
            <h6 className="my-3 title-text text-bold">
              For MBA Admission Enquiry please contact:
            </h6>
            <span>
              Click <ArrowIcon className="mb-1" />
              <Link
                target="_blank"
                to={
                  "https://docs.google.com/forms/u/0/d/1pL6Ye---FHjVw16ijhztkJbX2cLnG5RyXpDxNMgRpgE/viewform?pli=1&pli=1&edit_requested=true"
                }
              >
                <button id="blinking-button" className="p-1 px-5">
                  MBA Enquiry form
                </button>
              </Link>
            </span>
            <p className="py-4">
              <b>Prof. Mahesh Salvi : </b> +91 9545450545
            </p>
            <h5 className="my-3 text-bold">MBA DTE CODE : 6812</h5>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
