import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import Divider from '@mui/material/Divider';
import { TableComponent2colm } from "../Components/TableComponent";
import { HrMeetTable } from "../Data/Naac/BestPracticesData";
import HRImg1 from '../assets/BestPractices/HR Meet1.jpg';
import HRImg2 from '../assets/BestPractices/HR Meet 2.jpg';
import AlumniImg1 from '../assets/BestPractices/AlumniMeet1.jpg';
import AlumniImg2 from '../assets/BestPractices/AlumniMeet2.jpg';


const sidemenus = [
    {
        title: 'HR Meet',
        url: '/BestPractices/HRMeet'
    },
    {
        title: 'Alumni Meet',
        url: '/BestPractices/AlumniMeet'
    },
];

export default function BestPractices(props) {
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class', 'active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });

        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return (
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu, index) => {
                                return (
                                    <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export function HRmeet() {
    return (
        <div className="text-justify">
            <h5 className="title-text my-4 mt-2"> HR MEET: Best Practice OF MIBS</h5>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">1) Title of the practice : </h6>
            <p className="mt-3">HUMAN RESOURCE MEET</p>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">2) Objectives of the Practice : </h6>
            <div>
                <ul>
                    <li>To enhance Industry institution Interaction</li>
                    <li>To encourage the faculty for developing and designing case studies with local Industries.</li>
                    <li>To strengthen, research, training and consultancy based opportunities in line with need of Industries.</li>
                    <li>To strengthen the Industry approach towards students SIP.</li>
                    <li>To strengthen the placement cell and students participation in placement cell activities.</li>
                    <li>To develop the Industry Readiness platform for the students.</li>
                    <li>To enhance the communication skill and skill development activities.</li>
                    <li>To arranging Regular Guest lectures, workshops in association with ‘HR CONNECT’. </li>
                    <li>To enhance Active student participation in program arrange by HR Forum.</li>
                </ul>
            </div>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">3) The Context :</h6>
            <div>
                <p>The concept was challenging one to introduce as there were issues such as –</p>
                <ul>
                    <li>Getting permission from Authority</li>
                    <li>Explaining the concept of HR MEET program and its benefits to faculties and students.</li>
                    <li>Scheduling the activities as per convenience of the HR FORUM and institution’s academic schedule.</li>
                    <li>Active participation of the students.</li>
                </ul>
            </div>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">4) The Practice :</h6>
            <div>
                <p>As The Institute established in Industrial belt, has a most the convenient place to   contact industry experts. Last seven years continuously arranging HR meet in Institute. For conducting the hr meet following steps have been taken .</p>
                <ul>
                    <li>The HR FORUM & Institute faculties communicate about the conducting HR MEET.</li>
                    <li>Institute and forum schedule the program.</li>
                    <li>Institute prepares the pamphlets, notices, flex.</li>
                    <li>Auditorium gets ready for the event.</li>
                    <li>Faculties and students committee takes the effort for the success of the program.</li>
                </ul>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">5) Evidence of success :</h6>
            <div>
                <p>As The Institute established in Industrial belt, has a most the convenient place to   contact industry experts. Last seven years continuously arranging HR meet in Institute. For conducting the hr meet following steps have been taken .</p>
                <ul>
                    <li>Provide evidence of success such as the objectives of the programme is highly achieved. It encourages the faculties to develop and enhance Research activities, hands on experiences of live cases shared by industry experts.</li>
                    <li>Industry experts sharing the expert knowledge, experiences with students. It is an Great opportunities to students to discussed with industry experts. </li>
                    <li>All the participants enhance their knowledge relating to current updates and know-how of the Industries. </li>
                </ul>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">6) Problems Encountered and Resources Required : </h6>
            <div>
                <p>The problem encountered are :- </p>
                <ul>
                    <li>Initially arranging and scheduling the HR MEET is very crucial task. Cause of hectic schedule of HR FORUM and Guest speaker.</li>
                    <li>The time management. Both of us have to schedule in such way that it should not disturb the routine work of the industry executives. </li>
                    <li>Availability of Industry experts/Speakers is also one of the major task.
                        The problem were overcome by carefully & systematic planning of HR FORUM and Institute. The resources require mainly well equipped Auditorium and well planned sitting arrangement.</li>
                    <li>As last three years Covid-19 Epidemic highly impacted on these practices. Even then, after lockdown in feb 2020 Institute successfully conducted the HR MEET.</li>
                </ul>

                <p className="text-bold">Notes: HR MEET is a one of the Best practice of the MIBS ( formerly known IMCD) Management support& students support , the Institute successfully carried on number of HR MEETS.</p>
            </div>
              <div>
                  <figure>
                    <img  src={HRImg1} height={400} width={700} className="px-5"/>
                  </figure>
                  <figure>
                    <img  src={HRImg2} height={400} width={700} className="px-5"/>
                  </figure>
              <h5 className="text-bold title-text my-2">Industry-Institution Interaction : HUMAN RESOURCE MEET</h5>
                <HrMeetTable />
              </div>
        </div>
    );
}

export function AlumniMeet() {
    return (
        <div className="text-justify">
            <h5 className="title-text my-4 mt-2">Alumni Meet</h5>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">1) Title : </h6>
            <p className="mt-3 text-bold">Alumni contribution to Student Development</p>


            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">2) Objectives:- </h6>
            <div>
                <p>The main objective of the Alumni Contribution are: </p>
                <ul>
                    <li>To strengthen networking of all the alumni</li>
                    <li>To seek for academic and professional support from alumni</li>
                    <li>To find out ways and means of support from alumni</li>
                    <li>conducting  various Academic, Research and providing of placement and training support</li>
                    <li>To be seek suggestions from alumni</li>
                    <li>To seek support from alumni on academic fronts, extra inputs, placements and internships.</li>
                    <li>To extend support and guidance for functioning of various students committees.</li>
                    <li>Alumni who are entrepreneurs have been providing inputs on how to start a new venture and turning them in to job providers</li>
                    <li>Alumni as ambassadors of MIBS for promotion of branding of MIBS </li>
                </ul>
            </div>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">3) Context :</h6>
            <div>
                <p>What were the contextual features or challenging issues that needed to be addressed in designing and implementing this practice (in about 150 words)? The practice of Alumni contributions to current student’s development was designed in context of:</p>
                <ol>
                    <li>The Alumni of the Institute have signed the MOU with Institute for  conducting  various Academic, Research and providing of placement and training support.</li>
                    <li>Alumni conducted expert Sessions, Personality Development, Career Counseling, Entrepreneurship Development, mock personal interviews.</li>
                    <li>Discussing business and entrepreneurship opportunities and have had frequent interactions with the students. </li>
                    <li>Active participation of the students.Alumni have highlighting the importance of current trends in the market and will guide the students about the career opportunities in different fields. </li>
                    <li>Alumni will provided extra imputes to students, shared their personal experiences in professional life, and narrated their life journey mainly professional journey till date with students.</li>
                    <li>Alumni will also support the current batches  in planning and organizing events, extend support and guidance for functioning of various students committees.</li>
                    <li>Alumni who are entrepreneurs will providing inputs on how to start a new venture and turning them in to job providers. </li>
                    <li>Alumni extend their support for campus placements and summer and winter internships.</li>
                    <li>Alunmi will provide inputs to the students choosing their specialisation in the second year. From the last two years.</li>
                </ol>
            </div>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">4) The Practice :</h6>
            <div>
                <p>Describe the practice and its uniqueness in the context of India higher education. What were the constraints / limitations, if any, faced (in about 400 words)?</p>
                <ul>
                    <li>The Alumni of the Institute have signed the MOU with Institute for conducting various Academic, Research and providing of placement and training support.</li>
                    <li>Till now, Alumni conducted expert Sessions, Personality Development, Career Counselling, Entrepreneurship Development, mock personal interviews.</li>
                    <li>Alumni discussed business and entrepreneurship opportunities and have had frequent interactions with the students. During the interactions alumni have highlighted the importance of current trends in the market and guided the students about the career opportunities in different fields. </li>
                    <li>Alumni provided extra imputes to students, shared their personal experiences in professional life, and narrated their life journey mainly professional journey till date with students. </li>
                    <li>Alumni also  support the current batches  in planning and organizing events, extend support and guidance for functioning of various students committees. </li>
                    <li>Alumni who are entrepreneurs have been providing inputs on how to start a new venture and turning them in to job providers. </li>
                    <li>Alumni extend their support for campus placements and summer and winter internships. </li>
                    <li>Alumni also provide inputs to the students choosing their specialisation in the second year. From the last two years, Alumni have taken tremendous efforts in planning and organising state level and National conferences and seminars. </li>
                </ul>
            </div>
            <h6 className="text-bold">Uniqueness in the context of India higher education:</h6>
            <h6 className="text-bold">Institute has registered Alumni Association</h6>
            <p>At this stage it is worth noting that since the institute is established in 1991 it is more than 28 years since our students have entered into the professional world MIBS is blessed to have rich alumni base and a strong support which needs to be channelized for the effective progress of the Institute. MoU with Alumni is one of the biggest uniqueness if the institute. Alumni constantly taking efforts for student support and progression.</p>

            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">5) Evidence of success :</h6>
            <div>
                <p>Provide evidence of success such as performance against targets and benchmarks, review Results. What do these results indicate? Describe in about 200 words.</p>
                <p>Modern Institute of Business Studies (MIBS) is in the field of management education since last 28 years. Institute has Registered Alumni Association. Every year the alumni association meetings are held with the main objective of informing the alumni the various activities at the institute. Alumni of our institute have show keen interest and are always ever ready and enthusiastic to support MIBS. They are ever grateful to MIBS for the strong foundation laid in their lives. </p>
                <ul>
                    <li>Many students have been benefitted by way of Industry readiness training organized by alumni</li>
                    <li>Alumni has conducted career guidance sessions. </li>
                    <li>Many alumni delivered expert talks on different technical and skill based topics, which helped them in getting better placements</li>
                    <li>Students have been entered in entrepreneurship due to motivation from alumni</li>
                    <li>Certificate course on Personnel Administration, Entrepreneur awareness conducted by our alumni Mr. Zahir sheikh, Kuldeep Mokashi, Devendra Mali.</li>
                    <li>Alumni are working on various college bodies like College Development Committee, Internal Quality Assurance Cell, contributing significantly by providing suggestions in syllabus revision to shorten the gap between Industry –Institution</li>
                </ul>
            </div>
            <Divider sx={{ border: 1, marginBottom: 1 }} />
            <h6 className="text-bold">6) Problems Encountered and Resources Required : </h6>
            <div>
                <p>
                   Institute has not encountered any major problem as such. Only due to last three years covid-19 epidemic conducting alumni meet was crucial task. Even then we conducted online alumni meet. It was observed that due internet connectivity issues few of them were unable to join the meet.
                </p>
                <p>
                   The Alumni of the Institute have signed the MOU with Institute for conducting various Academic, Research and providing of placement and training support.Till now.Alumni conducted expert Sessions, Personality Development, Career Counselling, Entrepreneurship Development, mock personal interviews, discussed business and entrepreneurship opportunities and have had frequent interactions with the students. During the interactions alumni have highlighted the importance of current trends in the market and guided the students about the career opportunities in different fields. Alumni provided extra imputes to students, shared their personal experiences in professional life, and narrated their life journey mainly professional journey till date with students. Alumni also support the current batches in planning and organizing events, extend support and guidance for functioning of various students committees. Alumni who are entrepreneurs have been providing inputs on how to start a new venture and turning them in to job providers. Alumni extend their support for campus placements and summer and winter internships;provide inputs to the students choosing their specialisation in the second year. From the last two years, Alumni have taken tremendous efforts in planning and organising state level and National conferences and seminars.
                </p>
                <p>
                   At this stage it is worth noting that since the institute is established in 1991 it is more than 28 years since our students have entered into the professional world IMCD is blessed to have rich alumni base and a strong support which needs to be channelized for the effective progress of the Institute.
                </p>
                <p>
                   All efforts are being made for the same.
                </p>
                <p>
                   Meetings are held continuously and minutes are recorded.
                </p>
            </div>
            <div>
                  <figure>
                  <img src={AlumniImg1} height={400} width={700} className="px-5"/>
                  </figure>
                  <figure>
                  <img src={AlumniImg2} height={400} width={700} className="px-5"/>
                  </figure>
            </div>
        </div>
    );
}