
import React from 'react';
import ebook from '../assets/Library/Ebook.png';
import doaj from '../assets/Library/doaj.png';
import mccia from '../assets/Library/MCCIA.png';
import swayam from '../assets/Library/Swayam.png';
import delnet from '../assets/Library/delnet.png';
import kumbh from '../assets/Library/kumbh.png';
import pathshala from '../assets/Library/pathshala.png';
import unipune from '../assets/Library/universityPune.png';
import vidya from '../assets/Library/vidyaMitra.png';
import D_library from '../assets/Library/digitalLibrary.png';
import { Link } from 'react-router-dom';


const TableComponent = ({ data, headers }) => {
    return (
      <div className="table-responsive">
        <table className="table table-light">
          {/* <caption>{headers.caption}</caption> */}
          <thead>
            <tr>
              {headers.columns.map((column, index) => (
                <th key={index} scope="col">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={index} className='text-justify'>
                <th>{index + 1}</th>
                <td>{user.col1}</td>
                <td>{user.col2}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const TableComponentImg = ({ data, headers }) => {
    return (
      <div className="table-responsive">
        <table className="table table-light">
          {/* <caption>{headers.caption}</caption> */}
          <thead>
            <tr>
              {headers.columns.map((column, index) => (
                <th key={index} scope="col">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={index} className='text-justify'>
                <th>{index + 1}</th>
                <td> <img src={user.img} alt='jj' style={{ minWidth: '100px', height: '100px' }} /></td>
                <td>{user.col1}</td>
                <td>{user.col2}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

export  function LibraryCommitteeTable(){
    const userData = [
      {
         col1: 'Dr. Maithili Arjunwadkar (Director MIBS)  ' ,
         col2:'Chairman ',
      },
      {
        col1: 'Dr. Mandakini Bhosale  ' ,
        col2:'Member  ',
      },
      {
        col1: 'Dr. Manoj Sathe ' ,
        col2:'Member  ',
      },
      {
        col1: 'Mrs. Aruna Kawathekar ' ,
        col2:'Member  ',
      },
      {
        col1: 'Mrs. Mrudula Madiwale ' ,
        col2:'Member  ',
      },
      {
        col1: 'Mr. Prashant Markale ' ,
        col2:'Member  ',
      },
      {
        col1: 'Akash Patil (MCA) ' ,
        col2:'Student Representative  ',
      },
      {
        col1: 'Sahil Pawar (MBA) ' ,
        col2:'Student Representative  ',
      },
      {
        col1: 'MR. Santosh Bagav (Librarian) ' ,
        col2:'Secretary ',
      },
      ];
      const tableHeaders = {
        columns: ['Sr No.','Member Name', 'Position'],
      };

      return (
        <div>
          <TableComponent data={userData} headers={tableHeaders} />
        </div>
      );
}

export  function LibraryResourcesTable(){
  const userData = [
    {
       col1: 'Total No. of Collections' ,
       col2:'10766',
    },
    {
      col1: 'Total No. of Titles' ,
      col2:'3958',
    },
    {
      col1: 'Total No. of Print  Journals' ,
      col2: '012',
    },
    {
      col1: 'Magazines' ,
      col2:'10',
    },
    {
      col1: 'E- Journals Full text & Abstract papers ' ,
      col2:'2500',
    },
    {
      col1: 'CD’s' ,
      col2: '150',
    },
    {
      col1: 'News Paper Marathi & English' ,
      col2:'06',
    },
    {
      col1: 'DELNET (Developing Library Network) New Delhi' ,
      col2:'Unlimited',
    },
    {
      col1: 'Associate Resource Membership ' ,
   
    },
    {
      col1: 'e-pg pathshala Resources / e- adhyayan' ,
   
    },
    {
      col1: 'Swayam prabha' ,
   
    },
    {
      col1: 'Vidya Mitra' ,
   
    },
    {
      col1: 'DOAJ( Directory of open access Journals)' ,
   
    },
    {
      col1: 'NDLI (National Digital Library India)' ,
   
    },
    ];
    const tableHeaders = {
      columns: ['Sr No.','Particular', 'Quantity'],
    };

    return (
      <div>
        <TableComponent data={userData} headers={tableHeaders} />
      </div>
    );
}

export  function LibraryAssociateTable(){
  
  const userData = [
    {
       img: unipune,
       col1:'Jayakar knowledge Resource  Centre    Savitribai Phule Pune University,Pune. participates in E-ShodhSindhu program. Under this program access to more than 8000 journals are provided. ⦁	It  is a part of Universal Digital Library program since 2002. Implementated  RFID technology in the library along with smart cards to faculty and students. Has internet center for students of the University. Holds manuscripts written in Sanskrit, Hindi and Marathi languages. Extends inter library loan system facility  to affiliated colleges and recognized institutions. Conducts orientation programmes  for new students. Provides guidance in respect of technical and organizational matters  to college libraries.' ,
       col2: <Link target='_blank' to='http://lib.unipune.ac.in:8002/'>http://lib.unipune.ac.in:8002/</Link>,
    },
    {
      img: delnet,
      col1:'DELNET (Developing Library Network) New Delhi. Login based: DELNET provides an array of facilities. DELNET`S relentless efforts in resource sharing have proved extremely effective. It has contributed a lot towards the modernisation of libraries in India.' ,
      col2:<Link target='_blank' to='http://delnet.in/index.html'>http://delnet.in/index.html</Link>,
    },
    {
      img: pathshala,
      col1: "e-pg pathshala Resources: - e-PG. Pathshala is an initiative of the MHRD under its National Mission on Education through ICT (NME-ICT) being executed by the UGC." ,
      col2: <Link target='_blank' to='https://epgp.inflibnet.ac.in/'>https://epgp.inflibnet.ac.in/</Link>,
    },
    {
      img: D_library,
      col1: 'NDLI (National Digital Library India)- is a vartual repository with search and brose facilities but provide a host of services for learner community.It is sponsored & mentored by Minisry of Education,& Govt if India.' ,
      col2:<Link target='_blank' to='https://ndl.iitkgp.ac.in/'>https://ndl.iitkgp.ac.in/</Link>,
    },
    {
      img: ebook,
      col1: 'e- adhyayan- e-Adhyayan is a repository of e.books for under graduate and post graduate courses. The e- books are being derived from e-books and e-PG Pathshala and Swayam.e.PG Pathashala is OER which is being developed and maintain by INFLIBNET Centre.' ,
      col2:<Link target='_blank' to='https://ebooks.inflibnet.ac.in/eadhyayan/'>https://ebooks.inflibnet.ac.in/eadhyayan/</Link>,
    },
    {
      img: swayam,
      col1: 'Swayam SWAYAM PRABHA  is an education learning platform initiated by the Ministry of Human Resource Development (MHRD) available through 34 (initially 32) DTH channels. ' ,
      col2: <Link target='_blank' to='https://www.swayamprabha.gov.in/'>https://www.swayamprabha.gov.in/</Link>,
    },
    {
      img: vidya,
      col1: 'Vidya Mitra- The INFLIBNET Centre has developed a web-based interface called "Vidya-mitra: Integrated e-Content Portal" for all e-content projects, developed / funded under the National Mission of Education through ICT. There are more than 50 projects on e-content under NME-ICT which are developed / being developed in various subject disciplines (science, arts, engineering, social science, etc) through various Indian institutes / universities / colleges' ,
      col2:<Link target='_blank' to='https://vidyamitra.inflibnet.ac.in/'>https://vidyamitra.inflibnet.ac.in/</Link>,
    },
    {
      img: doaj,
      col1: 'DOAJ( Directory of open access Journals) DOAJ is a unique and extensive index of diverse open access journals from around the world, driven by a growing community, committed to ensuring quality content is freely available online for everyone.' ,
      col2:<Link target='_blank' to='https://doaj.org/'>https://doaj.org/</Link>,
    },
    {
      img: mccia,
      col1: 'MCCIA (Maratha Chambers of Commerce Industries & Agriculture) Pune. The Chamber has always functioned under three main pillars - Advocacy, Skill Building and Networking.  MCCIA has been championing the needs of the industry, on behalf of the industry.  Owing to the intensified need for a skilled workforce, especially in the MSME segment, MCCIA has been conducting and promoting various capacity building initiatives to accelerate organisations towards a higher growth trajectory. Thirdly, MCCIA has always promoted an environment of collaboration by helping local MSMEs with key business networking, thereby enabling them to develop long-term mutually beneficial relationships.' ,
      col2:<Link target='_blank' to='https://www.mcciapune.com'>https://www.mcciapune.com</Link>
    },
    {
      img: kumbh,
      col1: 'The unique features of the AICTE books based upon model curriculum are: (1) Content of the book aligned with the mapping of Course Outcomes, Programs Outcomes and Unit Outcomes. (2)	In the beginning of each unit learning outcomes are listed to make the student understand what is expected of him/ her after completing that unit. (3)	Book provides lots of recent information, interesting facts, QR Code for E-resources. QR Code for use of ICT, projects, group discussion etc.  (4)	Student and teacher centric subject material included in book in balanced and chronological manner.  (5)	Figures, tables, and software screen shots are inserted to improve clarity of the topics. ' ,
      col2:<Link target='_blank' to='https://ekumbh.aicte-india.org/'>https://ekumbh.aicte-india.org/</Link>
    },
    
    ];
    const tableHeaders = {
      columns: ['Sr No.', '','Membership', 'WEB LINK'],
    };

    return (
      <div>
        <TableComponentImg data={userData} headers={tableHeaders} />
      </div>
    );
}