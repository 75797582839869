import React from "react";
import Slider from "react-slick";

export default function TestimonialSlick() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div>
        <Slider {...settings}>
            <div>
                <div className="w-100 p-5 mx-3 bg-light text-center">
                    <q>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                        voluptatem repellendus tempore tenetur excepturi, qui veniam
                        ratione sit vitae nulla veritatis sed quidem quas vero molestiae
                        ea saepe culpa obcaecati?
                    </q>
                    <h4 className="mt-5">Student Name-1</h4>
                    <h6>Department Batch Year</h6>
                </div>
            </div>
            
            <div>
                <div className="w-100 p-5 mx-3 bg-light text-center">
                    <q>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                        voluptatem repellendus tempore tenetur excepturi, qui veniam
                        ratione sit vitae nulla veritatis sed quidem quas vero molestiae
                        ea saepe culpa obcaecati?
                    </q>
                    <h4 className="mt-5">Student Name-2</h4>
                    <h6>Department Batch Year</h6>
                </div>
            </div>

            <div>
                <div className="w-100 p-5 mx-3 bg-light text-center">
                    <q>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut
                        voluptatem repellendus tempore tenetur excepturi, qui veniam
                        ratione sit vitae nulla veritatis sed quidem quas vero molestiae
                        ea saepe culpa obcaecati?
                    </q>
                    <h4 className="mt-5">Student Name-3</h4>
                    <h6>Department Batch Year</h6>
                </div>
            </div>
        </Slider>
    </div>
  );
}