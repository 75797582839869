import React from "react";
import Slider from "react-slick";

export default function CourseSlick() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div>
        <Slider {...settings}>
            <div>
                <div className="course-card" style={{ backgroundColor: "#C5EBFF" }}>
                    <span>#Business</span>
                    <h4 className="my-2">Business Analytics</h4>
                    <p>Duration: 2 year</p>
                    <p>Rating: </p>
                    <p className="text-end m-0">Read More</p>
                </div>
            </div>
            
            <div>
                <div className="course-card" style={{ backgroundColor: "#C9FFC5" }}>
                    <span>#Management</span>
                    <h4 className="my-2">Human Resources Management</h4>
                    <p>Duration: 2 year</p>
                    <p>Rating: </p>
                    <p className="text-end m-0">Read More</p>
                </div>
            </div>

            <div>
                <div className="course-card" style={{ backgroundColor: "#FFE6C5" }}>
                    <span>#Management</span>
                    <h4 className="my-2">Marketing Management</h4>
                    <p>Duration: 2 year</p>
                    <p>Rating: </p>
                    <p className="text-end m-0">Read More</p>
                </div>
            </div>

            <div>
                <div className="course-card" style={{ backgroundColor: "#FFC5C5" }}>
                    <span>#Management</span>
                    <h4 className="my-2">Operations &amp; Supply Chain Management</h4>
                    <p>Duration: 2 year</p>
                    <p>Rating: </p>
                    <p className="text-end m-0">Read More</p>
                </div>
            </div>
        
        </Slider>
    </div>
  );
}