import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import AQAR2021 from '../assets/PDF/AQAR/AQAR REPORT 20_21.pdf';
import AQAR2122 from '../assets/PDF/AQAR/AQAR REPORT 21-22.pdf';


const sidemenus = [
    {
        title: 'AQAR 2021-22',
        url:'/AQAR/AQAR2021-22'
    },
    {
        title: 'AQAR 2020-21',
        url:'/AQAR/AQAR2020-21'
    },
    // {
    //     title: 'AQAR 2019-20',
    //     url:'/AQAR/AQAR2019-20'
    // },
    // {
    //     title: 'AQAR 2018-19',
    //     url:'/AQAR/AQAR2018-19'
    // },
];

export default function AQAR(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export function AQAR202122(){
    return(
      <div>
        <h5 className="title-text my-2 mb-4">AQAR 2021-22</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={AQAR2122}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to={AQAR2122}> click here to download the PDF file.</Link></p>
        </object>
      </div>
      </div>
    );
}

export function AQAR202021(){
    return(
        <div>
        <h5 className="title-text my-2 mb-4">AQAR 2020-21</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={AQAR2021}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to={AQAR2021}> click here to download the PDF file.</Link></p>
        </object>
      </div>
      </div>
    );
}

// export function AQAR201920(){
//     return(
//       <div>
//       <UnderMaintanence />
//       </div>
//     );
// }

// export function AQAR201819(){
//     return(
//       <div>
//       <UnderMaintanence />
//       </div>
//     );
// }