import React from "react";
import OurRecruitersImage from "../../assets/trainingPlacement/MIBS- Our Recruters.jpg";

function OurRecruters() {
  return (
    <div>
      <img src={OurRecruitersImage} alt="MIBS-our Recruiters" />
    </div>
  );
}

export default OurRecruters;
