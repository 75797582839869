export const menuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "#",
    submenu: [
      {
        title: `About P.E.Society`,
        url: "/aboutUs/PESociety",
      },
      {
        title: "About Institute",
        url: "/aboutUs/Institute",
      },
      {
        title: "Vision Mission",
        url: "/aboutUs/VisionMission",
      },
      {
        title: `Chairman's Message`,
        url: "/aboutUs/ChairmansMessage",
      },
      {
        title: `Director's Message`,
        url: "/aboutUs/DirectorsMessage",
      },
      {
        title: `Visitor's Message`,
        url: "/aboutUs/VisitorMessage",
      },
      {
        title: `Coordinator's Message`,
        url: "/aboutUs/CoordinatorsMessage",
      },
      {
        title: "Governance",
        url: "#",
        submenu: [
          {
            title: "Organization Chart",
            url: "/governance/OrganizationChart",
          },
          {
            title: "College Development Committee",
            url: "/governance/CollegeDevelopmentCommittee",
          },
          {
            title: "Internal Quality Assurance Committee",
            url: "/governance/InternalQualityAssuranceCommittee",
          },
          {
            title: "Anti Ragging Committee",
            url: "/governance/AntiRaggingCommittee",
          },
          {
            title: "Internal Complaint Committee",
            url: "/governance/InternalComplaintCommittee",
          },
          {
            title: "Student Grievance Redressal",
            url: "/governance/StudentGrievanceRedressalCommittee",
          },
          {
            title: "SC/ST Committee",
            url: "/governance/SC-ST-Committee",
          },
        ],
      },

      {
        title: "Mandatory Disclosure",
        url: "/aboutUs/MandatoryDisclosure",
      },
      {
        title: "AICTE Approval",
        url: "/aboutUs/Approval",
      },
    ],
  },
  {
    title: "Programs",
    url: "#",
    submenu: [
      {
        title: "MBA",
        url: "#",
        submenu: [
          {
            title: "About MBA",
            url: "/courses/MBA",
          },
          // {
          //   title: 'Innovative Practices',
          //   url: '/courses/MBA/InnovativePractices',
          // },
        ],
      },
      {
        title: "MCA",
        url: "#",
        submenu: [
          {
            title: "About MCA",
            url: "/courses/MCA",
          },
          // {
          //   title: 'Innovative Practices',
          //   url: '/courses/MCA/InnovativePractices',
          // },
        ],
      },
      {
        title: "BCA",
        url: "#",
        submenu: [
          {
            title: "About BCA",
            url: "/courses/BCA",
          },
          // {
          //   title: 'Innovative Practices',
          //   url: '/courses/MBA/InnovativePractices',
          // },
        ],
      },
      {
        title: "Ph.D Management",
        url: "/courses/Phd",
      },
      // {
      //   title: 'mca',
      //   url: '/courses/mcas',
      // },
      // {
      //   title: 'mba',
      //   url: '/courses/mbas',
      // },
    ],
  },
  {
    title: "Admission",
    url: "#",
    submenu: [
      {
        title: "Admission Process",
        url: "#",
        submenu: [
          {
            title: "MBA",
            url: "/admission/MBA",
          },
          {
            title: "MCA",
            url: "/admission/MCA",
          },
          {
            title: "Ph.D",
            url: "/admission/PHD",
          },
        ],
      },
      {
        title: "Eligibility",
        url: "/admission/Eligibility",
      },
      {
        title: "Fees Structure and Documents",
        url: "/admission/FeesStructure",
      },
      {
        title: "MIBS Prospectus",
        url: "/admission/Prospectus",
      },
      {
        title: "Contact For Admissions",
        url: "/admission/Contact",
      },
    ],
  },
  {
    title: "Students",
    url: "#",
    submenu: [
      {
        title: "Academic Calender",
        url: "/students/AcademicCalender ",
      },
      {
        title: "MBA Curriculum",
        url: "/students/MBACurriculum",
      },
      {
        title: "MCA Curriculum",
        url: "/students/MCACurriculum",
      },
      {
        title: "Extension Activities",
        url: "/students/ExtensionActivities",
      },
      {
        title: "Circulars",
        url: "/students/Circulars",
      },
      // {
      //   title: 'Collaborations',
      //   url:'/students/Collaborations'
      // },
      {
        title: "Newsletter",
        url: "/students/Newsletter",
      },
      {
        title: "Student Achievement",
        url: "/students/StudentAchievement",
      },
      {
        title: "Life at MIBS",
        url: "/students/LifeAtMIBS",
      },
      {
        title: "Online Grievance Redressal",
        url: "/students/OnlineGrievance",
      },
    ],
  },
  {
    title: "Faculty & Research",
    url: "#",
    submenu: [
      {
        title: "Faculty",
        url: "#",
        submenu: [
          {
            title: "Teaching Staff MCA",
            url: "/faculty/TeachingMCA",
          },
          {
            title: "Teaching Staff MBA",
            url: "/faculty/TeachingMBA",
          },
          {
            title: "Non Teaching Staff",
            url: "/faculty/nonTeaching",
          },
        ],
      },
      {
        title: "Research Center",
        url: "#",
        submenu: [
          {
            title: "Code of Ethics",
            url: "/ResearchCenter/CodeEthics",
          },
          {
            title: "Research and Publications",
            url: "/ResearchCenter/Publications",
          },
        ],
      },
      {
        title: "Institution’s Innovation Council (IIC)",
        url: "#",
        submenu: [
          {
            title: "About IIC",
            url: "/IIC/AboutIIC",
          },
          {
            title: "Objectives of IIC",
            url: "/IIC/ObjectivesOfIIC",
          },
          {
            title: "Functions of IIC",
            url: "/IIC/FunctionsOfIIC",
          },
          {
            title: "Benefits of IIC ",
            url: "/IIC/BenefitsOfIIC",
          },
          {
            title: "IIC Activities",
            url: "/IIC/IICActivities",
          },
        ],
      },
    ],
  },
  {
    title: "NAAC",
    url: "#",
    submenu: [
      {
        title: "Accreditation Certificate and Status",
        url: "/naac/AccreditationStatus",
      },
      {
        title: "IQAC",
        url: "#",
        submenu: [
          {
            title: " IQAC Cell",
            url: "/IQAC/Cell",
          },
          {
            title: "IQAC Minutes",
            url: "#",
            submenu: [
              {
                title: "August 2022",
                url: "/IQAC/Minutes/Aug2022",
              },
              {
                title: "December 2022",
                url: "/IQAC/Minutes/Dec2022",
              },
            ],
          },
        ],
      },
      {
        title: "AQAR",
        url: "#",
        submenu: [
          {
            title: "AQAR 2021-22",
            url: "/AQAR/AQAR2021-22",
          },
          {
            title: "AQAR 2020-21",
            url: "/AQAR/AQAR2020-21",
          },
          // {
          //   title: 'AQAR 2019-20',
          //   url:'/AQAR/AQAR2019-20'
          // },
          // {
          //   title: 'AQAR 2018-19',
          //   url:'/AQAR/AQAR2018-19'
          // },
        ],
      },
      {
        title: "Self-Study Report (SSR)",
        url: "/naac/SSR",
      },
      {
        title: "Committee",
        url: "#",
        submenu: [
          {
            title: "Internal Complaint Committee",
            url: "/Committee/InternalCommittee",
          },
          {
            title: "Anti-Ragging Committee & Squad",
            url: "/Committee/AntiRaggingCommittee",
          },
          {
            title: "Student Grievence Cell",
            url: "/Committee/StudentGrievenceCell",
          },
          {
            title: "SC-ST Committee",
            url: "/Committee/SC-STCommittee",
          },
        ],
      },
      {
        title: "Feedback",
        url: "/naac/Feedback",
      },
      {
        title: "Best Practices",
        url: "#",
        submenu: [
          {
            title: "2021-22",
            url: "#",
            submenu: [
              {
                title: "HR Meet",
                url: "/BestPractices/HRMeet",
              },
              {
                title: "Alumni Meet",
                url: "/BestPractices/AlumniMeet",
              },
            ],
          },
          {
            title: "2022-23",
            url: "/BestPractices/2022-23",
          },
          {
            title: "Institute Distinctiveness",
            url: "#",
          },
        ],
      },
    ],
  },
  {
    title: "Library",
    url: "#",
    submenu: [
      {
        title: "About Library",
        url: "/library/AboutLibrary",
      },
      {
        title: "Library Resources",
        url: "/library/Resources",
      },
      {
        title: "Library Rules",
        url: "/library/Rules",
      },
      {
        title: "Associate Memberships",
        url: "/library/AssociateMemberships",
      },
      {
        title: "Library Committee",
        url: "/library/LibraryCommittee",
      },
      // {
      //   title: 'Web OPAC',
      //   url: '/library/WebOPAC'
      // },
      // {
      //   title: 'DELNET',
      //   url: '/library/DELNET'
      // },
      // {
      //   title: 'National Digital Library',
      //   url: '/library/NationalDigitalLibrary'
      // },
      // {
      //   title: 'SPPU Jaykar Library',
      //   url: '/library/SPPUJaykarLibrary'
      // },
      {
        title: "Previous Question Papers",
        url: "/library/PreviousQuestionPapers",
      },
    ],
  },
  {
    title: "Training and Placements",
    url: "#",
    submenu: [
      {
        title: "About Placement Cell",
        url: "/placement/PlacementCell",
      },
      {
        title: "Contact T&P Officer",
        url: "#",
        submenu: [
          {
            title: "Message From Training Officer",
            url: "/contactTPofficer/trainingOfficer",
          },
          {
            title: "Message From Placement Officer",
            url: "/contactTPofficer/PlacementOfficer",
          },
        ],
      },
      // {
      //   title: "Summer Intership Program / IT Project",
      //   url: "/placement/SummerIntershipProgram",
      // },
      {
        title: "Our Recruiters",
        url: "/placement/OurRecruiters",
      },
      // {
      //   title: "Placement Brouchere",
      //   url: "/placement/PlacementBrouchere",
      // },
      {
        title: "Collaborations",
        url: "/placement/Collaborations",
      },
    ],
  },

  // {
  //   title:'Gallery',
  //   url:'#',
  //   submenu:[
  //     {
  //       title: 'Industrial Visit',
  //       url:'/gallery/IndustrialVisit'
  //     },
  //     {
  //       title: 'Cultural Program',
  //       url:'/gallery/CulturalProgram'
  //     },
  //     {
  //       title: 'Sports',
  //       url:'/gallery/Sports'
  //     },
  //     {
  //       title: 'Extension Activities',
  //       url:'/gallery/ExtensionActivities'
  //     },
  //     {
  //       title: 'Training and Placement',
  //       url:'/gallery/TrainingPlacement'
  //     },
  //     {
  //       title: 'Infrastructure',
  //       url:'/gallery/Infrastructure'
  //     },
  //     {
  //       title: 'Alumni Meet',
  //       url:'/gallery/AlumniMeet'
  //     },
  //     {
  //       title: 'HR Meet',
  //       url:'/gallery/HRMeet'
  //     },
  //   ]
  // },

  {
    title: "Alumni",
    url: "#",
    submenu: [
      {
        title: "About Alumni Cell",
        url: "/alumni/AlumniCell",
      },
      {
        title: "Alumni Achievers",
        url: "/alumni/AlumniAchievers",
      },
      {
        title: "Alumni Registration",
        url: "/alumni/AlumniRegistration",
      },
    ],
  },
  {
    title: "Contact Us", //.navbar-nav .nav-item .nav-link { margin left 3px
    url: "/contact",
  },
];
