import * as React from 'react';
import FlipCard from '../Components/FlipCard';
import Breadcrumb from "../Components/Breadcrumb";
import { Grid, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Contact() {
  window.scrollTo(0, 420);
  const pageTitle = 'Contact Us'
  return (
    <>
        <Breadcrumb pageTitle={pageTitle} />   
        <div className='backgroundImg'>
              <Paper elevation={3} className="p-0 text-justify mx-3 my-5 py-4 px-3">
                <Grid container>
                     <Grid  xs={12} md={6}>
                            <FlipCard />
                     </Grid>
                     <Grid xs={12} md={6} className='my-3' spacing={5}>
                       <Item>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3780.0130337887326!2d73.7797322!3d18.6634111!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b762beaaaaab%3A0xd3fe3d74997743ce!2sInstitute%20of%20Management%20and%20Career%20Development!5e0!3m2!1sen!2sin!4v1707369394961!5m2!1sen!2sin" height={390} style={{ border: 0 }} allowfullscreen="" loading="lazy" className="w-100"></iframe>
                       </Item>
                     </Grid>
                </Grid> 
              </Paper>
        </div>
    </>
  )
}
