import MenuItems from "./MenuItems";
import { menuList } from "../menuList";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div
        //style={{backgroundColor:'#DAFFFB',F5D0D0}}
        style={{ backgroundColor: "#ffecc6" }}
        className="marquee-container"
      >
        <Marquee
          width="100%"
          loop="infinite"
          speed="60"
          behavior="scroll"
          direction="left"
          pauseOnHover
          style={{ padding: "2px", color: "#232D3F" }}
        >
          {/* <span style={{fontSize:'16px'}}> <b> MAH- MBA/MCA-CET2024 online registration and confirmation date <a href='https://cetcell.mahacet.org/' target='_blank' style={{color:'steelblue'}}> ( https://cetcell.mahacet.org/ ) </a> extended from 06-02-2024 to 12/02/2024 </b></span> */}
          <span style={{ fontSize: "16px" }}>
            <b>
              {" "}
              Establishment of 24x7 Woman Helpline Contact Numbers :{" "}
              <a style={{ color: "steelblue" }}> 9011070916 & 122 </a>
            </b>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ fontSize: "16px" }}>
            <b>
              <a sx={{ color: "steelblue" }} href="/admissionEnquiry">
                {" "}
                Admissions open for BCA/MBA/MCA for Academic Year 2024-25.
              </a>
            </b>
          </span>
        </Marquee>
      </div>
      <nav
        id="navbar_top"
        className="navbar navbar-expand-lg navbar-light-R"
        // style={{ backgroundColor: "#494A4B" }}
        style={{ backgroundColor: "#A70606" }}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            id="navActive"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {menuList.map((item, index) => {
                const depthLevel = 0;
                return (
                  <MenuItems items={item} key={index} depthLevel={depthLevel} />
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
