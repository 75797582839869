import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
// import InsideLayout from './InsideLayout';

const Layout = () =>{
    return(
        <>
            <Header />
                {/* <InsideLayout/> */}
                <div>
                    <Outlet />
                </div>
            <Footer/>
        </>
    )
}

export default Layout;

