import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import placementOfficer from "../assets/AboutUs/PlacementOfficer.png";
import placementCoordinator from "../assets/AboutUs/PlacementCoordinator.png";
import trianingOfficer from "../assets/AboutUs/MIBS- Training Officer.jpg";
import { FbIcon, InstaIcon, TwitIcon, LnIcon } from "../Components/SvgIcons";
import { Divider } from "@mui/material";

const sidemenus = [
  {
    title: "Message from Training Officer",
    url: "/contactTPofficer/trainingOfficer",
  },
  {
    title: "Message From Placement Officer",
    url: "/contactTPofficer/PlacementOfficer",
  },
];

export default function ContactTPofficer(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  const handleClick = (event) => {
    const id = event.currentTarget.id;
    setName(sidemenus[id].title);
    setId(id);
  };

  // Set active menu item
  const setActiveMenuItem = () => {
    const activeMenu = document.querySelector(".sideMenu a.active-menu");
    if (activeMenu) {
      activeMenu.classList.remove("active-menu");
    }
    const newActiveMenu = document.getElementById(menuId.toString());
    if (newActiveMenu) {
      newActiveMenu.classList.add("active-menu");
    }
  };

  return (
    <>
      <Breadcrumb pageTitle={menuName} />
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => (
                <Link
                  key={index}
                  id={index}
                  onClick={handleClick}
                  to={menu.url}
                  className={menu.title === menuName ? "active-menu" : ""}
                >
                  {menu.title}
                </Link>
              ))}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => (
                <div
                  id={menu.title.split(" ").join("").toString()}
                  key={index}
                  className={menu.title === menuName ? "" : "d-none"}
                >
                  {props.pageContent}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function TrainingOfficer() {
  return (
    <div>
      <div>
        <div className="row mt-4">
          {/* Message  */}
          <div className="col-lg-8 text-justify">
            <h5 className="title-text mb-3">Welcome to Training Corner</h5>
            <p>
              <b> Dear Students,</b>
            </p>
            <p>
              The Training Cell at MIBS supplements the regular academic
              curriculum with various Training programs providing a unique
              opportunity for the students to develop their personality while
              upgrading their communication and presentation skills. These
              training programs imparts to the young upcoming professionals to
              have a match with the demand and skills required by the Corporates
              to make them to be able to qualify stringent Selection. The
              training programs is designed in the manner to well equip the
              students with necessary knowledge skills like -
            </p>
            <ul>
              <li>Soft Skills</li>
              <li>Industrial Aptitude</li>
              <li>Seminar, Group Discussions</li>
              <li>Industry Visits</li>
              <li>Personality Developments programmes</li>
              <li>English communication skills </li>
              <li>Mock Interviews </li>
            </ul>
            <p>
              The contents of the training programs are designed in such a way
              that these activities and exercises will help in overall
              personality growth of the students.
            </p>
            <p>
              The students are encouraged to attend these courses and derive
              maximum benefit from them.
            </p>
            <p>
              The students should understand that Employability Skills are a way
              of life and a discipline, and not merely a course. In order to
              derive maximum benefit from the Employability Skills Programs, one
              has to practice what one learns during the Programs.
            </p>
          </div>
          {/* Profile  */}
          <div className="col-lg-4 text-center profile-info">
            <img
              src={trianingOfficer}
              alt="userImage"
              className="opacity-100 img-fluid"
            />
            <h5 className="my-3 subtitle-text">Prof. Dr. Prasad Ghodke</h5>
            <p className="m-1">Training Officer</p>
            <p className="m-1">PES, MIBS, Nigdi Pune</p>
            {/* Social icons */}
            <div className="d-flex gap-3 justify-content-center social-icons">
              <Link className="facebook">
                <FbIcon />
              </Link>
              <Link className="twitter">
                <TwitIcon />
              </Link>
              <Link className="linkedin">
                <LnIcon />
              </Link>
              <Link className="instagram">
                <InstaIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PlacementOfficer() {
  return (
    <div>
      <div>
        <div className="row mt-4">
          {/* Message  */}
          <div className="col-lg-8 text-justify">
            <h5 className="title-text mb-3">Welcome to Placement Corner</h5>
            <p>
              <b>Dear Students,</b>
            </p>
            <p>
              We are delighted to welcome you cheerfully as you explore the
              Placement Corner, which serves as a gateway to fascinating career
              opportunities. Our devoted staff is dedicated to assisting you at
              every stage of your career development.
            </p>
            <p>
              We will be here to provide advice and guidance to help you
              confidently navigate the job market. We provide every detail from
              workshops on resume building to mock interviews and networking
              opportunities, all aimed at giving you the tools and resources you
              need to be successful.
            </p>
            <p>
              You will get assistance to a wide variety of internship and career
              opportunities across multiple sectors, courtesy of our extensive
              network of industry partners and recruiters.
            </p>
            <p>
              Our primary objective is your success, and we're here to assist
              you throughout each phase of your endeavor.
            </p>
            <p>Get started on your journey to success today!</p>
            <p>
              Best Regards,
              <br />
              <b>Mrs. Neha Gupte</b>
              <br />
              Placement Coordinator
              <br />
              Email ID: nh.bendre@gmail.com
            </p>
            <div className="blockquote d-none">
              <blockquote className="p-3 mt-4 ps-5 fs-6">
                "I have always believed that the answer to my destiny lies in
                creating the results, I desire!"
              </blockquote>
            </div>
          </div>
          {/* Profile  */}
          <div className="col-lg-4 text-center profile-info">
            <img
              src={placementCoordinator}
              alt="userImage"
              className="opacity-100 img-fluid"
            />
            <h5 className="my-3 subtitle-text">Mrs. Neha Gupte</h5>
            <p className="m-1">Placement Coordinator</p>
            <p className="m-1">Email ID: nh.bendre@gmail.com</p>
            {/* Social icons */}
            <div className="d-flex gap-3 justify-content-center social-icons">
              <Link className="facebook">
                <FbIcon />
              </Link>
              <Link className="twitter">
                <TwitIcon />
              </Link>
              <Link className="linkedin">
                <LnIcon />
              </Link>
              <Link className="instagram">
                <InstaIcon />
              </Link>
            </div>
          </div>
        </div>

        {/* About & Career Path (if applicable) */}
        <div className="my-5 my-lg-0 d-none" style={{ textAlign: "justify" }}>
          {/* If there is any additional content about Mrs. Neha Gupte, you can add it here */}
        </div>
      </div>
      <Divider
        sx={{
          my: 2,
          borderTop: "5px",
          borderStyle: "dotted", // Applies the dotted border style
          borderColor: "primary.main", // Applies color from the theme
        }}
      />
      <div className="row my-5">
        {/* Message  */}
        <div className="col-lg-8 text-justify">
          {/* <h5 className="title-text mb-3">Welcome to Placement Cell</h5> */}
          <p>
            <b>Dear Students,</b>
          </p>
          <p>
            Welcome to the Placement Cell of Modern Institute of Business
            Studies, where your career aspirations are our top priority. As your
            Placement Coordinator, I am excited to support you in navigating the
            path to professional success.
          </p>
          <p>
            At MIBS, we believe in holistic development. Our Placement Cell is
            dedicated to bridging the gap between academic learning and industry
            expectations. We provide personalized career counseling, skill
            enhancement workshops, and ample opportunities to interact with
            industry leaders.
          </p>
          <p>
            We collaborate with top organizations to offer internships and
            placements that align with your career goals. Our goal is to ensure
            you are not just job-ready but future-ready, equipped with the
            skills and confidence to excel in the competitive business world.
          </p>
          <p>
            I encourage you to actively participate in our events, workshops,
            and networking sessions. Utilize the resources available and connect
            with us for any career-related guidance or assistance.
          </p>
          <p>
            Your journey towards a successful career starts here, and we are
            here to support you every step of the way. Let’s work together to
            turn your aspirations into achievements.
          </p>
          <p>
            Best Regards,
            <br />
            <b>Komal Apte</b>
            <br />
            Placement Officer
            <br />
            Email ID: komal.aptemibs@gmail.com
          </p>
          <div className="blockquote d-none">
            <blockquote className="p-3 mt-4 ps-5 fs-6">
              "I have always believed that the answer to my destiny lies in
              creating the results, I desire!"
            </blockquote>
          </div>
        </div>
        {/* Profile  */}
        <div className="col-lg-4 text-center profile-info">
          <img
            src={placementOfficer}
            alt="userImage"
            className="opacity-100 img-fluid"
          />
          <h5 className="my-3 subtitle-text">Komal Apte</h5>
          <p className="m-1">Placement Officer</p>
          <p className="m-1">Email ID: komal.aptemibs@gmail.com</p>
          {/* social icons */}
          <div className="d-flex gap-3 justify-content-center social-icons">
            <Link className="facebook">
              <FbIcon />
            </Link>

            <Link className="twitter">
              <TwitIcon />
            </Link>

            <Link className="linkedin">
              <LnIcon />
            </Link>

            <Link className="instagram">
              <InstaIcon />
            </Link>
          </div>
        </div>
      </div>

      {/* About & Career Path  */}
      <div className="my-5 my-lg-0 d-none" style={{ textAlign: "justify" }}>
        {/* If there is any additional content about Komal Apte, you can add it here */}
      </div>
    </div>
  );
}
