import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import CollaborationData from "../Data/Training&placement/CollaborationData";
import OurRecruters from "../Components/trainingPlacemant/OurRecruters";

const sidemenus = [
  {
    title: "About Placement Cell",
    url: "/placement/PlacementCell",
  },
  {
    title: "Contact T&P Officer",
    url: "/contactTPofficer/PlacementOfficer",
  },
  // {
  //   title: "Summer Intership Program / IT Project",
  //   url: "/placement/SummerIntershipProgram",
  // },
  {
    title: "Our Recruiters",
    url: "/placement/OurRecruiters",
  },
  // {
  //   title: "Placement Brouchere",
  //   url: "/placement/PlacementBrouchere",
  // },
  {
    title: "Collaborations",
    url: "/placement/Collaborations",
  },
];

export default function TrainingPlacement(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    window.scrollTo(0, 0);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <Breadcrumb pageTitle={menuName} />
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function PlacementCell() {
  return (
    <div className="m-auto w-90 text-left mt-3">
      <h5 className="text-center">The Placement Cell</h5>
      <div className="text-left my-4">
        <p>
          The Placement Cell at PES Modern Institute of Business Studies is
          dedicated to guiding and facilitating the career aspirations of our
          students. With a proactive approach, we bridge the gap between
          academia and industry by fostering valuable connections and
          opportunities. Our primary objective is to ensure that students are
          well-prepared for the professional world through comprehensive career
          counseling, skill development workshops, and industry interactions.
          Through internship placements, campus recruitment drives, and alumni
          networking, we cultivate a conducive environment for students to
          explore diverse career paths and gain hands-on experience.
        </p>
        <p>
          The Placement Cell also focuses on enhancing employability skills,
          providing personalized guidance on resume building, interview
          techniques, and industry-specific trends. Our efforts are bolstered by
          a robust feedback mechanism from recruiters and students, allowing us
          to continually refine and improve our offerings. Our track record of
          successful placements reflects our dedication to nurturing talent and
          fostering lifelong career success.
        </p>
      </div>
    </div>
  );
}

export function ContactTPOfficer() {
  return (
    <div>
      <UnderMaintanence />
    </div>
  );
}

export function SummerIntershipProgram() {
  return (
    <div>
      <UnderMaintanence />
    </div>
  );
}

export function OurRecruiters() {
  return (
    <div>
      <OurRecruters />
    </div>
  );
}

export function PlacementBrouchere() {
  return (
    <div>
      <UnderMaintanence />
    </div>
  );
}

export function Collaborations() {
  return (
    <div>
      <h5 className="title-text my-2 mb-4 decorated">Collaborations</h5>
      <CollaborationData />
    </div>
  );
}
