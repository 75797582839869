import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";

const sidemenus = [
    {
        title:'Master in Business Administration',
        url: "/courses/MBA"
    },
    // {
    //     title:'Innovative Practices',
    //     url: "/courses/MBA/InnovativePractices"
    // },
];

export default function MBA(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        // console.log('Menu :',menuName);
        // console.log(menuName.split(' ').join('').toString());
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );
        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {/* <h5 className="title-text my-4 mt-2">{menu.title}</h5>
                                        <div>
                                            {menu.content}
                                        </div> */}
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}


export function MBASpecialization(){
   
    return(
        <>
            <h5 className="title-text my-4 mt-2">
            Masters in Business Administration (MBA)
            </h5>
            <div className="mb-5 text-justify">
                <p>Progressive Education Society’s Modern Institute of Business Studies offers <b>Masters 
                    of Business Administration (MBA) </b> which is 2 years (4 semesters) Full Time Post Graduate Program.  
                    MBA is approved by AICTE, New Dehli, recognized by Directorate of Technical Education Government 
                    of Maharashtra State and Affiliated by Savitribai Phule Pune University.
                </p>
                <p>The curriculum takes the MBA programme to the next level in terms of implementing Outcome Based
                    Education along with the Choice Based Credit System (CBCS) and Grading System.
                </p>
                <p>MBA program offers Major/Minor Specialization, various Generic Electives, Specialization Electives.</p>
                <p>In MBA program, each student shall undertake a Summer Internship Project (SIP) at the end of Second 
                    Semester for a minimum of 8 weeks. SIP can be carried out in a Corporate Entity / NGO / SME / 
                    Government Undertaking / Cooperative Sector. SIP may be a research project – based on primary / 
                    secondary data or may be an operational assignment involving working by the student on a given 
                    task/assignment/project/ etc. in an organization / industry. It is expected that the SIP shall 
                    sensitize the students to the demands of the workplace.
                </p>
                <p>The Institutes supports placement opportunity to get jobs in good MNC’s. This program will build 
                    right platform for students to become effective and ethical leaders, managers, and entrepreneurs.
                </p>
            </div>

            <Tree
                lineWidth={'3px'}
                lineColor={'var(--main-color)'}
                lineBorderRadius={'8px'}
                lineHeight={'30px'}
                lineStyle={'solid'}
                nodePadding={'1px'}

                label={<h5>Specialization Offered</h5>}
            >
                <TreeNode label={<div><h5 className="p-2">MBA</h5></div>}>
                    <TreeNode label={<div><h5 className="p-2">Major or Minor</h5></div>}>
                        <TreeNode label={<>
                            <div className="text-start">
                                <ul style={{marginLeft:'1'}}>
                                    <li>Business Analytics</li>
                                    <li>Operations Supply Chain Management (OSCM)</li>
                                    <li>Human Resources Management</li>
                                    <li>Marketing Management</li>
                                    <li>Financial Management</li>
                                </ul>
                            </div>
                        </>} />
                    </TreeNode>
                    <TreeNode label={<div><h5 className="p-2">Only as Minor Specializations</h5></div>}>
                        <TreeNode label={<>
                            <div className="text-start">
                                <ul>
                                    <li>Tourism & Hospitality Management (THM)</li>
                                    <li>Rural & Agribusiness Management (RABM)</li>
                                    <li>International Business Management (IB)</li>
                                    <li>Pharma & Healthcare Management (PHM)</li>
                                </ul>
                            </div>
                        </>}/>
                    </TreeNode>
                </TreeNode>

                {/* <TreeNode label={<div><h6 className="p-2">PHD</h6></div>}>
                    <TreeNode label={<>
                        <div className="text-start">
                                <ul>
                                    <li>Financial Phd</li>
                                    <li>Organizational Phd</li>
                                </ul>
                        </div>
                    </>} />
                </TreeNode> */}
            </Tree>
        </>
    )
}

// export function MBAInnovativePractices(){
//     return(
//         <>
//         <div className="text-jutify">

//             <h5 className="title-text my-4 mt-2">Innovative Practices MBA</h5>
//             <UnderMaintanence />
//         </div>

//         </>
//     )
// }