import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import FacultyCard, { NonTeachingFacultyCard } from "../Components/FacultyCard";
import manoj from "../assets/Faculty/TeachingMCA/Prof.Dr.ManojSathe.png";
import bharati from "../assets/Faculty/TeachingMCA/Mrs.BharatiM.Ramageri.png";
import maithili from "../assets/Faculty/TeachingMCA/Prof.DrMs.MaithiliArjunwadkar.png";
import gajanan from "../assets/Faculty/TeachingMCA/Prof.GajananMadhaoBadhe.png";
import leena from "../assets/Faculty/TeachingMCA/Prof.LeenaAmolDeshmukh.png";
import uma from "../assets/Faculty/TeachingMCA/Prof.UmaR.Patil.png";
import yogeshwari from "../assets/Faculty/TeachingMCA/Prof.YogeshwariAnandMahajan.png";

import prashant from "../assets/Faculty/NonTeaching/Mr.PrashantMarkale.png";
import santosh from "../assets/Faculty/NonTeaching/Mr.SantoshBhanudasBagav.png";
import aruna from "../assets/Faculty/NonTeaching/Mrs.ArunaUmeshKawathekar.png";
import megha from "../assets/Faculty/NonTeaching/Mrs.MeghaS.Satav.png";
import swati from "../assets/Faculty/NonTeaching/Mrs.SwatiKantilalGadhave.png";
import akanksha from "../assets/Faculty/NonTeaching/Ms.AkankshaTodarmal.png";
import mrudula from "../assets/Faculty/NonTeaching/Ms.MrudulaJMadiwale.png";
import sneha from "../assets/Faculty/NonTeaching/Ms.SnehalRahulPadhye.png";

import darshan from "../assets/Faculty/TeachingMBA/Prof.DarshanSMejari.png";
import amruta from "../assets/Faculty/TeachingMBA/Prof.Dr.AmrutaMandarKhedkar.png";
import sudarshan from "../assets/Faculty/TeachingMBA/Prof.Sudarshan.A.Pawar.png";
import mandakini from "../assets/Faculty/TeachingMBA/Prof.Dr.Ms.MandakiniR.Bhosale.png";
import prasad from "../assets/Faculty/TeachingMBA/Prof.Dr.PrasadGhodke.png";
import madhuri from "../assets/Faculty/TeachingMBA/Prof.MadhuriDambal.png";
import mahesh from "../assets/Faculty/TeachingMBA/Prof.MaheshSalvi.png";
import manojYawalkar from "../assets/Faculty/TeachingMBA/Prof.ManojShriramYawalkar.png";
import komal from "../assets/Faculty/TeachingMBA/Prof.Mrs.KomalH.Apte.png";
import sonal from "../assets/Faculty/TeachingMBA/Prof.Ms.SonalS.Tejwani.png";
import neha from "../assets/Faculty/TeachingMBA/Prof.NehaR.Gupte.png";
import rupali from "../assets/Faculty/TeachingMBA/Prof.RupaliJadhav.png";
import smita from "../assets/Faculty/TeachingMBA/Prof_Smita.png";

const sidemenus = [
  {
    title: "Teaching Staff MCA",
    url: "/faculty/TeachingMCA",
  },
  {
    title: "Teaching Staff MBA",
    url: "/faculty/TeachingMBA",
  },
  {
    title: "Non Teaching Staff",
    url: "/faculty/nonTeaching",
  },
];

export default function Faculty(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    window.scrollTo(0, 0);
  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <Breadcrumb pageTitle={menuName} />
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {" "}
                    {menu.title}{" "}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function TeachingMCA() {
  return (
    <div className="bg-light m-0 px-3 pb-3">
      <h5 className="title-text py-4">Teaching Faculty MCA</h5>
      <div>
        <div>
          <FacultyCard
            img={maithili}
            name="Prof. Dr. Ms. Maithili Arjunwadkar"
            designation="Director"
            qualification="Ph.D, MCA, B.Sc.(Electronics)"
            specialization="Data Security"
            mailId="drmaithili.mibs@gmail.com"
            researchArea="Cryptography, Steganography, Watermarking, Machine Learning, Block Chain, Internet of Behaviour (IoB)"
            totalExperience="24.10 Years"
            industryExperience="3.3 Years"
            teachingExperience="22.7 Years"
            papersPublished={{ total: 22, national: 2, international: 20 }}
            booksPublished={{ total: 1, national: 1, international: 1 }}
            researchGuidance={{
              total: 6,
              completed: 0,
              pursuing: 6,
              patents: 2,
              filed: 1,
            }}
            googleScholarId="https://scholar.google.com/citations?authuser=4&user=KCvacI4AAAAJ"
          />
        </div>
        <div>
          <FacultyCard
            img={manoj}
            name="Prof. Dr. Manoj Sathe"
            designation="Associate Professor"
            qualification="Ph.D (Computer Science), M.C.A, M.B.S, P.G.D.B.M."
            specialization="IT"
            mailId="sathe.manoj@gmail.com"
            researchArea="Data Science and Machine Learning"
            totalExperience="19 Years"
            industryExperience="1.5 Years"
            teachingExperience="18 Years"
            papersPublished={{ total: 10, national: 6, international: 4 }}
            booksPublished={{ total: 7, national: 3, international: 4 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: 2,
            }}
            googleScholarId="https://scholar.google.com/citations?user=ujcBVt4AAAAJ&hl=en"
          />
        </div>
        <div>
          <FacultyCard
            img={gajanan}
            name="Prof. Gajanan Madhao Badhe"
            designation="Assistant Professor"
            qualification="M.C.A., M.M.S., PGDBM, M.Sc., Ph. D.Pursuing"
            specialization="Computer Applications"
            mailId="gmbadhe@hotmail.com"
            researchArea="Data Security, Network Technology, Blockchain Technology Applications"
            totalExperience="23 Years"
            industryExperience="01 Years"
            teachingExperience="22 Years"
            papersPublished={{
              total: "07/03",
              national: "01/01",
              international: "06/02",
            }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: "",
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId=""
          />
        </div>
        <div>
          <FacultyCard
            img={bharati}
            name="Mrs.Bharati M.Ramageri"
            designation="Assistant Professor"
            qualification="BE(CSE), MTech(IT), Ph.D pursuing"
            specialization="Computer Science and  Engineering"
            mailId="bharatiramageri@gmail.com"
            researchArea="Blockchain Technology, Data Mining, Big Data Analytics"
            totalExperience="22 Years"
            industryExperience="02 Years"
            teachingExperience="20 Years"
            papersPublished={{
              total: "16/9",
              national: "09",
              international: "07",
            }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.co.in/citations?user=0k1YlqMAAAAJ&hl=en"
          />
        </div>
        <div>
          <FacultyCard
            img={leena}
            name="Prof. Leena Amol Deshmukh"
            designation="Assistant Professor"
            qualification="MCA, B.Sc & Ph.D (Pursuing)"
            specialization="Data security"
            mailId="leenavaidya2212@gmail.com"
            researchArea="Digital watermarking and stegnography"
            totalExperience="13 Years"
            industryExperience="0 Years"
            teachingExperience="13 Years"
            papersPublished={{ total: 0, national: 0, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId=""
          />
        </div>
        <div>
          <FacultyCard
            img={uma}
            name="Prof. Uma R. Patil"
            designation="Assistant Professor"
            qualification="MCA, M.Sc. (CS) & pursuing Ph.D(CS)"
            specialization="Computer Application"
            mailId="ujupatil@gmail.com"
            researchArea="Internet of Things, Machine learning"
            totalExperience="11 Years"
            industryExperience="01 Years"
            teachingExperience="10 Years"
            papersPublished={{
              total: "9/7",
              national: "2/2",
              international: "7/5",
            }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Appeared",
            }}
            googleScholarId="https://scholar.google.com/citations?hl=en&user=NUW_ykUAAAAJ"
          />
        </div>
        <div>
          <FacultyCard
            img={yogeshwari}
            name="Prof.Yogeshwari Anand Mahajan"
            designation="Assistant Professor"
            qualification="MCA"
            specialization=""
            mailId="yogeshwariamahajan@gmail.com"
            researchArea=""
            totalExperience="2+"
            industryExperience=""
            teachingExperience=""
            papersPublished={{ total: 0, national: 0, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId=""
          />
        </div>
      </div>
    </div>
  );
}

export function TeachingMBA() {
  return (
    <div className="bg-light m-0 px-3 pb-3">
      <h5 className="title-text py-4">Teaching Faculty MBA</h5>
      <div>
        <div>
          <FacultyCard
            img={mandakini}
            name="Prof. Dr. Ms. Mandakini R. Bhosale"
            designation="Associate Professor"
            qualification="Ph.D(HR), MMS, M.COM, PGDHM, B.Ed.(commerce)"
            specialization="Human Resource Management"
            mailId="mandakinibhosale@gmail.com"
            researchArea="HR"
            totalExperience="28 Years"
            industryExperience="0 Years"
            teachingExperience="28 Years"
            papersPublished={{
              total: "13/04",
              national: "8/4",
              international: "5/0",
            }}
            booksPublished={{ total: 1, national: 1, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.co.in/citations?user=4s33oPsAAAAJ&hl=en"
          />
        </div>
        <div>
          <FacultyCard
            img={sudarshan}
            name="Prof. Dr. Sudarshan A. Pawar"
            designation=" Associate Professor and Research Co-ordinator"
            qualification="NET (Mgmt), Ph.D , MBA (Mkt.)"
            specialization="Marketing Specialization"
            mailId="sapawarmibs@gmail.com"
            researchArea="Consumer Buying Behaviour, Branding, Promotion"
            totalExperience="14.5 Years"
            industryExperience="1 Years"
            teachingExperience="13.5 Years"
            papersPublished={{
              total: "14",
              national: "5",
              international: "9",
            }}
            booksPublished={{ total: 2, national: 2, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.com/citations?hl=en&user=4ezgFlQAAAAJ"
          />
        </div>
        <div>
          <FacultyCard
            img={mahesh}
            name="Prof. Mahesh Salvi"
            designation="Assistant Professor"
            qualification="MCA, MCM, Ph.D (pursuing)"
            specialization="Computer Management"
            mailId="salvimk@gmail.com"
            researchArea="New Education Policy"
            totalExperience="23 Years"
            industryExperience="4 Years"
            teachingExperience="19 Years"
            papersPublished={{
              total: "03/02",
              national: "03/02",
              international: 0,
            }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.com/citations?user=ujcBVt4AAAAJ&hl=en"
          />
        </div>

        <div>
          <FacultyCard
            img={prasad}
            name="Prof. Dr. Prasad Ghodke"
            designation="Assistant Professor"
            qualification="MBA (Financial Management) Ph.D (Financial Management)"
            specialization="Financial Management"
            mailId="prasadsppu2@gmail.com"
            researchArea="Financial management of micro & small scale industries etc"
            totalExperience="8 Years"
            industryExperience="1 Year"
            teachingExperience="7 Years"
            papersPublished={{
              total: "09/05",
              national: "05",
              international: "04",
            }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.com/citations?user=tMEMkFwAAAAJ&hl=en"
          />
        </div>
        <div>
          <FacultyCard
            img={darshan}
            name="Prof. Darshan S Mejari"
            designation="Assistant Professor"
            qualification="B.com & M.B.A. (Marketing & HRM)"
            specialization="Marketing & Human Resource Management"
            mailId="mejari.darshan@gmail.com"
            researchArea="Marketing"
            totalExperience="22 Years"
            industryExperience="06 Years"
            teachingExperience="16 Years"
            papersPublished={{ total: 2, national: 2, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={amruta}
            name="Prof. Dr. Amruta Mandar Khedkar"
            designation="Assistant Professor"
            qualification="PhD, MCM, M.com, B.com(CA)"
            specialization="General Management, Business Analytics"
            mailId="khedkaramruta02@gmail.com"
            researchArea="Business Management"
            totalExperience="8 Years"
            industryExperience="0 Years"
            teachingExperience="8 Years"
            papersPublished={{ total: "2/5", national: 2, international: 5 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 6,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="https://scholar.google.com/citations?user=HezK_Q4AAAAJ&hl=en"
          />
        </div>
        <div>
          <FacultyCard
            img={neha}
            name="Prof. Neha R. Gupte"
            designation="Assistant Professor"
            qualification="Ph.D (Pursuing), MMS (Human Resource), BMS (Marketing), JLPT N3, Diploma In Japanese Language"
            specialization="Human Resource, Foreign Language (Japanese Language)"
            mailId="nh.bendre@gmail.com"
            researchArea="Management"
            totalExperience="08 Years"
            industryExperience="02 Years"
            teachingExperience="1.5 Years(Academics),4.5 Years(Freelancing)"
            papersPublished={{ total: 1, national: 0, international: 1 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={rupali}
            name="Prof. Rupali Jadhav"
            designation="Assistant Professor"
            qualification="B.Sc, MBA, MCM, MCA"
            specialization="Marketing, HR, IT"
            mailId="rupali.jadhav2385@gmail.com"
            researchArea="Data Mining,AI in Marketing"
            totalExperience="07 Years"
            industryExperience="0 Years"
            teachingExperience="7 Years"
            papersPublished={{ total: 2, national: 2, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={manojYawalkar}
            name="Prof. Manoj Shriram Yawalkar"
            designation="Assistant Professor"
            qualification="MMS(Fin), MMS (Comp Mgt), PGDBM (Gen. Mgt.), MCM, BCS"
            specialization="General Management, Computer Management, Financial Management"
            mailId="manojyawalkar2009@gmail.com"
            researchArea="-"
            totalExperience="28 Years"
            industryExperience="4 Years"
            teachingExperience="24 Years"
            papersPublished={{
              total: "10/04",
              national: "10",
              international: "04",
            }}
            booksPublished={{ total: "01", national: 1, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={komal}
            name="Prof. Mrs. Komal H. Apte  "
            designation="Assistant Professor"
            qualification="M.B.A (Marketing), UGC NET qualified"
            specialization="Marketing"
            mailId="gpta.komal@gmail.com"
            researchArea="Financial Products Marketing"
            totalExperience="6 Years"
            industryExperience="5 Years"
            teachingExperience="1 Year"
            papersPublished={{ total: 0, national: 0, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={madhuri}
            name="Prof. Madhuri Dambal"
            designation="Assistant Professor"
            qualification="Company Secretary, PGDBA(Finance), M.com, B.com, B Ed"
            specialization="Finance and Taxation"
            mailId="madhuridambal5@gmail.com"
            researchArea="Internet of Things, Machine learning"
            totalExperience="11 Years"
            industryExperience="9+ Years"
            teachingExperience="2 Years"
            papersPublished={{ total: "Nil", national: 0, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={sonal}
            name="Prof. Ms. Sonal S. Tejwani"
            designation="Assistant Professor"
            qualification="B.Com , MBA (Finance) , SET Qualified"
            specialization="Finance"
            mailId="sonaltejwani3110@gmail.com"
            researchArea="Finance"
            totalExperience="02 Years"
            industryExperience="1 Year 8 months"
            teachingExperience="$ months"
            papersPublished={{ total: "Nil", national: 0, international: 0 }}
            booksPublished={{ total: "Nil", national: 0, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "Nil",
            }}
            googleScholarId="-"
          />
        </div>
        <div>
          <FacultyCard
            img={smita}
            name="Prof. Smita Prashant Sonawane"
            designation="Assistant Professor"
            qualification="B.Sc. (Statistics), MBA (Finance)"
            specialization="Financial Management"
            mailId="smitasonawane01@gmail.com"
            researchArea="Financial Management"
            totalExperience="7 Years"
            industryExperience="0 Years"
            teachingExperience="7 Years"
            papersPublished={{
              total: "03/02",
              national: "01/01",
              international: "02/01",
            }}
            booksPublished={{ total: 2, national: 2, international: 0 }}
            researchGuidance={{
              total: 0,
              completed: 0,
              pursuing: 0,
              patents: "01",
            }}
            googleScholarId="-" // You can add the Google Scholar ID if available
          />
        </div>
      </div>
    </div>
  );
}

export function NonTeaching() {
  return (
    <div className="bg-light m-0 px-3 pb-3">
      <h5 className="title-text py-4">Non-Teaching Faculty</h5>
      <div>
        <NonTeachingFacultyCard
          img={prashant}
          name="Mr. Prashant Markale"
          designation="Office Superintendent"
          qualification="L.L.B., M.A. "
          specialization="-"
          mailId="prashantmarkalepune@gmail.com"
          totalExperience="11 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={megha}
          name="Mrs. Megha S.Satav"
          designation="Junior Clerk"
          qualification="M.Com"
          specialization="Accounting"
          mailId="satavmegha@gmail.com"
          totalExperience="25 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={aruna}
          name="Mrs. Aruna Umesh Kawathekar"
          designation="Asst. Librarian"
          qualification="M.Com, M.Lib, I.sc"
          specialization="Library and Information Science "
          mailId="kawathekarau@gmail.com"
          totalExperience="25 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={santosh}
          name="Mr. Santosh Bhanudas Bagav "
          designation="Librarian"
          qualification="Classification, Cataloguing, Information Retrieval, Research Methodology."
          specialization="Library Science"
          mailId="santoshbagav4@gmail.com"
          totalExperience="8.5Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={mrudula}
          name="Ms. Mrudula J Madiwale"
          designation="Asst. Librarian"
          qualification="M.Lib & ISc, B.Ed. , M.A."
          specialization="Library and Information Science"
          mailId="mridulamadiwale@yahoo.com"
          totalExperience="15 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={sneha}
          name="Ms. Snehal  Rahul Padhye"
          designation="Accounts Clerk"
          qualification="B.Com, BBM"
          specialization="Accounting"
          mailId="padhyesnehal2174@gmail.com"
          totalExperience="18 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={akanksha}
          name="Ms. Akanksha Todarmal"
          designation="Junior Clerk"
          qualification="M.Sc (Chemistry)"
          specialization="Accounting"
          mailId="akankshastoradmal@gmail.com"
          totalExperience="2 Years"
        />
      </div>
      <div>
        <NonTeachingFacultyCard
          img={swati}
          name="Mrs. Swati Kantilal Gadhave"
          designation="Junior Clerk"
          qualification="M.Com"
          specialization="Accounting"
          mailId="swatinakure201@gmail.com"
          totalExperience="10 Years"
        />
      </div>
    </div>
  );
}
