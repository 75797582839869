import { useState, useEffect } from "react";
import "./UpcomingEvents.css";
import Blink from "react-blink-text";
import { newsData } from "../../../Data/NewsAnnouncementData"; // Import newsData directly
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { NewsIcon } from "../../SvgIcons";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const NewsAnnouncementText = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleTexts, setVisibleTexts] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === newsData?.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const nextIndex1 = (currentIndex + 1) % newsData?.length;
    setVisibleTexts([newsData[currentIndex], newsData[nextIndex1]]);
  }, [currentIndex]);

  return (
    <div className="upcoming-event" style={{ height: "400px" }}>
      {visibleTexts.map((item, index) => (
        <div className="upcoming-texts" key={index}>
          <div className="home-card-content">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid xs={1} className="home-card-title mt-1">
                  <NewsIcon />
                </Grid>
                <Grid xs={11}>
                  <h5 className="home-card-title"> {item?.title}</h5>{" "}
                  {/*Newstitle*/}
                </Grid>
                <Grid xs={12} className="ms-3">
                  <div className="home-card-body mx-3">
                    {/*Newsbody*/}
                    {/* {item?.description}            */}
                    {/* Render news body */}
                    <div>
                      <Link
                        to={"/NewsAnnouncementPage"}
                        style={{ color: "steelblue", fontSize: 13 }}
                      >
                        {" "}
                        View More...
                      </Link>
                    </div>
                  </div>
                  <div className="Application home-card-Footer mt-3 mx-1">
                    {/*NewsFooter*/}
                    <Blink
                      color="gray"
                      text={item?.updatedDate ? item?.updatedDate : "Upcoming"}
                      fontSize={15}
                    >
                      {item?.date}
                    </Blink>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsAnnouncementText;
