import React, { useState } from 'react';
import './FlipCard.css';
import Table from 'react-bootstrap/Table';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

function FlipCard() {
  const [isFlipped, setFlipped] = useState(false);

  const toggleCard = () => {
    setFlipped(!isFlipped);
  };

  return (
    <>
      <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onMouseEnter={toggleCard} onMouseLeave={toggleCard} cardZIndex={'auto'} flipSpeedBackToFront={10} flipSpeedFrontToBack={10}>
        <div className="flip-card-inner">
          <div className="flip-card-front mobileViewType">
            <h5 className="title-text mb-5 text-bold">CONTACT INFO</h5>
            <Table className='text-wrap ms-2'  striped borderedless hover size="sm">
              <thead>
                <tr className='px-5'>
                  <th className='px-1' style={{paddingBottom:'70px'}}>
                    <FmdGoodIcon />Address:
                  </th>
                  <td >PES MIBS Nigdi.<br />
                  Modern Educational Campus, <br/>
                  Scheme 4, Yamuna Nagar, Nigdi,<br />
                  Pimpri-Chinchwad, Maharashtra 411044. <br />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <svg fill="#000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 4C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7C1 5.34315 2.34315 4 4 4H20ZM19.2529 6H4.74718L11.3804 11.2367C11.7437 11.5236 12.2563 11.5236 12.6197 11.2367L19.2529 6ZM3 7.1688V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7.16882L13.8589 12.8065C12.769 13.667 11.231 13.667 10.1411 12.8065L3 7.1688Z" ></path>
                    </svg> &nbsp;
                    Email : </th>
                  <td>imcdmba@rediffmail.com/ imcd.admission@gmail.com</td>
                </tr>
                <tr>
                  <th className='w-25 py-3'>
                    <svg fill="#000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.442 27.442">
                      <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546 c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481 s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469 c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z M19.995,21.1H7.448V3.373h12.547V21.1z"></path>
                    </svg> &nbsp;
                    Phone:</th>
                  <td className='py-3'>
                    +91 9545450545 / 9011070916</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="flip-card-back mobileViewType">
            <h5 className="mb-5 text-bold text-light">CONTACT INFO</h5>
            <Table striped borderedless hover size="sm" className='text-wrap ms-2'>
              <thead>
                <tr className='px-5'>
                <th className='px-1' style={{paddingBottom:'70px'}}>
                    <FmdGoodIcon />Address:
                  </th>
                  <td >PES MIBS Nigdi.<br />
                  Modern Educational Campus, <br/>
                  Scheme 4, Yamuna Nagar, Nigdi,<br />
                  Pimpri-Chinchwad, Maharashtra 411044. <br />
                  </td>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <th>
                      <svg fill="#000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 4C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7C1 5.34315 2.34315 4 4 4H20ZM19.2529 6H4.74718L11.3804 11.2367C11.7437 11.5236 12.2563 11.5236 12.6197 11.2367L19.2529 6ZM3 7.1688V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7.16882L13.8589 12.8065C12.769 13.667 11.231 13.667 10.1411 12.8065L3 7.1688Z" ></path>
                      </svg> &nbsp;
                      Email : </th>
                      <td>imcdmba@rediffmail.com/ imcd.admission@gmail.com</td>
                  </tr>
                   <tr>
                  <th className='w-25 py-3'>
                    <svg fill="#000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.442 27.442">
                      <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546 c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481 s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469 c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z M19.995,21.1H7.448V3.373h12.547V21.1z"></path>
                    </svg> &nbsp;
                    Phone:</th>
                  <td className='py-3'>
                  +91 9545450545 / 9011070916</td>
                  </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlipCard;
