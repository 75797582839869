import { Link } from 'react-router-dom';
import {SocialIconSet} from './SvgIcons'

const Footer = () =>{
    return(
        <>
            {/* Footer */}
            <footer className="default-padding pb-5 bg-light footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center">
                            <img src="/logo.png" alt="logo" height={80} />
                            <div>
                                <p className="my-1" style={{ fontSize: 12 }}>
                                    PROGRESSIVE EDUCATION SOCIETY'S
                                </p>
                                <p className="my-1 fw-bold" style={{ color: "var(--main-color)" }}>
                                    MODERN INSTITUTE OF BUSINESS STUDIES (AUTONOMOUS)
                                </p>
                                <p style={{ fontSize: 12 }}>
                                    (Formerly known as Institute of Management and Career Development IMCD),<br />
                                    Approved by AICTE New Delhi, Recognized by Govt. of Maharashtra, Accredited by NAAC, Permanently Affiliated to Savitribai Phule Pune University
                                </p>
                                <div className='mobViewBottom'>

                                <SocialIconSet/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <h5>USEFUL LINKS</h5>
                            <div className="mt-3">
                                <ul className="ps-4">
                                    <li>
                                        <>
                                            <p>IQAC</p>
                                        </>
                                    </li>
                                    <li>
                                        <>
                                            <p>IQAC Meeting</p>
                                        </>
                                    </li>
                                    <li>
                                        <>
                                            <p>Admission</p>
                                        </>
                                    </li>
                                    <li>
                                        <>
                                            <p>Gallery</p>
                                        </>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <h5>CONTACT HERE</h5>
                            <div className="mt-3">
                                <ul className="ps-4">
                                    <li>
                                        <p>
                                            PES MIBS Nigdi.
                                            <br />
                                            Modern Educational Campus, 
                                            <br />
                                            Scheme 4, Yamuna Nagar, Nigdi, 
                                            <br />
                                            Pimpri-Chinchwad, Maharashtra 411044.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            imcdmba@rediffmail.com, <br />
                                            imcd.admission@gmail.com
                                        </p>
                                    </li>
                                    <li>
                                        <p>+91 9545450545 / 9011070916</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="text-center">
                        <p className="m-1">
                            Copyright © 2023. All Rights Reserved by P. E. S. Modern Institute of
                            Business Studies.
                        </p>
                        <p className="m-1">
                            Designed &amp; Developed by <Link target='_blank' to="https://www.akronsystems.com">Akron Systems</Link>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;