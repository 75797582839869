import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
// import { Tree, TreeNode } from 'react-organizational-chart';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import AdmissionEnquiry from "./AdmissionEnquiry";

const sidemenus = [
    {
        title:'MBA',
        url: "/admission/MBA"
    },
    {
        title:'MCA ',
        url: "/admission/MCA"
    },
    {
        title:'Ph.D ',
        url: "/admission/PHD"
    },
    {
        title: 'Eligibility',
        url: '/admission/Eligibility',
    },
    {
        title: 'Fees Structure and Documents',
        url: '/admission/FeesStructure',
    },
    {
        title: 'MIBS Prospectus',
        url: '/admission/Prospectus',
    },
    {
        title: 'Contact For Admissions',
        url: '/admission/Contact',
    },
    
];

export default function Admission(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export function MBAAdmission(){
    return(
        <>
        <div className="text-justify">
            <h5 className="my-3 title-text">Sanctioned Intake Capacity : 120</h5>
            <h6 className="my-3 fw-bold">Procedure of Admission (CAP round) confirmation in PES MIBS</h6>
            <h5 className="my-3 title-text">Steps for Admission Process</h5>
            <h6 className="my-3 fw-bold">Step 1 : Verification of Allotment Letter</h6>
            <h6 className="my-3 fw-bold">Step 2 : Fill the Admission Form</h6>
            <h6 className="my-3 fw-bold">Step 3 : Verification of Original Documents</h6>
            <h6 className="my-3 fw-bold">Step 4 : Fill fee challan and deposit Institute fees</h6>
            <h6 className="my-3 fw-bold">Step 5 : Online Admission Confirmation (DTE Website)</h6>
            <h6 className="my-3 fw-bold">Step 6 : Final Submission</h6>
            <h6 className="my-3 fw-bold">Step 7 : Fill SPPU Eligibility form</h6>
            <p className="fw-bold m-0">For More Details, Please Refer Below Link:</p>
            <Link to={`https://cetcell.mahacet.org/`} style={{color:'steelblue'}} target="_blank"> https://cetcell.mahacet.org/</Link>
            
            </div>
        </>
    );
}

export function MCAAdmission(){
    return(
        <>
         <div className="text-justify">
            <h5 className="my-3 title-text">Sanctioned Intake : 60</h5>
            <h6 className="my-3 fw-bold">Procedure of Admission (CAP round) confirmation in PES MIBS</h6>
            <h5 className="my-3 title-text">Steps for Admission Process</h5>
            <h6 className="my-3 fw-bold">Step 1 : Verification of Allotment Letter</h6>
            <h6 className="my-3 fw-bold">Step 2 : Fill the Admission Form</h6>
            <h6 className="my-3 fw-bold">Step 3 : Verification of Original Documents</h6>
            <h6 className="my-3 fw-bold">Step 4 : Fill fee challan and deposit Institute fees</h6>
            <h6 className="my-3 fw-bold">Step 5 : Online Admission Confirmation (DTE Website)</h6>
            <h6 className="my-3 fw-bold">Step 6 : Final Submission</h6>
            <h6 className="my-3 fw-bold">Step 7 : Fill SPPU Eligibility form</h6>
            <p className="fw-bold m-0">For More Details, Please Refer Below Link:</p>
            {/* <Link to={`https://mca2023.mahacet.org.in/cet2023/mca23/index.php?show=home`} target="_blank"> https://mca2023.mahacet.org.in/cet2023/mca23/index.php?show=home</Link> */}
            <Link to={`https://cetcell.mahacet.org/`} style={{color:'steelblue'}} target="_blank"> https://cetcell.mahacet.org/</Link>
            </div>
        </>
    );
}

export function PHDAdmission(){
    return(
        <>
        
       <div className="text-justify">
        {/* <h5 className="my-3 title-text">PhD Admission Procedure</h5> */}
        <h5 className="my-3 title-text">Steps for Admission Process</h5>
        <h6 className="my-3 fw-bold">Step 1 : Apply for SPPU PET exam</h6>
        <h6 className="my-3 fw-bold">Step 2 : Selected Research center in online mode</h6>
        <h6 className="my-3 fw-bold">Step 3 : Present research Idea in research center</h6>
        <h6 className="my-3 fw-bold">Step 4 : Get the approval from SPPU</h6>
        <div style={{margin:'10px'}}>
        {/* <div style={{ margin: '100px auto', maxWidth: '600px', padding: '20px 50px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}> */}
        <p>For Ph.D Admission process</p>
         <Link to="https://bcud.unipune.ac.in/phd_entrance/applicant/login.aspx"   target="_blank" style={{color:'steelblue', wordWrap:'break-word'}}>visit:https://bcud.unipune.ac.in/phd_entrance/applicant/login.aspx.</Link>
        </div>
        </div>
      </>
    );
}

export function Eligibility(){
    return(
      <>
       <div className="text-justify">
        <h5 className="my-3 title-text">Eligibility for MBA</h5>
        <h6 className="my-3 fw-bold">Maharashtra State Candidature Candidates</h6>
        <ol type="i">
            <li>The Candidate should be an Indian National. </li>
            <li>Passed minimum three year duration Bachelor’s Degree awarded by any of the Universities recognized by University Grants Commission or Association of Indian Universities in any discipline with at least 50% marks in aggregate or equivalent (at least 45% in case of candidates of backward class categories, Economically weaker section and Persons With Disability Candidates belonging to Maharashtra State only) or its equivalent.</li>
            <li>Obtained non zero score in CET conducted by the Competent Authority (MAHMBA/MMS- CET 2023).</li>
        </ol>

        <h6 className="my-3 fw-bold">All India Candidature Candidates, Union Territory of Jammu and Kashmir and Union Territory of Ladakh Migrant Candidature Candidates</h6>
        <ol type="i">
            <li>The Candidate should be an Indian National.</li>
            <li>Passed minimum Three year duration Bachelor's Degree awarded by the University recognized by University Grants Commission or Association of Indian Universities in any discipline with at least 50% marks in aggregate or equivalent (at least 45% in case of candidates of backward class categories, economically weaker section and Persons with Disability Candidates belonging to Maharashtra State only) or its equivalent.</li>
            <li>The candidate should have obtained non zero positive score in any one of the following examinations. - CET conducted by the Competent Authority or Common Admission Test conducted by Indian Institute of Management (CAT) or Common Management Aptitude Test Conducted by National Testing Agency (CMAT) or Xavier Aptitude Test conducted by Xavier School of Management Jamshedpur (XAT) or Entrance Test for Management Admissions conducted by the Association of Indian Management Schools (ATMA) or Management Aptitude Test Conducted by All India Management Association (MAT) or Graduate Management Aptitude Test Conducted by Graduate Management Admission Council, United States of America (GMAT).</li>
        </ol>

        <h5 className="my-3 mt-5 title-text">Eligibility Criteria for MCA 2023</h5>
        <h6 className="my-3 fw-bold">The candidate should fulfill the following eligibility criteria:</h6>
        <ol type="i">
            <li>The candidate should be an Indian National.</li>
            <li>The should have Passed B.C.A or B.Sc. (Computer Science) or B.Sc (IT) or B.E. (CSE) or B.Tech (CSE) or B.E. (IT) or B.Tech. (IT) or equivalent Degree and obtained at least 50% marks in aggregate (at least 45% in case of candidates of Backward Class categories, economically weaker section and Persons with Disability category belonging to Maharashtra State); Or (ii) The candidate should have any graduation degree (e.g : B.E. or B.Tech or B.Sc or B.Com or B.A. or B.Voc. etc) preferably with Mathematics at 10+2 Level or at Graduation Level(with additional bridge Courses as per the norms of the concerned University) and obtained at least 50% marks in aggregate (at least 45% in case of candidates of Reserved categories,Economically Weaker Section and Persons with Disability category belonging to Maharashtra State);(Candidates appearing for final year of qualifying examination are also eligible to appear for CET)</li>
        </ol>
        <h6 className="my-3 fw-bold">Note:</h6>
        <ul>
            <li>Aggregate marks means the grand total of marks obtained by the candidate in subjects on which the class declaration is made in the particular University from which the candidate is passing the qualifying examination.</li>
            <li>In case the candidates are awarded grades/CGPA instead of marks, the conversion of grades/CGPA to percentage of marks would be based on the procedure certified by the University/institution from where they have obtained the bachelor degree.</li>
            <li>The percentage of marks shall be calculated by rounding off to two places after decimal.</li>
            <li>The candidates belonging to SC, VJ/DT (NT (A)), NT (B), NT(C), NT (D), OBC, SBC categories should produce “Caste Validity Certificate” issued by Scrutiny Committee of Social Welfare Department and the Candidate belonging to ST category should submit “Tribe Validity Certificate” issued by Scrutiny Committee of Tribal Department and valid Non Creamy Layer certificate except SC, ST candidates at the time of verification of documents.</li>
        </ul>
        </div>
      </>
    );
}

export function FeesStructure(){
    return(
      <>
       <div className="text-justify">
        <h5 className="my-3 title-text">Documents Required for MBA and MCA Admission</h5>
        <table>
            <thead>
                <tr>
                    <th>Sr.No.</th> 
                    <th>Particulars</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1.</td>
                    <td>CAP Allotment Letter</td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Score Card</td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td>SSC Marks Statement</td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td>HSC Marks Statement</td>
                </tr>
                <tr>
                    <td>5.</td>
                    <td>All statement of marks for the Bachelor’s Degree Examination</td>
                </tr>
                <tr>
                    <td>6.</td>
                    <td>College Leaving certificate (LC / TC)</td>
                </tr>
                <tr>
                    <td>7.</td>
                    <td>Migration Certificate (OTH & OMS Category Students) (if applicable)</td>
                </tr>
                <tr>
                    <td>8.</td>
                    <td>Domicile Certificate by permanent residence /Birth Cert/ School Leaving attested by School & College</td>
                </tr>
                <tr>
                    <td>9.</td>
                    <td>Certificate of Indian Nationality. In lieu of the “Certificate of Indian Nationality”/The School leaving Certificate /Indian Passport/ Birth Certificate</td>
                </tr>
                <tr>
                    <td>10.</td>
                    <td>GAP Certificate (If Applicable)</td>
                </tr>
                <tr>
                    <td>11.</td>
                    <td>Caste certificate (If Applicable)</td>
                </tr>
                <tr>
                    <td>12.</td>
                    <td>Caste / Tribe Validity certificate (If Applicable)</td>
                </tr>
                <tr>
                    <td>13.</td>
                    <td>Non-creamy Layer certificate  valid up to 31st March 2024 (If Applicable)</td>
                </tr>
                <tr>
                    <td>14.</td>
                    <td>Income Certificate (If Applicable)</td>
                </tr>
                <tr>
                    <td>15.</td>
                    <td>EWS Certificate (if Applicable)</td>
                </tr>
                <tr>
                    <td>16.</td>
                    <td>Certificate of Handicap (if Applicable)</td>
                </tr>
                <tr>
                    <td>17.</td>
                    <td>Certificate in Proforma A,B,C,D,E,F,G, V as applicable in original</td>
                </tr>
                <tr>
                    <td>18.</td>
                    <td>Aadhar Card</td>
                </tr>
                <tr>
                    <td>19.</td>
                    <td>PAN Card</td>
                </tr>
                <tr>
                    <td>20.</td>
                    <td>Passport Size Photo</td>
                </tr>
            </tbody>
        </table>
        </div>
      </>
    );
}

export function MIBSProspectus(){
    return(
        <>
            <object
                // data="/assets/pdf/InformationGuidelines.pdf"
                data={process.env.PUBLIC_URL+'/assets/pdf/InformationGuidelines.pdf'}
                type="application/pdf"
                width="100%"
                height={800}
            >
            <p>Your web browser doesn't have a PDF plugin.</p>
            <p>Instead you can <Link to={process.env.PUBLIC_URL+"/assets/pdf/InformationGuidelines.pdf"}> click here to download the PDF file.</Link></p>
            </object>
        </>
    );
}

export function ContactAdmission(){
    return(
      <>
        <AdmissionEnquiry />
      </>
    );
}