import React from "react";

const Breadcrumb = (props) => {
    // let pageTitle = 'Page Title';
    return(
        <>
            <div className="position-relative">
                <div className="overlayDiv"/>
                <div className="backgroundDiv">
                    <div className="container d-flex align-items-center">
                        <h4 id="page-title" className="text-white opacity-75">
                            {props.pageTitle}
                        </h4>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Breadcrumb;