import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import { ANTI_RAGGING, CommitteeData, Initiatives, InternalCommittee, StudentGrievanceData } from "../Data/GovernanceData";
import organizationChart from "../assets/Library/OrganizationChart.jpg"

const sidemenus = [
    {
        title: 'Organization Chart',
        url: '/governance/OrganizationChart',
      },
      {
        title: 'College Development Committee',
        url: '/governance/CollegeDevelopmentCommittee',
      },
      {
        title: 'Internal Quality Assurance Committee',
        url: '/governance/InternalQualityAssuranceCommittee',
      },
      {
        title: 'Anti Ragging Committee',
        url: '/governance/AntiRaggingCommittee',
      },
      {
        title: 'Internal Complaint Committee',
        url: '/governance/InternalComplaintCommittee',
      },
      {
        title: 'Student Grievance Redressal',
        url: '/governance/StudentGrievanceRedressalCommittee',
      },
      {
        title: 'SC-ST-Committee',
        url: '/governance/SC-ST-Committee',
      },
];

export default function Governance(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );

        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });

    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {props.pageContent}
                                   </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export function Organization(){
    return(
      <div>
      {/* <h5 className="title-text my-2 mb-4">Organization Chart</h5>  */}
      <div> 
          <img src={organizationChart} height={'auto'} width={700} className="px-5" />
      </div> 
     
  </div>
    );
}

export function CollegeDevelopment(){
    return(
      <div>
         <h5 className="title-text my-4 mt-2">
        College Development Committee (CDC)
         </h5>
         <div>
           <ul>
             <li>Prof. Dr. Gajanan R. Ekbote(Chairman)</li>
             <li>Prof. Dr. Milind D. Alandikar (Visitor)</li>
             <li>Prof. S. S. Deshmukh (Education)</li>
             <li>Prof. Dr. Ms. N. G. Ekbote (Research)</li>
             <li>Prof. Dr. P. D. Choudhari (Special Invitee)</li>
             <li>Prof. S. S. Thengdi (Special Invitee)</li>
             <li>Prof. Dr. Mrs. K. R.Joshi (Special Invitee)</li>
             <li>Dr. Maithili Arjunwadkar (Director)</li>
             <li>Dr. Manoj Sathe (Coordinator)</li>
             <li>Mr. Prashant Markale (Member-Non teaching)</li>
             <li>Prof. Mahesh Salvi (Member-Teaching)</li>
  
           </ul>
         </div>
      </div>
    );
}

export function InternalQualityAssurance(){
    return(
      <div>
         <div>
           <h5 className="title-text mb-2">Internal Quality Assurance Committee</h5>
          
        <Initiatives />
         </div>
      </div>
    );
}

export function AntiRagging(){
    return(
      <div>
         <div>
         <h5 className="title-text mb-2">ANTI RAGGING COMMITTEE</h5>
        
        <ANTI_RAGGING />
           <p>Contact us 9011070916</p>
         </div>
      </div>
    );
}

export function InternalComplaint(){
    return(
      <div>
         <div>
         <h5 className="title-text  mb-2">Internal Complaint Committee</h5>
        <InternalCommittee />
          <p>Contact us 9011070916</p>
         </div>
  
        
        
      </div>
    );
}

export function StudentGrievance(){
    return(
      <div>
           <div>
          <h5 className="title-text mb-2">Student Grievance Redressal Committee</h5>
         
          <StudentGrievanceData />
          <p>Contact us 9011070916</p>
        </div>
  
      </div>
    );
}

// SC/ST Committee 

export function SCST_Committee(){
  return(
      <div>
         <div>
         <h5 className="title-text mb-2">SC/ST Committee</h5>
         <CommitteeData />
         </div>
      </div>
  );
}
