
import React from 'react';
import TableComponent, { TableComponent2colm } from '../Components/TableComponent';


export  function Initiatives() {
  const userData = [
    {
      NameOfTheMember: 'Dr. Maithili Arjunwadkar ' ,
      Designation:'Director',
      PositionInTheCommittee:'Chairman, Head of the Institution '
  },
  {
    NameOfTheMember: 'Dr. Milind Alandikar' ,
    Designation:'Visitor, MIBS',
    PositionInTheCommittee:'Management Representative'
  },
  {
      NameOfTheMember: 'Dr.Manoj Sathe' ,
    Designation: 'Co-coordinator',
      PositionInTheCommittee:'Management Representative'
    },
    {
      NameOfTheMember: 'Dr.Mandakini Bhosale' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'IQAC Co-ordinator'
    },
    {
      NameOfTheMember: 'Mr.Prashant Markale' ,
      Designation:'Non-teaching staff',
      PositionInTheCommittee:'Administrative Officer'
    },
    {
      NameOfTheMember: 'Mrs.Bharati Ramageri' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member Teaching faculty '
    },
    {
      NameOfTheMember: 'Dr. Prasad Ghodke' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member Teaching faculty '
    },
    {
      NameOfTheMember: 'Prof. Neha Gupte' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member Teaching faculty '
    },
    {
      NameOfTheMember: 'Mrs. Aruna Kawathekar' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member Teaching faculty '
    },
    {
      NameOfTheMember: 'Mr.Chirantan Kulkarni' ,
      Designation:'Student Representative',
      PositionInTheCommittee:'Member Student/Alumni'
    },
    {
      NameOfTheMember: 'Mr. Devenra Mali' ,
      Designation:'Student Representative',
      PositionInTheCommittee:'Employer/ Industrialist'
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name Of The Member', 'Designation', 'Position In The Committee'],
  };

  return (
    <div>
      <TableComponent data={userData} headers={tableHeaders} />
    </div>
  );
};

export  function StudentGrievanceData() {
  const userData = [
    {
      NameOfTheMember: 'Dr. Maithili Arjunwadkar ' ,
      Designation:'Director',
      PositionInTheCommittee:'Chairman'
  },
  {
      NameOfTheMember: 'Dr.Manoj Sathe' ,
    Designation: 'Co-coordinator',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Prof. Dr. P.D. Chaoudhari' ,
      Designation:'PES Modern college of  Pharmacy',
      PositionInTheCommittee:'Ombudsman(appointed by SPPU ) '
    },
    {
      NameOfTheMember: 'Mrs.Mrudula Madiwale' ,
      Designation:'Non-teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mrs.Snehal Padhye' ,
      Designation:'Non-Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Miss.Sakshi Marathe' ,
      Designation:'Student',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mr.Rahul Chavan' ,
      Designation:'Student',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mrs. Bharti Armani' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member Secretary'
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name Of The Member', 'Designation', 'Position In The Committee'],
  };

  return (
    <div>
      <TableComponent data={userData} headers={tableHeaders} />
    </div>
  );
};

export  function InternalCommittee () {
  const userData = [
{
      NameOfTheMember: 'Dr. Maithili Arjunwadkar ' ,
      Designation:'Director',
      PositionInTheCommittee:'Chairman'
  },
  {
      NameOfTheMember: 'Dr.Manoj Sathe' ,
      Designation: 'Co-coordinator',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Meena Kurlekar' ,
      Designation:'Director Vanchit Vikas',
      PositionInTheCommittee:'Member of NGO'
    },
    {
      NameOfTheMember: 'Dr.Mandakini Bhosale' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mrs.Bharati Ramageri' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mr.Prashant Markale' ,
      Designation:'Non-teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mrs.Snehal Padhye' ,
      Designation:'Non-teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mrs.Mrudula Madiwale' ,
      Designation:'Non-Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Ratnamala Swami' ,
      Designation:'Student',
      PositionInTheCommittee:'Student Representative'
    },
    {
      NameOfTheMember: 'Dhyaneshwari Mahalle' ,
      Designation:'Student',
      PositionInTheCommittee:'Student Representative'
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name Of The Member', 'Designation', 'Position In The Committee'],
  };

  return (
    <div>
      <TableComponent data={userData} headers={tableHeaders} />
    </div>
  );
};

export  function ANTI_RAGGING  () {
  const userData = [
    {
      NameOfTheMember: 'Dr. Maithili Arjunwadkar ' ,
      Designation:'Director',
      PositionInTheCommittee:'Chairman'
    },
    {
      NameOfTheMember: 'Mr. Ranganath Unde' ,
      Designation: 'Sr.P.I .Nigdi',
      PositionInTheCommittee:'Police-Representative'
    },
    {
      NameOfTheMember: 'Mrs. Bharati Ramageri' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Dr. Mandakini Bhosale' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mr. Prashant Markale' ,
      Designation:'Non-Teaching staff',
      PositionInTheCommittee:'Member'
    },
    {
      NameOfTheMember: 'Mr.Sahil pawar' ,
      Designation:'Student-representative',
      PositionInTheCommittee:'Student'
    },
    {
      NameOfTheMember: 'Miss. Sarika Shinde' ,
      Designation:'Student-representative',
      PositionInTheCommittee:'Student'
    },
    {
      NameOfTheMember: 'Mr .Mahesh salvi' ,
      Designation:'Teaching staff',
      PositionInTheCommittee:'Member-Secretary'
    }
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name Of The Member', 'Designation', 'Position In The Committee'],
  };

  return (
    <div>
      <TableComponent data={userData} headers={tableHeaders} />
    </div>
  );
};


export  function CommitteeData() {
  const userData = [
    {
      col1: 'Dr. Maithili Arjunwadkar ' ,
      col2:'Chairperson',
      col3:'Director'
  },
  {
    col1: 'Dr.Manoj Sathe' ,
    col2: 'Member',
    col3:'Co-ordinator, MIBS'
  },
  {
    col1: 'Mr. Santosh Bagav' ,
    col2:'Member',
    col3:'Librarian'
  },

    {
      col1: 'Mr.Prashant Markale' ,
      col2:'Member',
      col3:'Non – Teaching staff'
    },
    {
      col1: 'Miss.Gajare Ruchita' ,
      col2:'Member',
      col3:'Student Representative'
    },
    {
      col1: 'Mr.Asif Tadavi' ,
      col2:'Member',
      col3:'Student Representative'
    },
    {
      col1: 'Dr. Mandakini Bhosale' ,
      col2:'Member-Secretary',
      col3:'Teaching- Staff'
    },
   
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Name of the member', 'Position in the Committee', 'Authority'],
  };

  return (
    <div>
      <TableComponent2colm data={userData} headers={tableHeaders} />
    </div>
  );
};