import GalleryView from "../Components/GalleryView";
import VideoPlayer from "../Components/VideoPlayer";
import { Link } from "react-router-dom";
import "../css/vertical-carousel.css";
import Aos from "aos";
import CourseSlick from "../Components/CourseSlick";
import TestimonialSlick from "../Components/TestimonialSlick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import img1 from "../assets/BannerImages/bannerImg1.jpeg";
import img2 from "../assets/BannerImages/bannerImg2.jpeg";
import img3 from "../assets/BannerImages/bannerImg3.jpeg";
import img4 from "../assets/BannerImages/bannerImg4.jpeg";
import img5 from "../assets/BannerImages/bannerImg5.jpeg";
import img6 from "../assets/BannerImages/bannerImg6.jpeg";
import img7 from "../assets/BannerImages/bannerImg7.jpeg";
import img8 from "../assets/BannerImages/bannerImg8.jpeg";
import img9 from "../assets/BannerImages/bannerImg9.jpeg";
// import img10 from "../assets/BannerImages/bannerImg10.jpg";
// import img11 from "../assets/BannerImages/bannerImg11.JPG";
// import img12 from "../assets/BannerImages/bannerImg12.JPG";
import img13 from "../assets/BannerImages/MIBS- Our Recruters.jpg";
import { Grid } from "@mui/material";
import NewsAnnouncementText from "../Components/home/eventCards/NewsAnnouncement";
import UpcomingEvents from "../Components/home/eventCards/UpcomingEvents";
import NoticeBoardText from "../Components/home/eventCards/NoticeBoard";

Aos.init({
  duration: 1000,
});

// Multiple slides
let items = document.querySelectorAll(".cc .ccl");
items.forEach((el) => {
  const minPerSlide = 2;
  let next = el.nextElementSibling;
  for (var i = 1; i < minPerSlide; i++) {
    if (!next) {
      // wrap carousel by using first child
      next = items[0];
    }
    let cloneChild = next.cloneNode(true);
    el.appendChild(cloneChild.children[0]);
    next = next.nextElementSibling;
  }
});

const Home = () => {
  const images = [
    // img10,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    // img11,
    // img12,
    img13,
  ];
  return (
    <>
      {/* Carousel Images */}
      <div className="default-padding p-0 px-0">
        <div className="container-fluids">
          <div className="Image-Carousel">
            <Carousel
              autoPlay
              infiniteLoop
              showArrows={true}
              showThumbs={false}
            >
              {images.map((image, index) => (
                <div key={index} style={{ minWidth: "100%" }}>
                  <img src={image} alt={`Image ${index}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div>
        <Grid container className="newsCard my-5 px-5">
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-right">
            <h5 className="text-bold decorated mb-4">NEWS ANNOUNCEMENT</h5>
            <NewsAnnouncementText />
            <Link to={"/NewsAnnouncementPage"}>
              <h5 className="ViewMore">View All News</h5>
            </Link>
          </Grid>
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-up">
            <h5 className="text-bold decorated mb-4">NOTICE BOARD</h5>
            <NoticeBoardText />
            <Link to={"/NoticeBoardPage"}>
              <h5 className="ViewMore">View All Notices</h5>
            </Link>
          </Grid>
          <Grid md={4} xs={12} className="px-3 box" data-aos="fade-left">
            <h5 className="text-bold decorated mb-4">UPCOMING EVENT</h5>
            <UpcomingEvents />
            <Link to={"/UpcomingEventPage"}>
              <h5 className="ViewMore">View All Events</h5>
            </Link>
          </Grid>
        </Grid>
      </div>
      {/* Why Choose us*/}
      <div className="default-padding bg-light">
        <div className="container">
          <div className="text-center" data-aos="fade-up">
            <h5 className="title-text">WELCOME TO</h5>
            <h2>Modern Institute Of Business Studies</h2>
          </div>
          <Grid container className="why-choseus-area default-padding-top">
            <Grid xs={12} md={4} className="py-2">
              <div className="position-relative my-1">
                <img
                  src="assets/img/1.jpg"
                  alt="Thumb"
                  style={{ maxWidth: "100%", height: 320 }}
                />
                <VideoPlayer />
              </div>
            </Grid>
            <Grid container md={8} className="why-choseus-area px-4">
              <Grid xs={12} md={4} className="px-2 py-3">
                <div className="item">
                  <span>01</span>
                  <i className="flaticon-library" />
                  <Link>
                    <h5 className="my-4">Campus Life</h5>
                  </Link>
                  <p>Absolute required of reserved in offering no.</p>
                  <ul>
                    <li>Point One</li>
                    <li>Point Two</li>
                    <li>Point Three</li>
                  </ul>
                </div>
              </Grid>
              <Grid xs={12} md={4} className="px-2 py-3">
                <div className="item">
                  <span>01</span>
                  <i className="flaticon-library" />
                  <Link>
                    <h5 className="my-4">Campus Life</h5>
                  </Link>
                  <p>Absolute required of reserved in offering no.</p>
                  <ul>
                    <li>Point One</li>
                    <li>Point Two</li>
                    <li>Point Three</li>
                  </ul>
                </div>
              </Grid>
              <Grid xs={12} md={4} className="px-2 py-3">
                <div className="item">
                  <span>01</span>
                  <i className="flaticon-library" />
                  <Link>
                    <h5 className="my-4">Campus Life</h5>
                  </Link>
                  <p>Absolute required of reserved in offering no.</p>
                  <ul>
                    <li>Point One</li>
                    <li>Point Two</li>
                    <li>Point Three</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Courses */}
      <div className="default-padding bg-light pt-0">
        <div className="container">
          <div className="container text-center" data-aos="fade-up">
            <h5 className="title-text">SPECIALIZATIONS OFFERED</h5>
            <h2>Here is our Top Courses</h2>
          </div>
          <div className="default-padding-top" data-aos="zoom-in">
            <Grid container className="course-box">
              <Grid xs={12} md={5} className="course-box-first px-2">
                <h2 className="fw-bold mb-4">
                  Select your favorite subject from best categories
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                  laudantium autem saepe sint ex placeat nihil laboriosam fugit,
                  quod nisi dolor rem cupiditate minus animi laborum voluptatem
                  assumenda. Neque, suscipit!
                </p>
                <button className="btn btn-main">View</button>
              </Grid>
              <Grid xs={12} md={7}>
                <CourseSlick />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* Temstimonial */}
      <div className="default-padding">
        <div className="container">
          <div className="text-center" data-aos="fade-up">
            <h5 className="title-text">TESTIMONIALS</h5>
            <h2>Our Students Review</h2>
          </div>
          <div className="default-padding-top" data-aos="zoom-in">
            <TestimonialSlick />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
