import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";

const sidemenus = [
    {
        title:'Master of Computer Application',
        url: "/courses/MCA"
    },
    // {
    //     title:'Innovative Practices',
    //     url: "/courses/MCA/InnovativePractices"
    // },
];

export default function MCA(props){
    const [menuName, setName] = useState(props.pageName);
    const [menuId, setId] = useState(props.pageId);

    useEffect(() => {
        // console.log('Menu :',menuName);
        // console.log(menuName.split(' ').join('').toString());
        let id = menuId.toString();
        document.getElementById(id).setAttribute('class','active-menu');

        // Add d-none class for all divs
        sidemenus.forEach(element => {
            let id = element.title.split(' ').join('').toString();
            document.getElementById(id).classList.add('d-none');
        });
        
        // remove class d-none of particular div on click by getting Id
        let divId = menuName.split(' ').join('').toString();
        document.getElementById(divId).classList.remove('d-none');

        // scroll top
        window.scrollTo(0, 0);


    }, [menuName, menuId]);

    const handleClick = event => {
        setName(event.currentTarget.textContent);
        setId(event.currentTarget.id);

        // Remove all class active-menu of '.sideMenu a' on click
        const allWithClass = Array.from(
            document.querySelectorAll(".sideMenu a")
        );
        allWithClass.forEach((element) => {
            element.classList.remove("active-menu");
        });
    };

    return(
        <>
            <Breadcrumb pageTitle={menuName} />
            <div className="container mt-3">
                <div className="row default-padding-bottom">
                    {/* Sidebar */}
                    <aside className="sideMenu col-lg-3">
                        <div className="scroll-div">
                            {sidemenus.map((menu,index)=>{
                                return(
                                    <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                                );
                            })}
                        </div>
                    </aside>
                    {/* content */}
                    <div className="col-lg-9">
                        <div className="px-lg-3 px-1">
                            {sidemenus.map((menu,index)=>{
                                return(
                                   <div id={menu.title.split(' ').join('').toString()} key={index}>
                                        {/* <h5 className="title-text my-4 mt-2">{menu.title}</h5>
                                        <div>
                                            {menu.content}
                                        </div> */}
                                        {props.pageContent}
                                   </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}


export function MCASpecialization(){
    return(
        <>
        <div className="text-justify">
            <h5 className="title-text my-4 mt-2">Master of Computer Application (MCA)</h5>

            <p>Progressive Education Society’s Modern Institute of Business Studies offers 2 
                years Masters of Computer Application (MCA) which is 2 years (4 semesters) Full 
                Time Post Graduate Program. MCA is approved by AICTE, New Dehli, recognized by 
                Directorate of Technical Education Government of Maharashtra State and Affiliated 
                by Savitribai Phule Pune University.
            </p>
            <p>The curriculum takes the MCA program to the next level in terms of implementing 
                Outcome Based Education along with the Choice Based Credit System (CBCS) 
                and Grading System.
            </p>
            <p>The basic objective of the Master of Computer Applications (MCA) is to provide 
                a steady stream of necessary knowledge, skills and foundation for acquiring a 
                wide range of rewarding careers into rapidly expanding world of Information 
                Technology.
            </p>
            <p>Students are expected to undertake one mini project starting from first semester 
                till third semester. The Project which is expected to work in industry to be 
                conducted in the FINAL Semester and evaluated at the end of the semester.
            </p>
            <p>Institute has to offer various open courses per each semester to the students. 
                The motive behind keeping an open course is to make students aware of current/upcoming 
                trends in Information Technology and other domains. Full autonomy is given to the
                Institute to plan and execute the open courses. It is expected to extend the autonomy 
                to the student also. Care must be taken to consider credit points and necessary 
                contact hours assigned to it while finalizing any open course for the given semester.
            </p>
            <p>The Institutes supports placement opportunity to get jobs in good MNC’s. This Program 
                will build right platform for students to become successful software professionals. 
                MCA course also helps to build Entrepreneurship skills in the student.
            </p>
            </div>
        </>
    )
}

// export function MCAInnovativePractices(){
//     return(
//         <>
//          <div className="text-justify">
//             <h5 className="title-text my-4 mt-2">Innovative Practices MCA</h5>
//             <UnderMaintanence />
//          </div>
//         </>
//     )
// }

export function Procedure(){
    return(
        <>
            <h6 className="my-3 fw-bold">Admission to the MBA programme is done through a Centralized Admission Process (CAP) 
            conducted by the Directorate of Technical Education (DTE)</h6>
            <h5 className="my-3 title-text">Steps for Admission Process at a Glance</h5>
            
            <h6 className="my-2 fw-bold">Step 1 : Entrance Exam</h6>
            <p> Apply online for MAH-MBA/MMS-CET 2022/ CAT/CMAT Entrance Exam 2022-23. The Date, Time, Pattern, Marking Scheme, 
                Frequency, etc of every entrance exam is different and the information of the same is available on website of 
                respective authority.</p>

            <h6 className="my-2 fw-bold">Step 2 : Report at Facilitation Centre-FC</h6>
            <ul>
                <li>Candidates have to report at Facilitation Centre (FC) for Document verification. List of Facilitation Centers 
                    published on the State CET Cell, Maharashtra State website.</li>
                <li>The Candidates will also be able to fill in Online Application Form and upload the scanned copies of the required
                     documents through any computer connected to internet from home or any other place</li>
                <li>Candidates are required to fill in all the details as per the instructions and Upload scanned images of valid 
                    necessary original documents as per the requirement of the admission while filling online application form.</li>
                <li>After filling the information and submission of Application Form, the Candidates shall verify the data filled and
                    correct, take printout of the form and sign it.</li>
            </ul>

            <h6 className="my-3 fw-bold">Step 3 : Display or Publishing of Provisional merit list, Submission of grievances, if any, through 
            the method prescribed by the Competent Authority for this purpose and Display or Publishing of Final Merit Lists.</h6>

            <h6 className="my-3 fw-bold">Step 4 : Filling of Option Form</h6>
            <p>Filling up and Confirmation of Online Option Form having preferences of Courses and Institutions prior to respective CAP Rounds.</p>
            <p>Submit your preference along with choice code.</p>
            <p>DTE Institute Code: <strong>MB6631</strong></p>
            <p>1<sup>st</sup> Shift Choice Code:  <strong>663110110</strong></p>
            <p>2<sup>nd</sup> Shift Choice Code:  <strong>663110120</strong></p>
            <p>CET Cell, Maharashtra State will allot respective colleges as per students score.</p>
            
            
            <h6 className="my-3 fw-bold">Step 5 : Report to Admission Reporting Centre-ARC</h6>
            <ul>
                <li>Display of Provisional Allotment of respective CAP Round indicating allotted institute and Course</li>
                <li>Reporting and accepting the offered seat at Admission Reporting Centre by the Candidate as per the 
                    schedule declared by the Competent Authority through the method prescribed by the Competent Authority 
                    for the purpose of accepting the offered seat and rectifying the errors as per the clause</li>
            </ul>

            <h6 className="my-3 fw-bold">Step 6 : Reporting to Allotted College</h6>
            <p>Only after reporting to ARC, the candidate should report to the allotted institute for seeking 
                admission on the allotted seat as per final allotment in CAP rounds.</p>

            <h6 className="my-3 fw-bold">Documents required to be uploaded along with "Application Form for Centralized Admission Process"</h6>
            <ol>
                <li>SSC (Std.X) mark sheet.</li>
                <li>HSC/Diploma mark sheet</li>
                <li>Graduation/Qualifying Examination mark sheet.</li>
                <li>CET mark sheet/ Score Card.</li>
                <li>School Leaving Certificate</li>
                <li>Certificate of the Indian Nationality/ Domicile of the candidate</li>
                <li>Birth/Leaving certificate of candidate Indicating place of Birth in the State of Maharashtra.</li>
                <li>Caste certificate</li>
                <li>Caste validity certificate</li>
                <li>Non creamy layer certificate @ valid up to 31st March 2023.</li>
            </ol>

            <h6 className="my-3 fw-bold"> For More Details, Please Refer Below Link:</h6>
            <Link to={`https://mbacet2022.mahacet.org/StaticPages/HomePage`}> https://mbacet2022.mahacet.org/StaticPages/HomePage</Link>
        </>
    );
}