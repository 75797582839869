
import React from 'react';
import {TableComponent2colm} from '../../Components/TableComponent';


export  function HrMeetTable() {
  const userData = [
    {
      col1: 'EXPECTION from HR-Business perspectives IQAC suggested the plan to organized HR Meet in feb 2020. In Jan 20120 the proposal was placed before CDC' ,
      col2:'23/02/2020 Speaker-Mahesh Rangole. Corporate HR Conducted the session. 90 HR,Faculties and students participated.',
    },
    {
        col1: 'Best HR Practices performance Appraisal The Industry-Institute Interaction Committee (IIIC)& IQAC suggested the plan to organized HR Meet in June 2019. In April 2019 the proposal was placed before CDC' ,
        col2:'HR Meet was organized on 14th June 2019 resource person Mr. Santosh Kadam conducted the session. About 110 HR Professionals from the Pimpri chinchwad industrial belt as well as chakan, Bhosari industrial area attended the HR Meet.',
    },
   {
      col1: 'Recent Wage Settlement of Mahindra Manufacturing Ltd & PF Recent Amendments' ,
      col2: '22/6/2019 organised HR Meet More than 100 HR Exicutives from PCMC CHAKAN MIDC attended the meet. Speakers: Mahesh Karandikar Gen Manager, Employee Relation mahidra Vehicle Manufacturing & Adv. Aditya Joshi & PF Commissioner',
    },
    {
      col1: 'Uplift the Life: NLP for HR Professionals' ,
      col2:'22/11/2018 Organised HR meet speakar Mr. Yogesh Daudkhane .expert coach NLP',
    },
    {
      col1: 'HR Analytics: Through Advance Excel' ,
      col2:'18/11/2017 Santosh Pawar Corporate HR HEAD',
    },
    {
      col1: 'Performance Management System' ,
      col2:'16/09/2016 Samtosh Kawade Corporate HR HEAD Walchandnagar Industries',
    },
  ];

  const tableHeaders = {
    columns: ['Sr No.', 'Topic Plans and Suggestions by IQAC /CDC For HR MEET', 'Status of completion', ''],
  };

  return (
    <div>
        
      <TableComponent2colm data={userData} headers={tableHeaders} />
    </div>
  );
};
