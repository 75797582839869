import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Paper } from "@material-ui/core";
import TableContainer from "@mui/material/TableContainer";

export default function FacultyCard({
  img,
  name,
  designation,
  qualification,
  specialization,
  mailId,
  researchArea,
  totalExperience,
  industryExperience,
  teachingExperience,
  papersPublished,
  booksPublished,
  researchGuidance,
  googleScholarId,
}) {
  const theme = useTheme();

  return (
    <>
      <div className="table-responsive-sm mb-5 pb-0">
        <TableContainer component={Paper} sx={{ borderRadius: 5 }}>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th rowSpan="5" height={100} width={250}>
                  <figure className="x-auto">
                    <img src={img} height={100} width={100} alt="Profile" />
                  </figure>
                  <p className="m-0 text-center text-main text-uppercase">
                    {name}
                  </p>
                  <p className="m-0 text-gray text-center">{designation}</p>
                </th>
              </tr>
              <tr>
                <td className="m-0 text-bold">Qualification </td>
                <td colSpan="4">{qualification}</td>
              </tr>
              <tr>
                <td className="m-0 text-bold">Specialization </td>
                <td colSpan="4">{specialization}</td>
              </tr>
              <tr>
                <td className="m-0 text-bold">Mail ID </td>
                <td colSpan="4" style={{ color: "steelblue" }}>
                  {mailId}
                </td>
              </tr>
              <tr>
                <td className="m-0 text-bold text-left">Research Area </td>
                <td colSpan="4">{researchArea}</td>
              </tr>
              <tr>
                <td className="m-0 text-bold text-left">Total Experience </td>
                <td>{totalExperience}</td>
                <td>
                  <b>Industry : </b> {industryExperience}
                </td>
                <td>
                  <b>Teaching : </b> {teachingExperience}
                </td>
              </tr>
              <tr>
                <td className="m-0 text-bold text-left">
                  No.of Papers Published/Presented{" "}
                </td>
                <td>{papersPublished.total}</td>
                <td>
                  <b>National : </b> {papersPublished.national}
                </td>
                <td>
                  <b>International : </b> {papersPublished.international}
                </td>
              </tr>
              <tr>
                <td className="m-0 text-bold text-left">
                  No. of Books Published{" "}
                </td>
                <td>{booksPublished.total}</td>
                <td>
                  <b>National : </b> {booksPublished.national}{" "}
                </td>
                <td>
                  <b>International : </b> {booksPublished.international}{" "}
                </td>
              </tr>
              <tr>
                <td className="m-0 text-bold text-left">
                  Research Guidance : {researchGuidance.total}{" "}
                </td>
                <td>
                  <b>Completed : </b> {researchGuidance.completed}{" "}
                </td>
                <td>
                  <b>Pursuing : </b> {researchGuidance.pursuing}{" "}
                </td>
                <td>
                  <b>Patents: </b> {researchGuidance.patents}{" "}
                </td>
                {researchGuidance.filed && (
                  <td>
                    <b>Filed: </b> {researchGuidance?.filed}{" "}
                  </td>
                )}
              </tr>
              <tr style={{ borderBottomColor: "white" }}>
                <td className="m-0 text-bold text-left">Google Scholar Id </td>
                {/* <td colSpan='3'     >{googleScholarId}</td> */}
                <td colSpan="4">
                  <a
                    href={googleScholarId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {googleScholarId}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}

export function NonTeachingFacultyCard({
  img,
  name,
  designation,
  qualification,
  specialization,
  mailId,
  totalExperience,
}) {
  const theme = useTheme();

  return (
    <>
      <div className="table-responsive-sm mb-5 pb-0">
        <TableContainer component={Paper} sx={{ borderRadius: 5 }}>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th rowSpan="5" height={100} width={250}>
                  <figure className="x-auto">
                    <img src={img} height={100} width={100} alt="Profile" />
                  </figure>
                  <p className="m-0 text-center text-main text-uppercase">
                    {name}
                  </p>
                  <p className="m-0 text-gray text-center">{designation}</p>
                </th>
              </tr>
              <tr>
                <td className="m-0 text-bold">Qualification </td>
                <td colSpan="3">{qualification}</td>
              </tr>
              <tr>
                <td className="m-0 text-bold">Specialization </td>
                <td colSpan="3">{specialization}</td>
              </tr>
              <tr>
                <td className="m-0 text-bold">Mail ID </td>
                <td colSpan="3" style={{ color: "steelblue" }}>
                  {mailId}
                </td>
              </tr>
              <tr style={{ borderBottomColor: "white" }}>
                <td className="m-0 text-bold text-left">Total Experience </td>
                <td colSpan="3">{totalExperience}</td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}
