import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import Breadcrumb from "../Components/Breadcrumb";
import UnderMaintanence from "./UnderMaintanence";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Paper } from "@mui/material";
import AcademicCalender1 from '../assets/PDF/Academic Calender/AcademicCalendar23-24WEB_SITE.pdf';
import AcademicCalender2 from '../assets/PDF/Academic Calender/AcademicCalender22-23.pdf';
import studentAcheivment from '../assets/PDF/Student Achievments/MIBS_Achievement2022-23.pdf';
import LifeMIBS from '../assets/PDF/Student/Life@MIBS.pdf';
import MCApdf from '../assets/PDF/Student/MCA-Curriculum.pdf';
import MBApdf from '../assets/PDF/Student/MBA-Curriculum.pdf';
import Divider from '@mui/material/Divider';
import { OnlineGrievanceData } from "../Data/Student/OnlineGrievanceData";
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const sidemenus = [
  {
    title: 'Academic Calender',
    url: '/students/AcademicCalender '
  },
  {
    title: 'MBA Curriculum',
    url: '/students/MBACurriculum'
  },
  {
    title: 'MCA Curriculum',
    url: '/students/MCACurriculum'
  },
  {
    title: 'Extension Activities',
    url: '/students/ExtensionActivities'
  },
  {
    title: 'Circulars',
    url: '/students/Circulars'
  },
  // {
  //   title: 'Collaborations',
  //   url: '/students/Collaborations'
  // },
  {
    title: 'Newsletter',
    url: '/students/Newsletter'
  },
  {
    title: 'Student Achievement',
    url: '/students/StudentAchievement'
  },
  {
    title: 'Life at MIBS',
    url: '/students/LifeAtMIBS'
  },
  {
    title: 'Online Grievance Redressal',
    url: '/students/OnlineGrievance'
  },
];

export default function Students(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute('class', 'active-menu');

    // Add d-none class for all divs
    sidemenus.forEach(element => {
      let id = element.title.split(' ').join('').toString();
      document.getElementById(id).classList.add('d-none');
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(' ').join('').toString();
    document.getElementById(divId).classList.remove('d-none');

    // scroll top
    window.scrollTo(0, 0);


  }, [menuName, menuId]);

  const handleClick = event => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(
      document.querySelectorAll(".sideMenu a")
    );

    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });

  };

  return (
    <>
      <Breadcrumb pageTitle={menuName} />
      <div className="container mt-3">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link key={index} id={index} onClick={handleClick} to={menu.url}> {menu.title} </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div id={menu.title.split(' ').join('').toString()} key={index}>
                    {props.pageContent}
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export function AcademicCalender() {
  return (
    <div>

      <>
        <div className='box'>
          <h5 className="title-text my-2 mb-4 decorated">Academic Calendar</h5>
          <Paper elevation={3} className="p-3 textWrap" style={{ minHeight: '300px' }}>
            <div data-aos="fade-up">
              <a href={AcademicCalender1} target="_blank" className="pdf"><PictureAsPdfIcon />Academic Calender 2023-24</a>
              <a href={AcademicCalender2} target="_blank" className="pdf"><PictureAsPdfIcon />Academic Calender 2022-23</a>
            </div>
          </Paper>
        </div>
      </>
    </div>
  );
}

export function MBACurriculum() {
  return (
    <div>
      <h5 className="title-text my-2 mb-4">MBA Curriculum</h5>
      <object
        data={MBApdf}
        type="application/pdf"
        width="100%"
        height={800}
      >
        <p>Your web browser doesn't have a PDF plugin.</p>
        <p>Instead you can <Link to={MBApdf}> click here to download the PDF file.</Link></p>
      </object>
    </div>
  );
}

export function MCACurriculum() {
  return (
    <div>
      <h5 className="title-text my-2 mb-4">MCA Curriculum</h5>
      <object
        data={MCApdf}
        type="application/pdf"
        width="100%"
        height={800}
      >
        <p>Your web browser doesn't have a PDF plugin.</p>
        <p>Instead you can <Link to={MCApdf}> click here to download the PDF file.</Link></p>
      </object>
    </div>
  );
}

export function ExtensionActivities() {
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Activity Type</th>
            <th>Activity Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={12} className="fw-bold">Curricular Activity</td>
            <td>One week Tableau Workshop</td>
            <td>31-05-2022 to 05-06-2022</td>
          </tr>
          <tr>
            <td>Job Readiness Program</td>
            <td>16-06-2022 to 18-06-2022</td>
          </tr>
          <tr>
            <td>Induction Programme Progress towards Excellence (P2E)</td>
            <td>24-11-2022 to 03-12-2022</td>
          </tr>
          <tr>
            <td>Internship session (MBA)</td>
            <td>07-02-2023</td>
          </tr>
          <tr>
            <td>Internship session (MCA)</td>
            <td>15-02-2023</td>
          </tr>
          <tr>
            <td>Session on choosing MBA Specialisation </td>
            <td>17-03-2023</td>
          </tr>
          <tr>
            <td>Internal SIP presentation</td>
            <td>27-03-2023</td>
          </tr>
          <tr>
            <td>Internal session </td>
            <td>07-02-2023</td>
          </tr>
          <tr>
            <td>International Women's Day</td>
            <td>08-03-2023</td>
          </tr>
          <tr>
            <td>CET Training Program for MBA</td>
            <td>15-03-2023</td>
          </tr>
          <tr>
            <td>Session on choosing MBA Specialisation</td>
            <td>17-03-2023</td>
          </tr>
          <tr>
            <td>MCA - CET Online Training Programme</td>
            <td>21-03-2023</td>
          </tr>

          <tr>
            <td rowSpan={11} className="fw-bold">Co-Curricular Activity</td>
            <td>2 Hrs session on MS Word</td>
            <td>24-08-2022</td>
          </tr>
          <tr>
            <td>One Online Fast Track Orientation and Practice Programme MAH- MCA -CET 2022</td>
            <td>08-03-2022</td>
          </tr>
          <tr>
            <td>One Online Fast Track Orientation and Practice Programme MAH- MBA -CET 2022</td>
            <td>22-08-2022</td>
          </tr>
          <tr>
            <td>Programme on " Time Management"</td>
            <td>13-10-2022</td>
          </tr>
          <tr>
            <td>Session on IPR</td>
            <td>18-10-2022</td>
          </tr>
          <tr>
            <td>Sexual Harassment at work Place(Prevention prohibition & Redressal) Act,2013</td>
            <td>07-12-2022</td>
          </tr>
          <tr>
            <td>Session on "Human rights" under Indian Constitution (online)</td>
            <td>20-01-2023</td>
          </tr>
          <tr>
            <td>Sexual Harassment at work Place(Prevention prohibition & Redressal) Act,2013.</td>
            <td>07-12-2022</td>
          </tr>
          <tr>
            <td>Two days workshop on "Laptop Repairing"</td>
            <td>26-12-2023 & 27-12-2023</td>
          </tr>
          <tr>
            <td>Cyber Safe Campus</td>
            <td>30-12-2022</td>
          </tr>
          <tr>
            <td>Industrial Visit  - Maha Metro, Sant Tukaram Co Sugar Factory and Parle G - Talegaon</td>
            <td>09-01-2023</td>
          </tr>

          <tr>
            <td rowSpan={16} className="fw-bold">Extra- Curricular Activity</td>
            <td>NAAC Sponsor "Webinar"   NAAC RAF and Sustainable Quality Assessment with NEP Implementation</td>
            <td>07-01-2023</td>
          </tr>
          <tr>
            <td>Session on "Positive Thinking"</td>
            <td>18-01-2023</td>
          </tr>
          <tr>
            <td>Session on "Human rights" under Indian Constitution (online)</td>
            <td>20-01-2023</td>
          </tr>
          <tr>
            <td>Two Day state Level Seminar</td>
            <td>27-01-2023 & 28-01-2023</td>
          </tr>
          <tr>
            <td>Soft Skill training program</td>
            <td>12-12-2022 to 14-12-2022</td>
          </tr>
          <tr>
            <td>CET Training Program for MBA</td>
            <td>15-03-2023</td>
          </tr>
          <tr>
            <td>Guest session on shell scripting</td>
            <td>13-02-2023 & 14-02-2023</td>
          </tr>
          <tr>
            <td>MCA -CET Online Training Programme</td>
            <td>21-03-2023</td>
          </tr>
          <tr>
            <td>Business/Technical (Logo Design, Web Page Design, Quiz Competition, Poster Competition, Add-Mad Show & Fun- Fair)</td>
            <td>24-04-2023 to 28-04-2023</td>
          </tr>
          <tr>
            <td>CO-PO Attainment</td>
            <td>03-05-2023</td>
          </tr>
          <tr>
            <td>Session on " Six Sigma "</td>
            <td>11-05-2023</td>
          </tr>
          <tr>
            <td>Session on " Tableau "</td>
            <td>13-05-2023</td>
          </tr>
          <tr>
            <td>Alumni Meet</td>
            <td>04-02-2023</td>
          </tr>
          <tr>
            <td>HR Meet</td>
            <td>04-02-2023</td>
          </tr>
          <tr>
            <td>Sports days </td>
            <td>22-04-2023 & 28-04-2023</td>
          </tr>
          <tr>
            <td>Cultural Activity - Sapt Rang </td>
            <td>29-04-2023</td>
          </tr>

          <tr>
            <td rowSpan={24} className="fw-bold">Extension  Activity</td>
            <td>International Yoga Day</td>
            <td>21-06-2022</td>
          </tr>
          <tr>
            <td>Azadi kA Amrit Mohatsav week Celebration - Har Ghar Tiranga </td>
            <td>13-08-2022</td>
          </tr>
          <tr>
            <td>Azadi kA Amrit Mohatsav week Celebration - Bike Rally </td>
            <td>14-08-2022</td>
          </tr>
          <tr>
            <td>Azadi kA Amrit Mohatsav week - Indapendance Day Celebration </td>
            <td>15-08-2022</td>
          </tr>
          <tr>
            <td>Samuhik Rashtrageet </td>
            <td>17-08-2022</td>
          </tr>
          <tr>
            <td>Teachers Day Celebration </td>
            <td>05-09-2022</td>
          </tr>
          <tr>
            <td>Birth Anniversary- Mahatma Gandhi and Lal Bahdur Shastri </td>
            <td>03-10-2022</td>
          </tr>
          <tr>
            <td>Sarasvati Pujan and Desara </td>
            <td>04-10-2022</td>
          </tr>
          <tr>
            <td>Savitribai Phule Birth Anniversary</td>
            <td>03-01-2023</td>
          </tr>
          <tr>
            <td>Health Chek Up Program </td>
            <td>07-11-2022</td>
          </tr>
          <tr>
            <td>Sexual Harassmentat work Place(Prevention prohibition & Redressal) Act,2013</td>
            <td>07-12-2022</td>
          </tr>
          <tr>
            <td>Scoda- Road Safety Awareness Program</td>
            <td>18-01-2023</td>
          </tr>
          <tr>
            <td>Guruvarya Shri. Sahnkararo Kanitkar Smrutidin</td>
            <td>25-01-2023</td>
          </tr>
          <tr>
            <td>Flag unfurling Ceremony</td>
            <td>26-01-2023</td>
          </tr>
          <tr>
            <td>(Yekhmukh Dhanya) (75th Items Donation Camp)</td>
            <td>26-01-2023</td>
          </tr>
          <tr>
            <td>Chatrapati Shiv Jayanti</td>
            <td>21-02-2023</td>
          </tr>
          <tr>
            <td>International Woman's Day</td>
            <td>08-03-2023</td>
          </tr>
          <tr>
            <td>Babasaheb Ambedkar Jayani </td>
            <td>15-04-2023</td>
          </tr>
          <tr>
            <td>Go Green Campus poster competition</td>
            <td>26-04-2023</td>
          </tr>
          <tr>
            <td>Vasant Vyakhyanmala</td>
            <td>25-04-2023 to 27-04-2023</td>
          </tr>
          <tr>
            <td>Birth Anniversary of Mahatma Jrotirao Phule</td>
            <td>11-05-2023</td>
          </tr>
          <tr>
            <td>Session on " Self Defence for Girls"</td>
            <td>18-05-2023</td>
          </tr>
          <tr>
            <td>Blood Donation Camp</td>
            <td>19-05-2023</td>
          </tr>
          <tr>
            <td>Chatrapati Shivaji Maharaj Rajybhishek Divas</td>
            <td>06-06-2023</td>
          </tr>

        </tbody>
      </table>
    </>
  );
}

export function Circulars() {
  return (
    <div>
      <UnderMaintanence />
    </div>
  );
}



export function Newsletter() {
  return (
    <>
      <h5 className="title-text my-2 mb-4">Newsletter</h5>
      <object
        data={process.env.PUBLIC_URL + '/assets/pdf/Newsletter_Aug22_Jan23.pdf'}
        type="application/pdf"
        width="100%"
        height={800}
      >
        <p>Your web browser doesn't have a PDF plugin.</p>
        <p>Instead you can <Link to={process.env.PUBLIC_URL + "/assets/pdf/Newsletter_Aug22_Jan23.pdf"}> click here to download the PDF file.</Link></p>
      </object>
    </>
  );
}

export function StudentAchievement() {
  return (
    <>
      <div>
        <h5 className="title-text my-2 mb-4">Student Achievements</h5>
        <div>
          <object
            // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
            data={studentAcheivment}
            type="application/pdf"
            width="100%"
            height={800}
          >
            <p>Your web browser doesn't have a PDF plugin.</p>
            <p>Instead you can <Link to={studentAcheivment}> click here to download the PDF file.</Link></p>
          </object>
        </div>
      </div>
    </>
  );
}

export function LifeAtMIBS() {
  return (
    <div>
      <object
        data={LifeMIBS}
        type="application/pdf"
        width="100%"
        height={800}
      >
        <p>Your web browser doesn't have a PDF plugin.</p>
        <p>Instead you can <Link to={LifeMIBS}> click here to download the PDF file.</Link></p>
      </object>
    </div>
  );
}

export function OnlineGrievance() {
  return (

    <div> 
     <h5 className="title-text my-2 mb-4">Online Grievance Redressal</h5>
     <p>In order to provide opportunities for redressal of certain grievances of students have already enrolled in any institution, as well as for those seeking admission to such institutions, AICTE has notified All India Council for Technical Education (Redressal of Grievance of Students) Regulation, 2019 vide F. No. 1- 101/PGRC/AICTE/Regulation/2019 dated 07.11.2019 for establishment of Grievance Redressal Mechanism for all AICTE approved Technical Institutions. (Ref – AICTE Approval Process Handbook 2020-21) Progressive Education Society's, Modern Institute of Business Studies, Nigdi Pune follows these regulations and guidelines to establish the Student Grievance Redressal Committee.</p>
     <h6 className="text-bold">Who Can Complain-</h6>
     <p>A student in person or a group of students, if they feel unfair practices in any of the institution’s operations that can cause threat to his/her/their opportunities in education, career enhancement, cultural enhancement, and Personality Development can submit the complaint. If a student feels humiliated, assaulted by teacher/coordinator/administrator/other students he can go for the grievances. A student, while he is enrolled in the Institute for any of its courses can go for Grievance – Redressal by dropping the written complaint online.</p>
     <Divider sx={{ border: 1, marginBottom: 2 }} />
     <h5>STUDENT GRIEVANCE REDRESSAL COMMITTEE</h5>
     <OnlineGrievanceData />
     <Divider sx={{ border: 1, marginBottom: 2 }} />
     <div>
          <h5 className="title-text py-3">Submit Online Grievance : </h5>
          <div>
           Click Here <ArrowRightIcon /><a href="https://forms.gle/6QePTiTe49UsBMup8" target="_blank" className="btn btn-outline-light mt-0" style={{color:'steelblue'}}> https://forms.gle/6QePTiTe49UsBMup8 </a>
          </div>
    </div>
    </div>
  );
}