import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { RectangleBlock, FacebookIcon, TwitterIcon, LinkedInIcon, FbIcon, InstaIcon, TwitIcon, LnIcon} from "../Components/SvgIcons"
import ShankarraoKanitkar from '../assets/AboutUs/ShankarraoKanitkar.png';
import institute from '../assets/AboutUs/about_institute.jpeg';
import chairman from '../assets/AboutUs/chairmanSir.png';
import  director from '../assets/AboutUs/DirectorsImg.jpg';
import co_ordinator from '../assets/AboutUs/Manojsathepic.jpg';
import visitor from '../assets/AboutUs/VisitorsImg.png'
import mandotary from '../assets/PDF/About/MandatoryDiscloure2024.pdf';
import approval from '../assets/PDF/About/EOA-Report-23-24.pdf';

const sidemenus = [
  {
    title: "About P.E.Society",
    url: "/aboutUs/PESociety",
  },
  {
    title: "About Institute",
    url: "/aboutUs/Institute",
  },
  {
    title: 'Vision Mission',
    url: '/aboutUs/VisionMission',
  },
  {
    title: `Chairman's Message`,
    url: "/aboutUs/ChairmansMessage",
  },
  {
    title: `Director's Message`,
    url: "/aboutUs/DirectorsMessage",
  },
  {
    title: `Visitor's Message`,
    url: '/aboutUs/VisitorMessage',
  },
  {
    title: `Coordinator's Message`,
    url: "/aboutUs/CoordinatorsMessage",
  },
  // {
  //   title: "Governance",
  //   url: "/aboutUs/Governance",
  // },

  {
    title: `Mandatory Disclosure`,
    url: "/aboutUs/MandatoryDisclosure",
  },
  {
    title: 'AICTE Approval',
    url: '/aboutUs/Approval',
  },
];

export default function AboutUs(props) {
  const [menuName, setName] = useState(props.pageName);
  const [menuId, setId] = useState(props.pageId);

  useEffect(() => {
    let id = menuId.toString();
    document.getElementById(id).setAttribute("class", "active-menu");

    // Add d-none class for all divs
    sidemenus.forEach((element) => {
      let id = element.title.split(" ").join("").toString();
      document.getElementById(id).classList.add("d-none");
    });

    // remove class d-none of particular div on click by getting Id
    let divId = menuName.split(" ").join("").toString();
    document.getElementById(divId).classList.remove("d-none");

    // scroll top
    window.scrollTo(0, 0);

  }, [menuName, menuId]);

  const handleClick = (event) => {
    setName(event.currentTarget.textContent);
    setId(event.currentTarget.id);

    // Remove all class active-menu of '.sideMenu a' on click
    const allWithClass = Array.from(document.querySelectorAll(".sideMenu a"));
    allWithClass.forEach((element) => {
      element.classList.remove("active-menu");
    });
  };

  return (
    <>
      <Breadcrumb pageTitle={menuName} />
      <div className="container mt-3 ">
        <div className="row default-padding-bottom">
          {/* Sidebar */}
          <aside className="sideMenu col-lg-3">
            <div className="scroll-div">
              {sidemenus.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    id={index}
                    onClick={handleClick}
                    to={menu.url}
                  >
                    {menu.title}
                  </Link>
                );
              })}
            </div>
          </aside>
          {/* content */}
          <div className="col-lg-9">
            <div className="px-lg-3 px-1">
              {sidemenus.map((menu, index) => {
                return (
                  <div
                    id={menu.title.split(" ").join("").toString()}
                    key={index}
                  >
                    {/* <h5 className="title-text my-4 mt-2">{menu.title}</h5> */}
                    {props.pageContent}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// sub-pages below of about us tab
export function PESociety() {
  return (
    <>
      <div>
        <h5 className="title-text my-4 mt-2">Progressive Education Society</h5>
        <div className="row">
          <div className="col-3">
            <img src={ShankarraoKanitkar}  width={'220px'} height={'auto'} alt="" />
            <p className="mt-3 text-center"><b>Hon. Shri. Shankarrao Kanitkar</b><br/>
              Founder of P.E. Society</p>
          </div>
          <div className="col-9 text-justify">
            <p>
              Progressive Education Society is a reputed and distinguished educational 
              institution in Maharashtra. Honorable, Late Shankarrao Kanitkar, 
              along with his selfless dedicated team of teachers known for the highest moral values, 
              laid the foundation of the Society on the auspicious day of 'Akshay Tritiya' on 16th May 1934 in Pune.
            </p>
            <p>Progressive Education Society has been making noteworthy progress for the last 83 years 
              along with the noble path and principles laid down by the eminent predecessors such as 
              Guruwarya Late S. R. Kanitkar, Late V. T. Tatke and Late M. G. Chaphekar, who were illustrious 
              incarnations of ceaseless hard work and sacrifice.
            </p>
            <p>Late S. R. Kanitkar was ably assisted by Late V. T. Tatke, Late M. G. Chaphekar 
            and Late Koparkar. Progressive Education Society is proud to possess the glowing 
            tradition of these stalwarts.
          </p>
          <p>The two remarkable words that appear in the names of the Society and its educational institutions are 
            "Progressive" and "Modern". The inclusion of these words was certainly with some noble intentions,
             which serves as a bright beacon for Progressive Education Society to follow with determination.
          </p>
          </div>
        </div>
         <div className="text-justify">
          <p>'Progressive' means keeping with the spirit of times and innovation while 'Modern' means Modern in outlook, 
            up-to-date in all matters whether it is an academic, sports or cultural activities. It was resolved to use 
            modern equipment and modern methodology to teach the students.
          </p>
          <p>The aims and objectives of the Progressive Education Society as laid down in its Constitution are to impart 
            generally to the rising generations of this country and particularly to the residents of Maharashtra, an 
            efficient Pre-primary, Primary, Secondary, Higher Secondary and Collegiate education, embodying a literacy, 
            scientific, technical, commercial and industrial training and the training of teachers by the establishment 
            at Pune and other places in the Maharashtra State, as circumstances permit, of Pre-primary, Primary, Secondary, 
            Higher Secondary Schools and Colleges or by such other means as may be most conducive to the attainment of 
            the aims and objects of the Society.
          </p>
          <p>For more information visit our website, <a target="_blank" rel="noreferrer">www.pespune.com.</a> </p>
          </div>
      </div>
    </>
  );
}

export function AboutInstitute() {
  return (
    <>
    <div className="text-justify">
      <h5 className="title-text my-4 mt-2">
        Modern Institute of Business Studies (MIBS)
      </h5>
      <img src={institute}   width={800} height={"auto"} alt=""/>
      <p className="mt-3">
        Progressive Education Society has started Modern Institute Of Business Studies (MIBS) in 1991. 
        The Institute is approved by All India Council of Technical Education (AICTE), New Delhi, 
        Directorate of Technical Education (DTE), Government of Maharashtra and is affiliated to Savitribai 
        Phule Pune University (formerly University of Pune), complying with all the norms and standards of 
        Management Education.
      </p>
      <p>P. E. Society’s Modern Institute Of Business Studies (MIBS) is an Institute of higher education offering 
        quality programs that integrate professional skills and career focused education empowering students to 
        develop and achieve their personal and career potentials. MIBS provides assistance to students in obtaining 
        employment and provides classes, workshops and advisory sessions that teach and guide them through the process 
        of securing and maintaining employment. In order to attain its mission, MIBS strives to provide excellence in 
        education by appointment of experienced faculty. The faculty members upgrade their knowledge by participating 
        in the Faculty Development Program and presenting papers at various national programs and conferences in the 
        field of Management and Information Technology.
      </p>
      <p>The Institute offers Post Graduate program in Master of Business Administration (M.B.A.), 
        Masters of Computer Application (M.C.A.). The institute also offers Ph.D. in Management through research.
      </p>
      <p>The institute has membership with Pimpri-Chinchwad small scale industries association and Mahratta Chamber of 
        Commerce, Industries and Agriculture (MCCIA), Pune.</p>
      
      {/* Core Values */}
      <h5 className="title-text my-5">Core Values</h5>
      <div>
        <ul>
          <li>To hold to high standards of integrity, excellence and Creativity in all of our work.</li>
          <li>To value diversity in people, ideas and contributions, fostering an inclusive environment.</li>
          <li>To be committed to maintain long lasting trust between institution and the stakeholders.</li>
          <li>To promote & regulate entrepreneurial activities for developing entrepreneurial spirit in the society.</li>
          <li>To create nationally and internationally employable talent that can accept every challenges in modern corporate scenario.</li>
        </ul>
      </div>

      </div>
    </>
  );
}

export function VisionMission(){
  return(
    <div>
      <div>
        <h5 className="title-text my-2">Vision</h5>
        <p>We nurture competent techno-managerial professionals by imbibing holistic approach to serve the global society.
        </p>
      </div>

      <div className="my-3">
        <h5 className="title-text my-1">Mission</h5>
        <ul>
          <li> To create prospective innovative leaders for sustainable development in the field of Management and Information Technology </li>
          <li> To achieve excellence by imparting research and skill-based quality education to enhance the employability.</li>
          <li> To strengthen the collaboration with Alumni and Industry for overall development.  </li>
        </ul>
        </div>
    </div>

  );
}

export function ChairmansMessage(){
  return(
    <div>
      <div className="row mt-2">
        {/* Message  */}
        <div className="col-lg-8 text-justify">
          <h5 className="title-text mb-3">Chairman's Message</h5>
          <p><b>Dear Students and Well - Wishers</b></p>
          <p>
            Progressive Education Society, Shivajinagar, Pune is a premier Educational 
            Society registered under Bombay Public Trust Act and Societies' Registration Act in 1934. 
            The founders envisioned the need of a school dedicated to the pursuit of excellence in 
            education emphasizing tradition with modernism.
          </p>
          <p>
            The Modern Institute Of Business Studies is conducting course like programs Master of 
            Business Management MBA, MBA - HRD & MBA-IT of Savitribai Phule Pune University.
          </p>
          <p>
            The Progressive Education Society is proud that the wisdom of its founders is nutured 
            through its educational institutions. Progressive Education Society's Institute of selfless 
            Management and Career Development is one of such prominent Institutes striving to maintain 
            idealism and selfles service envisioned by founders of Progressive Education Society. 
            I appreciate your desire to pursue MBA course at the Modern Institute Of Business Studies, Nigdi.
          </p>
          <p>
            The Mission of the College is, to motivate the students to perform par excellence. 
            I have every hope that during your tenure at the College as Student, you will work in every 
            manner towards this mission. Our Students are groomed to take up challenges and industrial 
            pressure and also develop qualities like honesty, integrity and dignity of work. Thus our 
            students develop the urge to create wealth for the betterment of the nation. We welcome you 
            to the Progressive Education Society's Modern Institute Of Business Studies, Nigdi, Pune to 
            have pleasant experience of professional excellence through learning, pursuing and developing 
            your treasure of knowledge.
          </p>
          <p>
            Best wishes,<br/>
            <b>Prof. Dr. G. R. Ekbote</b><br/>
            Chairman,<br/>
            Progressive Education Society.
          </p>
          <div className="blockquote d-none">
            <blockquote className="p-3 mt-4 ps-5 fs-6">
              I have always believed that the answer to my destiny lies in creating
              the results, I desire!
            </blockquote>
          </div>
        </div>
        {/* Profile  */}
        <div className="col-lg-4 text-center profile-info">
          <img src={chairman} alt="userImage" className="opacity-100 img-fluid" />
          <h5 className="my-3 subtitle-text">Prof. Dr. Gajanan R. Ekbote</h5>
          <p className="m-1">Chairman Desk</p>
          <p className="m-1">Email</p>
          {/* social icons */}
          <div className="d-flex gap-3 justify-content-center social-icons">
            <Link className="facebook">
              <FbIcon/>
            </Link>
            
            <Link className="twitter">
              <TwitIcon />
            </Link>

            <Link className="linkedin">
              <LnIcon />
            </Link>

            <Link className="instagram">
              <InstaIcon />
            </Link>
          </div>
        </div>
      </div>

      {/* About & Career Path  */}
      <div className="my-5 my-lg-0 d-none" style={{ textAlign: "justify" }}>
        <h5 className="title-text mb-3">About</h5>
        <p>
          A surgeon by profession, Dr. Gajanan R. Ekbote made his foray into the
          world of administration in 1985. Fuelled by the desire for pedagogical
          pursuits, he accepted the challenging responsibility of leading the
          Progressive Education Society, Pune as its chairman. In order to keep pace
          with the changing scenario of education, he has introduced emerging and
          need-based courses in the institutions run by Progressive Education
          Society. It is an account of his pro-active approach and foresightedness
          that he has been successfully leading 56 educational institutions in Pune
          City. Dr. G. R. Ekbote was granted an equivalence of Ph.D degree by
          Maharashtra University of Health Sciences, Nashik for his outstanding
          academic qualifications, research work and eminence achieved in his
          profession. Presently, he is working as the Pro-Vice Chancellor of the
          same University.
        </p>
        <h5 className="title-text mb-3">Career Profile</h5>
        <ul>
          <li>Professor of Surgery - B. J Medical College, Pune</li>
          <li>
            Dean, Faculty of Medicine - Savitribai Phule Pune University, Pune
          </li>
          <li>
            Appointed as a Member of Consultative Group on 'Education' by planning
            commission, Govt. of India.
          </li>
          <li>
            Appointed as a Member of Consultative Group on 'Teacher's Education' by
            planning commission, Govt. of India.
          </li>
          <li>
            Member, Senate and Board of College and University Development -
            Savitribai Phule Pune University
          </li>
          <li>
            Member, Management Council (Chancellor's Nominee) - Senate and Academic
            Council Maharashtra University of Health Sciences, Nashik
          </li>
          <li>
            Member, Board of Governors - National Institute of Technology,
            Surathkal, Karnataka
          </li>
          <li>
            Member, Academic Council (UGC Nominee) - West Bengal University of
            Health Sciences, Kolkata
          </li>
        </ul>
      </div>
    </div>

  )
}

export function DirectorsMessage(){
  return (
    <>
      <div className="row mt-2">
        {/* Message */}
        <div className="col-lg-8 text-justify">
          <h5 className="title-text mb-3">Director's Message</h5>
          <p>
            Iam extremely happy and profoundly privileged to extend a warm welcome 
            to all students in Progressive Education Society's Modern Institute of 
            Business Studies (PES MIBS) family. PES MIBS has gained reputation for 
            excellence in academics by imparting quality education to professional 
            programs and working towards pioneer in research contribution. 
            Technology is moving at a very fast pace. Post Covid Pandemic technology 
            has become an integral part of every profession. This has made it 
            compulsory and urgent need that future managers and technocrats must be 
            familiar with all the skills running from techno-managerial aspects. 
            We believe that an individual can be professionally competent in outside 
            world if educated with true knowledge, and nurtured with humanistic 
            values and managerial skills.
          </p>
          <p>
            PES believes that while academic programs bring visibility, quality research 
            brings credibility to the institute which is the hallmarks of professional's 
            education. PES's competitive faculty hub firmly believes that knowledge generation
            through innovative strategies encourages our budding managers to stand tall in
            competitive market. Well said by William A. Foster “Quality is never an accident; 
            it is always the result of high intention, sincere effort, intelligent direction 
            and skilful execution; it represents the wise choice of many alternatives”
            At our Institute we value every individual and it is an aim of all our committed 
            teaching fraternity to provide the best possible environment and quality education
            for individual's holistic development. The curriculum as prescribed by SSPU is 
            tailored with various value addition courses as per individual needs. This is 
            the prime factor which makes every student professionally ready as per
            Industrial expectation.
          </p>
          <p>
            PES is a vibrant learning community that encourages a four-way partnership between 
            students, parents, industry and the society. The strong bonding between these 
            stakeholders is the key strength of PES in imparting the quality education in 
            innovative way. I wish you all the best and cordially invite you to join this 
            innovative learning campus.
          </p>
          <p>
            Best wishes,<br/>
            <b>Prof. Dr. Maithili Arjunwadkar</b><br/>
            (Director, Modern Institute of Business Studies)
          </p>
        </div>
        {/* Profile */}
        <div className="col-lg-4 text-center profile-info">
        <img src={director} alt="userImage" className="opacity-100 img-fluid" />
          <h5 className="my-3 subtitle-text">Prof. Dr. Maithili Arjunwadkar</h5>
          <p className="m-1">Director</p>
          <p className="m-1">Email</p>
          {/* social icons */}
          <div className="d-flex gap-3 justify-content-center social-icons">
            <Link className="facebook">
              <FbIcon/>
            </Link>
            
            <Link className="twitter">
              <TwitIcon />
            </Link>

            <Link className="linkedin">
              <LnIcon />
            </Link>
            
            <Link className="instagram">
              <InstaIcon />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export function VisitorMessage(){
  return(
    <div>
    <div className="row mt-2">
      {/* Message  */}
      <div className="col-lg-8 text-justify">
        <h5 className="title-text mb-3">Visitor's Message</h5>
        <p><b>From the desk of Prof. Dr. M.D. Alandikar, (Visitor-MIBS)</b></p>
        <p>
        Welcome to Modern Institute of Business Studies, Yamunanagar, Nigdi,Pune-44
        </p>
        <p>
        It’s our pleasure to welcome you in our estimated organization run by Progressive Education Society, Pune-05. College offers Master Degree courses like M.B.A. & M.C.A.  Our campus is re-accredited with ‘A’ Grade by NAAC, Bangalore. Our young experienced & enthusiastic team & teachers are always keen to help & guide the students to achieve the highest standard of performance. They all well qualified both in Academic and Practical terms. This obviously ensures a smooth and efficient learning environment. We all assure you that, you will certainly enjoy the atmosphere of the Institute and will keep up the glorious tradition of the Institute.
        </p>
        <p>
        The brilliant past students of this college are working at higher positions in various fields like Management, I.T., Computer Science and other industries.
        </p>
        <p>
        Infect, I am very honored to be the Visitor of Institute. The great teaching technique which is global. In outlook and composite in character.
        </p>
        <p>
        With regards,<br/>
        (Prof. Dr. Milind D. Alandikar)
         Visitor, MIBS
        </p>
       
      </div>
      {/* Profile  */}
      <div className="col-lg-4 text-center profile-info">
        <img src={visitor} alt="userImage" className="opacity-100 img-fluid" />
        <h5 className="my-3 subtitle-text">Prof. Dr. Milind D. Alandikar</h5>
        <p className="m-1">Visitor, MIBS</p>
        {/* social icons */}
        {/* <div className="d-flex gap-3 justify-content-center social-icons">
          <Link className="facebook">
            <FbIcon/>
          </Link>
          
          <Link className="twitter">
            <TwitIcon />
          </Link>

          <Link className="linkedin">
            <LnIcon />
          </Link>

          <Link className="instagram">
            <InstaIcon />
          </Link>
        </div> */}
      </div>
    </div>

    {/* About & Career Path  */}
    <div className="my-5 my-lg-0 d-none" style={{ textAlign: "justify" }}>
      <h5 className="title-text mb-3 text-justify">About</h5>
      <p>
        A surgeon by profession, Dr. Gajanan R. Ekbote made his foray into the
        world of administration in 1985. Fuelled by the desire for pedagogical
        pursuits, he accepted the challenging responsibility of leading the
        Progressive Education Society, Pune as its chairman. In order to keep pace
        with the changing scenario of education, he has introduced emerging and
        need-based courses in the institutions run by Progressive Education
        Society. It is an account of his pro-active approach and foresightedness
        that he has been successfully leading 56 educational institutions in Pune
        City. Dr. G. R. Ekbote was granted an equivalence of Ph.D degree by
        Maharashtra University of Health Sciences, Nashik for his outstanding
        academic qualifications, research work and eminence achieved in his
        profession. Presently, he is working as the Pro-Vice Chancellor of the
        same University.
      </p>
      <h5 className="title-text mb-3">Career Profile</h5>
      <ul>
        <li>Professor of Surgery - B. J Medical College, Pune</li>
        <li>
          Dean, Faculty of Medicine - Savitribai Phule Pune University, Pune
        </li>
        <li>
          Appointed as a Member of Consultative Group on 'Education' by planning
          commission, Govt. of India.
        </li>
        <li>
          Appointed as a Member of Consultative Group on 'Teacher's Education' by
          planning commission, Govt. of India.
        </li>
        <li>
          Member, Senate and Board of College and University Development -
          Savitribai Phule Pune University
        </li>
        <li>
          Member, Management Council (Chancellor's Nominee) - Senate and Academic
          Council Maharashtra University of Health Sciences, Nashik
        </li>
        <li>
          Member, Board of Governors - National Institute of Technology,
          Surathkal, Karnataka
        </li>
        <li>
          Member, Academic Council (UGC Nominee) - West Bengal University of
          Health Sciences, Kolkata
        </li>
      </ul>
    </div>
  </div>
  );
}

export function CoordinatorsMessage(){
  return(
    <div className="row mt-2">
      {/* Message */}
      <div className="col-lg-8 text-justify">
        <h5 className="title-text mb-3">Coordinator's Message</h5>
        <p>
          Dear Student,<br/>
          Welcome to PES MIBS <br/>
          As you begin your journey in the exciting world of Business and 
          Information Technology, we at PES MIBS are geared up to provide you a complete, 
          conducive environment that will help your overall development and growth as a professional. 
          The world of Business, computing/IT is subject to rapid changes requiring individuals,
           organizations and institutes to quickly adapt and change course to embrace the new 
           opportunities and deliver.
        </p>
        <p>
          In addition to high level Business and technical skills, everyone in today’s 
          competitive world requires soft skills like communication, interpersonal skill, 
          handling challenges and manages stress. Our infrastructure and staff is well equipped 
          to cater the above needs and I firmly believe that we can achieve your goals and dreams, 
          while also contributing to the progress of our society and country.
        </p>
        <p>
          PES is a vibrant learning community that encourages a four-way partnership 
          between students, parents, industry and the society. The strong bonding between 
          these stakeholders is the key strength of PES in imparting the quality education 
          in innovative way. I wish you all the best and cordially invite you to join this 
          innovative learning campus.
        </p>
       
        <p>
          All the very best,<br/>
          <b>Prof. Dr Manoj Sathe</b><br/>
          (Coordinator)
        </p>
      </div>
      {/* Profile */}
      <div className="col-lg-4 text-center profile-info">
      <img src={co_ordinator} alt="userImage" className="opacity-100 img-fluid" />
        <h5 className="my-3 subtitle-text">Prof. Dr Manoj Sathe</h5>
        <p className="m-1">Coordinator</p>
        <p className="m-1">Email</p>
        {/* social icons */}
        <div className="d-flex gap-3 justify-content-center social-icons">
            <Link className="facebook">
              <FbIcon/>
            </Link>
            
            <Link className="twitter">
              <TwitIcon />
            </Link>

            <Link className="linkedin">
              <LnIcon />
            </Link>
            
            <Link className="instagram">
              <InstaIcon />
            </Link>
        </div>
      </div>
    </div>

  )
}
const tableHeading = [
      'Sr No.','Name Of The Member','Designation' , 'Position In The Committee'
    ]
    const tableData = [
      {
          NameOfTheMember: 'Dr. Maithili Arjunwadkar ' ,
          Designation:'Director',
          PositionInTheCommittee:'Chairman, Head of the Institution '
      },
    ]


export function Approval(){
  return(
    <div>
       <h5 className="title-text my-2 mb-4"> AICTE Approval</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={approval}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to={approval}> click here to download the PDF file.</Link></p>
        </object> 
      </div>
    </div>

  );
}

export function MandatoryDisclosure(){
  return(
    <>
      <h5 className="title-text my-2 mb-4">Mandatory Disclosure</h5>
      <div>
        <object
          // data="http://mibmpune.com/assets/Mandatory-Disclosure.pdf"
          data={mandotary}
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to={mandotary}> click here to download the PDF file.</Link></p>
        </object> 
      </div>
    </>
  )
}

// Below Fields are not in use


export function Team() {
  return (
    <>
      <div>
        <h5 className="title-text my-4 mt-2">Our Team</h5>
        <div className="advisor-area">
          <div className="advisor-items text-center">
            <div className="row col-lg-12">
                <div className="single-item col-lg-3 col-md-6">
                  <div className="item">
                    <div className="thumb">
                      <img src="/assets/img/UserPhotoM.png" alt="userImage" className="opacity-50" />
                      <ul>
                        <li className="facebook">
                          <Link>
                            <FacebookIcon />
                          </Link>
                        </li>
                        <li className="twitter">
                          <Link>
                            <TwitterIcon />
                          </Link>
                        </li>
                        <li className="linkedin">
                          <Link>
                            <LinkedInIcon />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="info">
                      <h4><Link>Prof. Name Surname</Link></h4>
                      <span>Designation</span>
                      <div>Qualification</div>
                    </div>
                  </div>
                </div>
                <div className="single-item col-lg-3 col-md-6">
                  <div className="item">
                    <div className="thumb">
                    <img src="/assets/img/UserPhotoF.png" alt="userImage" className="opacity-50"/>
                      <ul>
                        <li className="facebook">
                          <Link>
                            <FacebookIcon />
                          </Link>
                        </li>
                        <li className="twitter">
                          <Link>
                            <TwitterIcon />
                          </Link>
                        </li>
                        <li className="linkedin">
                          <Link>
                            <LinkedInIcon />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="info">
                      <h4><Link>Prof. Name Surname</Link></h4>
                      <span>Designation</span>
                      <div>Qualification</div>
                    </div>
                  </div>
                </div>
                <div className="single-item col-lg-3 col-md-6">
                  <div className="item">
                    <div className="thumb">
                    <img src="/assets/img/UserPhotoM.png" alt="userImage" className="opacity-50"/>
                      <ul>
                        <li className="facebook">
                          <Link>
                            <FacebookIcon />
                          </Link>
                        </li>
                        <li className="twitter">
                          <Link>
                            <TwitterIcon />
                          </Link>
                        </li>
                        <li className="linkedin">
                          <Link>
                            <LinkedInIcon />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="info">
                      <h4><Link>Prof. Name Surname</Link></h4>
                      <span>Designation</span>
                      <div>Qualification</div>
                    </div>
                  </div>
                </div>
                <div className="single-item col-lg-3 col-md-6">
                  <div className="item">
                    <div className="thumb">
                    <img src="/assets/img/UserPhotoF.png" alt="userImage" className="opacity-50" />
                      <ul>
                        <li className="facebook">
                          <Link>
                            <FacebookIcon />
                          </Link>
                        </li>
                        <li className="twitter">
                          <Link>
                            <TwitterIcon />
                          </Link>
                        </li>
                        <li className="linkedin">
                          <Link>
                            <LinkedInIcon />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="info">
                      <h4><Link>Prof. Name Surname</Link></h4>
                      <span>Designation</span>
                      <div>Qualification</div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="default-padding pt-3">
          <h5 className="title-text my-4 mt-0">List of Faculties</h5>
          <table className="table table-responsive mb-5 pt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Full Time / Visiting</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Dr. Vijayalakshmi Srinivas</td>
                <td>Director</td>
                <td>Full Time</td>
                <td>Bcom, MBA, PhD. (Finance)</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Prof. Dr. Shrawan Owhal</td>
                <td>Professor</td>
                <td>Full Time</td>
                <td>BSc., DBM, MMS (OPE, MKT), PhD. (Marketing) </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Prof. Dr. Nivedita Ekbote</td>
                <td>Coordinator &amp; Associate Professor</td>
                <td>Full Time</td>
                <td>
                  BE, MBA, PhD. (Organization Management), M.A. (Sociology)
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Prof. Dr. Harsha Thorve</td>
                <td>Associate Professor</td>
                <td>Full Time</td>
                <td>Bcom, Mcom, SET, MBA, PhD. (Banking &amp; Finance)</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Prof. Priyadarshani Zanjurne (Patil)</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>BSc, MBA</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Prof. Prasanna Puranik</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>Bcom, Mcom, MBS (Finance) Mphil, PhD. (Persuing)</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Prof. Rupali Mane</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>BA, MSW, MPM , PhD. (Persuing)</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Prof. Jasmeet Patil</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>BSc., MBA</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Prof. Rohit Alandikar</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>BBM (IB), MBA (FIN,MKT), PhD. (Persuing)</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Prof. Sampada Joshi</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>BCS, MCM, MBA (HR), Mphil (IT)</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Prof. Ketki Sohoni</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>Bcom, MBA</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Prof. Joshua Xavier </td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>Bcom, Mcom</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Prof. Dr. Sachin Hatole</td>
                <td>Assistant Professor</td>
                <td>Full Time</td>
                <td>Bcom, DBM, MBA, PhD. (Marketing)</td>
              </tr>
            </tbody>
          </table>
          <h5 className="title-text my-4 mt-0">List of Non-Teaching Staff</h5>
          <table className="table table-responsive my-5 pt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mr. Santosh Bagav</td>
                <td>Librarian</td>
                <td>B.Lib.,M.Lib. SET</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Mr. Nilesh Bagve</td>
                <td>Assistant Librarian</td>
                <td>B.Lib.,M.Lib</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Mr. Nilesh Kachi</td>
                <td>Accounts Officer</td>
                <td>Bcom, MBA</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Mr. Pintu Nivangune</td>
                <td>Student Section officer</td>
                <td>BA, MA</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Mr. Pruthviraj Patil</td>
                <td>Student Section officer</td>
                <td>BA, MA</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Mr. Avinash Chavan</td>
                <td>Accounts Assistant </td>
                <td>Bcom</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Mr. Ashish Bane</td>
                <td>Technical Assistant</td>
                <td>Bcom</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mr. Sandip Shirole</td>
                <td>Office Assistant</td>
                <td>Bcom</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Mr. Somnath Dudhane</td>
                <td>Library Assistant</td>
                <td>Bcom</td>
              </tr>
            </tbody>
          </table>
          <h5 className="title-text my-4 mt-0">List of Visiting Staff</h5>
          <table className="table table-responsive my-5 pt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Qualification</th>
                <th>Specialization</th>
                <th>Teaching &amp; Industrial Experience</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Prof. Rajiv Taneja</td>
                <td>PGDBM, B.Com</td>
                <td>Marketing</td>
                <td>40</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Dr. Hemant Abhyankar</td>
                <td>MA(Eco), PhD</td>
                <td>Economics</td>
                <td>39</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Prof. Nitin Kshirsagar</td>
                <td>BE, MBS, PGDBM</td>
                <td>Production</td>
                <td>40</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Prof. Sunil Kelkar</td>
                <td>MCom, MBS, CA, PhD(pursuing)</td>
                <td>Finance</td>
                <td>36</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Prof. Chetana Rao</td>
                <td>BE, MBS</td>
                <td>Marketing &amp; HR</td>
                <td>10</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Prof. Jayashree Chinchanikar</td>
                <td>LLB, MLL &amp; LW</td>
                <td>Law</td>
                <td>3</td>
              </tr>
              <tr>
                <td>7</td>
                <td>CA Ruchi Kulkarni</td>
                <td>CA, CWA</td>
                <td>Finance</td>
                <td>5</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Prof. Arun Barwe</td>
                <td>CA</td>
                <td>Finance</td>
                <td>25</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Prof. Pradeep Thite</td>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>10</td>
                <td>Prof. Kaustubh Purohit</td>
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>11</td>
                <td>Prof. Sadanand Soman</td>
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <h5 className="title-text my-4 mt-0">List of Library Staff</h5>
          <table className="table table-responsive my-5 pt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Qualification</th>
                <th>Designation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mr. Santosh Bagve</td>
                <td>M.A. M.Lib, SET</td>
                <td>Librarian</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Nilesh Bagave</td>
                <td>M.A, M.Lib</td>
                <td>Asst. Librarian</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Sandeep Wajunj</td>
                <td>B.A, M.Lib</td>
                <td>Asst. Librarian</td>
              </tr>
            </tbody>
          </table>
          <h5 className="title-text my-4 mt-0">
            List of Administrative Staff
          </h5>
          <table className="table table-responsive mt-5 pt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Qualification</th>
                <th>Designation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Nilesh Kachi</td>
                <td>MBA</td>
                <td>In-charge of Account Section</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Pintu Nivangune</td>
                <td>M.A</td>
                <td>Clerk</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Pruthviraj Patil</td>
                <td>M.A</td>
                <td>Clerk</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Avinash Chavan</td>
                <td>B.com</td>
                <td>Office Assistant</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Sandip Shirole</td>
                <td>B.Com</td>
                <td>Office Assistant</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Ashish Bane</td>
                <td>B.Com</td>
                <td>System Administrator</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Somnath Dudhane</td>
                <td>B.Com</td>
                <td>Office Assistant</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Sunanda Muknak</td>
                <td />
                <td />
              </tr>
              <tr>
                <td>9</td>
                <td>Maridas Gandi</td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export function FeesRegulatoy(){
  return(
    <>
      <h5 className="title-text my-2 mb-4">Fees Regulatoy</h5>
      <div>
        <object
          data="https://mibspune.in/pdf/FRA.pdf"
          type="application/pdf"
          width="100%"
          height={800}
        >
          <p>Your web browser doesn't have a PDF plugin.</p>
          <p>Instead you can <Link to="https://mibspune.in/pdf/FRA.pdf"> click here to download the PDF file.</Link></p>
        </object>
      </div>
    </>
  )
}

export function Infrastructure(){
  const [showCampus, setShowCampus] = useState(true);
  const [showClassroom, setShowClassroom] = useState(false);
  const [showSeminar, setShowSeminar] = useState(false);
  const [showResearch, setShowResearch] = useState(false);

  const handleClick = (event) => {
    document.querySelectorAll(".tab-btn button").forEach((element) => {
      element.classList.remove('active');
    });
    event.currentTarget.setAttribute("class", "active");

    let id = event.currentTarget.id;
    if(id === '0'){
      setShowCampus(!showCampus);
      setShowClassroom(false);
      setShowSeminar(false);
      setShowResearch(false)
    }
    else if(id === '1'){
      setShowCampus(false );
      setShowClassroom(!showClassroom);
      setShowSeminar(false);
      setShowResearch(false)
    }
    else if(id === '2'){
      setShowCampus(false);
      setShowClassroom(false);
      setShowSeminar(!showSeminar);
      setShowResearch(false)
    }
    else if(id === '3'){
      setShowCampus(false);
      setShowClassroom(false);
      setShowSeminar(false);
      setShowResearch(!showResearch)
    }
  }

  return(
    <>
      <div className="tab-btn mb-3">
        <button id="0" onClick={handleClick} className="active">Campus</button>
        <button id="1" onClick={handleClick}>Classrooms</button>
        <button id="2" onClick={handleClick}>Seminar Hall</button>
        <button id="3" onClick={handleClick}>Research Lab</button>
      </div>

      {showCampus && <Campus />}  {showClassroom && <Classrooms/>}  {showSeminar && <SeminarHall/>}  {showResearch && <ResearchLab/> }
    </>    
  );
}

export function Campus(){
  return(
      <>
          <RectangleBlock width={800} height={350} imageName={'Campus Image'}/>
          <div className="my-3">
                  <p>Progressive Education Society's Modern Institute of Business Management (MIBM) encourages students 
                  from all faith backgrounds to find their God-designed potential for leadership, scholarship and service. 
                  At MIBM, students develop spiritual and professional gifts; find professors who care for and respect the
                  individual; and procure opportunities to create, learn and connect.</p>

                  <p>MIBM offers active learning in a vibrant atmosphere where students connect the classroom and their world. 
                  MIBM's nurturing environment offers modern management education combined with practical experiences such as 
                  internships, academic and career counseling and summer internship projects.</p>

                  <p>Progressive Education Society's Modern Institute of Business Management (MBM), is centrally located institute 
                  in Shivaji Nagar, Pune. Institute has stand alone building in heart of the city with all the necessary facilities. 
                  It's just 1.0 km from Shivaji Nagar bus stop and railway station, 650 meter from Pune Municipal Corporation bus stop,
                  3.0 km from Pune railway station, 5.0 km from Swargate bus stop and 12.0 km from Pune airport. MIBM is just besides 
                  J. M. Road (Jangli Maharaj Road), 1.0 km from F. C. Road (Ferguson College). Overall connectivity is best for MIBM
                      from any part of the city.</p>

                  <p>Campus is well constructed and designed for MBA students in every aspect from classroom to parking area. 
                  It has everything which an MBA college should possess.</p>
          </div>
      </>
  )
}

export function Classrooms(){
  return(
      <>
          <RectangleBlock width={800} height={350} imageName={'Classroom Image'}/>
          <div className="my-3">
              <p> MIBM has ample of classrooms for lectures, tutorials, discussions, and activities and separate 
              common rooms for girls and boys. MIBM has separate classrooms for MBA first year for all the three
              divisions and MBA second for all specializations.</p>

              <p>Every classroom is consisting of high quality inbuilt projectors with projector screen, white boards
              well-furnished tables and benches. All the classrooms in MIBM are equipped with air conditioners.</p>
          </div>
      </>
  )
}

export function SeminarHall(){
  return(
      <>
          <RectangleBlock width={800} height={350} imageName={'SeminarHall Image'}/>
          <div className="my-3">
              <p> With the capacity of more than 300 students, MIBM has two fully equipped seminar halls. It includes, 
                  seating arrangement, AC, audio visual system, projector with screen, podium, executive chairs, etc. 
                  These seminar halls are used for guest lectures, small events, various student's activities, seminars, 
                  conferences, presentations, tests, and role plays.</p>
          </div>
      </>
  )
}

export function ResearchLab(){
  return(
      <>
          <RectangleBlock width={800} height={350} imageName={'ResearchLab Image'}/>
          <div className="my-3">
              <p>
                  As MIBM has doctoral program (Ph. D), a research lab is available for research scholars and students, 
                  where students can access internet and various online resources like, EBSCO, DELNET, J Gate, and many 
                  ther resources. Research lab consists of 10 computers having high speed broadband connection and 
                  headphones with mike.
              </p>
          </div>
      </>
  )
}