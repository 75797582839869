export default function Sample(){
    return(
        <>
            <div className="d-flex flex-wrap flex-lg-nowrap" style={{background:'#555', height:'100vh'}}>
                <div className="w-100" style={{backgroundColor:'#fff'}}>
                    <div className="d-flex justify-content-center align-items-center" style={{height:'500px'}}>
                        Sample Image
                    </div>
                </div>
                <div className="w-100" style={{backgroundColor:'#eee'}}>
                    <div className="d-flex justify-content-center align-items-center" style={{height:'500px'}}>
                        Form
                    </div>
                </div>
            </div>
        </>
    )
}




// import GalleryView from '../Components/GalleryView';
// import VideoPlayer from '../Components/VideoPlayer';
// import { Link } from 'react-router-dom'
// import '../css/vertical-carousel.css';
// import Aos from 'aos';
// import CourseSlick from '../Components/CourseSlick';
// import TestimonialSlick from '../Components/TestimonialSlick';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { Carousel } from 'react-responsive-carousel';
// import img1 from '../assets/BannerImages/bannerImg1.png';
// import img2 from '../assets/BannerImages/bannerImg2.png';
// import img3 from '../assets/BannerImages/bannerImg3.png';
// import img4 from '../assets/BannerImages/bannerImg4.png';
// import img5 from '../assets/BannerImages/bannerImg5.jpeg';
// import img6 from '../assets/BannerImages/bannerImg6.jpeg';
// import img7 from '../assets/BannerImages/bannerImg7.jpeg';
// import img8 from '../assets/BannerImages/bannerImg8.jpeg';
// import img9 from '../assets/BannerImages/bannerImg9.jpeg';

// Aos.init({
//     duration: 1000,
// });

// // Multiple slides 
// let items = document.querySelectorAll('.cc .ccl')
// items.forEach((el) => {
//     const minPerSlide = 2
//     let next = el.nextElementSibling
//     for (var i=1; i<minPerSlide; i++) {
//         if (!next) {
//         // wrap carousel by using first child
//             next = items[0]
//         }
//         let cloneChild = next.cloneNode(true)
//         el.appendChild(cloneChild.children[0])
//         next = next.nextElementSibling
//     }
// });

// const HomeCopy = () => {
//     const images = [
//         img1 , img2 , img3 , img4 ,img5 , img6 ,img7 ,img8 ,img9
//     ]
//     return (
//         // <h2>Home page content</h2>
//         <>
//             {/* Slider */}
//             {/* Main Image */}
//                      <div className="col-lg-12">
//                         <div className='Image-Carousel'>
//                             <Carousel autoPlay infiniteLoop showArrows={true} showThumbs={false}>
//                                 {images.map((image, index) => (
//                                     <div key={index} style={{maxWidth: 'auto' }}>
//                                         <img src={image} alt={`Image ${index}`}  />
//                                     </div>
//                                 ))}
//                             </Carousel>
//                         </div>
//                         </div>
                       


//             {/* Why Choose us*/}
//             <div className="default-padding  bg-light">
//                 <div className="container">
//                     <div className="text-center" data-aos="fade-up">
//                         <h5 className="title-text">WELCOME TO</h5>
//                         <h2>Modern Institute Of Business Studied</h2>
//                     </div>
//                     <div className="row default-padding-top">
//                         <div className="col-lg-4">
//                             <div className="position-relative my-1">
//                                 <img
//                                     src="assets/img/1.jpg"
//                                     alt="Thumb"
//                                     style={{ width: "100%", height: 320 }}
//                                 />
//                                 <VideoPlayer />
//                             </div>
//                         </div>
//                         <div className="col-lg-8 why-choseus-area" data-aos="fade-left">
//                             <div className="row">
//                                 <div className="col-lg-4 col-md-4 my-1">
//                                 <div className="item">
//                                     <span>01</span>
//                                     <i className="flaticon-library" />
//                                     <Link><h5 className='my-4'>Campus Life</h5></Link>
//                                     <p>Absolute required of reserved in offering no.</p>
//                                     <ul>
//                                         <li>Point One</li>
//                                         <li>Point Two</li>
//                                         <li>Point Three</li>
//                                     </ul>
//                                 </div>
//                                 </div>
//                                 <div className="col-lg-4 col-md-4 my-1">
//                                 <div className="item">
//                                     <span>02</span>
//                                     <i className="flaticon-library" />
//                                     <Link><h5 className='my-4'>Academic</h5></Link>
//                                     <p>Absolute required of reserved in offering no.</p>
//                                     <ul>
//                                         <li>Point One</li>
//                                         <li>Point Two</li>
//                                         <li>Point Three</li>
//                                     </ul>
//                                 </div>
//                                 </div>
//                                 <div className="col-lg-4 col-md-4 my-1">
//                                 <div className="item">
//                                     <span>03</span>
//                                     <i className="flaticon-library" />
//                                     <Link><h5 className='my-4'>Activities</h5></Link>
//                                     <p>Absolute required of reserved in offering no.</p>
//                                     <ul>
//                                         <li>Point One</li>
//                                         <li>Point Two</li>
//                                         <li>Point Three</li>
//                                     </ul>
//                                 </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
            
//             {/* Courses */}
//             <div className="bg-light">
//                 <div className="container text-center" data-aos="fade-up">
//                     <h5 className="title-text">SPECIALIZATIONS OFFERED</h5>
//                     <h2>Here is our Top Courses</h2>
//                 </div>
//                 <div className="default-padding">
//                     <div className="container">
//                         <div className="course-box">
//                             <div className="row">
//                                 <div className="col-lg-5 course-box-first" data-aos="fade-right">
//                                     <h2 className="fw-bold mb-4">
//                                         Select your favorite subject from best categories
//                                     </h2>
//                                     <p>
//                                         Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
//                                         laudantium autem saepe sint ex placeat nihil laboriosam fugit,
//                                         quod nisi dolor rem cupiditate minus animi laborum voluptatem
//                                         assumenda. Neque, suscipit!
//                                     </p>
//                                     <button className="btn btn-main">View</button>
//                                 </div>
//                                 <div className="col-lg-7 p-0" data-aos="fade-left">
//                                     <div className="container my-3">
//                                         <CourseSlick />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             {/* Recent Gallery */}
//             {/* <GalleryView /> */}
//             {/* Testimonials */}
//             <div className="default-padding">
//                 <div className="container">
//                     <div className="text-center" data-aos="fade-up">
//                         <h5 className="title-text">TESTIMONIALS</h5>
//                         <h2>Our Students Review</h2>
//                     </div>
//                     <div className="default-padding-top" data-aos="zoom-in">
//                         <TestimonialSlick />
//                     </div>
//                 </div>
//             </div>
//         </>

//     );
// };

// export default HomeCopy;
