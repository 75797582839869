import { useRef ,useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Dropdown from './Dropdown';

const MenuItems = ({items, depthLevel}) => {
    let ref = useRef();
    const [dropdown, setDropdown] = useState(false);
    useEffect(() => {
        setDropdown(true);
    },[dropdown])

    // const handleClick = () => {
    //      // scroll top   
    //      window.scrollTo(0, 0);
    // }

    const handleClick = (e) => {
        // scroll top
        window.scrollTo(0, 0);
       //  document.getElementById('navActive').removeAttribute('class')
        document.getElementById('navActive').classList.remove('collapsed');
        document.getElementById('navbarNavDropdown').classList.remove('show');
        if(depthLevel > 3){
           // alert(e.target.text);
           sessionStorage.setItem('menuName', e.target.text)
       }
   }
   //On scroll collapse btn close
    window.onscroll = function() {
        document.getElementById('navActive').classList.remove('collapsed');
        document.getElementById('navbarNavDropdown').classList.remove('show');
    };
   
    depthLevel = depthLevel + 1;
    const liClass = depthLevel > 1 ? '' : 'nav-item';
    const aClass = depthLevel > 1 ? 'dropdown-item' : 'nav-link';
    return(
        <li className={`${liClass}`} ref={ref} >
            {items.url && items.submenu ? (
            <>
                <Link
                    className={`${aClass} ${
                        dropdown ? 'dropdown-toggle' : ''
                    }`}
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    role="button"
                    to='#'
                >
                {items.title}
                </Link>

                <Dropdown
                    depthLevel={depthLevel}
                    submenus={items.submenu}
                    dropdown={dropdown} />
            </>
            ) :(<Link className={`${aClass}`} to={items.url} onClick={handleClick}>
                    {items.title}
                </Link>
            )}
              
        </li>
    );
}
export default MenuItems;